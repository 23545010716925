import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField, TablePagination, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../contexts/Apicontext';

const ClassesTable = () => {
    const [classes, setClasses] = useState([]);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [newClass, setNewClass] = useState({
        className: '',
        description: '',
        price: '',
        servicePeriod: '',
        teacherID: '',
        isActive: true // Ensure this is a boolean
    });

    const [editMode, setEditMode] = useState(false);
    const [editClassId, setEditClassId] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const navigate = useNavigate();
    const apiBaseUrl = useApi();

    useEffect(() => {
        fetchClasses();
    }, []);

    const fetchClasses = async () => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/classes`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        setClasses(data);
    };

    const handleViewLecturesClick = (classItem) => {
        navigate(`/admin/classes/${classItem.classID}/lectures`, { state: { classID: classItem.classID, className: classItem.className } });
    };

    const handleOpenAddDialog = () => {
        setEditMode(false);
        setOpenAddDialog(true);
        setNewClass({ className: '', description: '', price: '', servicePeriod: '', teacherID: '', isActive: true });
    };

    const handleOpenEditDialog = (classItem) => {
        setEditMode(true);
        setEditClassId(classItem.classID);
        setNewClass({
            className: classItem.className,
            description: classItem.description,
            price: classItem.price,
            servicePeriod: classItem.servicePeriod,
            teacherID: classItem.teacherID,
            isActive: Boolean(classItem.isActive) // Convert to boolean if necessary
        });
        setOpenAddDialog(true);
    };
    

    const handleCloseAddDialog = () => {
        setOpenAddDialog(false);
        setNewClass({ className: '', description: '', price: '', servicePeriod: '', teacherID: '', isActive: true });
    };

    const handleAddOrUpdateClass = async () => {
        const token = localStorage.getItem('token');
        const method = editMode ? 'PUT' : 'POST';
        const url = editMode ? `${apiBaseUrl}/admin/classes/${editClassId}` : `${apiBaseUrl}/admin/classes`;

        const response = await fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(newClass)
        });

        if (response.ok) {
            Swal.fire(editMode ? 'Updated!' : 'Added!', `Class has been ${editMode ? 'updated' : 'added'}.`, 'success');
            handleCloseAddDialog();
            fetchClasses();
        } else {
            Swal.fire('Error!', `Failed to ${editMode ? 'update' : 'add'} class.`, 'error');
        }
    };

    const handleDeleteClick = async (classId) => {
        const firstConfirmation = window.confirm('Are you sure you want to delete this class? This action cannot be undone.');

        if (firstConfirmation) {
            const secondConfirmation = window.confirm('This is a permanent action. Are you absolutely sure you want to delete this class?');

            if (secondConfirmation) {
                const token = localStorage.getItem('token');
                const response = await fetch(`${apiBaseUrl}/admin/classes/${classId}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (response.ok) {
                    alert('Deleted! Class has been deleted.');
                    fetchClasses();
                } else {
                    alert('Error! Failed to delete class.');
                }
            }
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewClass({ ...newClass, [name]: value });
    };

    const handleIsActiveChange = (event) => {
        const value = event.target.value === 'true' || event.target.value === true;
        setNewClass({ ...newClass, isActive: value });
    };


    return (
        <div className='w-full'>
            <Typography variant="h4" gutterBottom>
                Classes Management
            </Typography>
            <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleOpenAddDialog} sx={{ mb: 2 }}>
                Add New Class
            </Button>
            <TableContainer component={Paper} sx={{ width: '100%' }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Class ID</TableCell>
                            <TableCell>Class Name</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Price (KRW)</TableCell>
                            <TableCell>Service Period</TableCell>
                            <TableCell>Is Active</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {classes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((classItem) => (
                            <TableRow key={classItem.classID}>
                                <TableCell>{classItem.classID}</TableCell>
                                <TableCell>{classItem.className}</TableCell>
                                <TableCell>{classItem.description}</TableCell>
                                <TableCell>{classItem.price}</TableCell>
                                <TableCell>{classItem.servicePeriod}</TableCell>
                                <TableCell>{classItem.isActive ? 'Yes' : 'No'}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => handleViewLecturesClick(classItem)}>
                                        <VisibilityIcon />
                                    </IconButton>
                                    <IconButton onClick={() => handleOpenEditDialog(classItem)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton onClick={() => handleDeleteClick(classItem.classID)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                count={classes.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <Dialog open={openAddDialog} onClose={handleCloseAddDialog} fullWidth maxWidth="sm">
                <DialogTitle>{editMode ? 'Edit Class' : 'Add New Class'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter the details of the class. The "Is Active" field determines whether the class is available for purchase (true) or not available (false).
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="className"
                        label="Class Name"
                        value={newClass.className}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <TextField
                        margin="dense"
                        name="description"
                        label="Description"
                        value={newClass.description}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <TextField
                        margin="dense"
                        name="price"
                        label="Price"
                        type="number"
                        value={newClass.price}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <TextField
                        margin="dense"
                        name="servicePeriod"
                        label="Service Period"
                        value={newClass.servicePeriod}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <TextField
                        margin="dense"
                        name="teacherID"
                        label="Teacher ID"
                        value={newClass.teacherID}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <FormControl fullWidth margin="dense">
                        <InputLabel id="isActive-label">Is Active</InputLabel>
                        <Select
                            labelId="isActive-label"
                            id="isActive"
                            value={newClass.isActive}  // This should now correctly hold true or false
                            onChange={handleIsActiveChange}
                            label="Is Active"
                        >
                            <MenuItem value={true}>True</MenuItem>
                            <MenuItem value={false}>False</MenuItem>
                        </Select>
                    </FormControl>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAddDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleAddOrUpdateClass} color="primary">
                        {editMode ? 'Update' : 'Add'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ClassesTable;
