import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
    Box, Container, Grid, Typography, Button, CircularProgress, Divider,
    LinearProgress
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import styled from 'styled-components';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { jwtDecode } from 'jwt-decode';
import TagScoreReport from './TagScoreReport'; // Import the TagScoreReport component
import { useApi } from '../../contexts/Apicontext';

const theme = createTheme({
    palette: {
        primary: {
            main: '#0f3e8e',
        },
        custom: {
            light: '#1434c7',
            dark: '#0a2b4d',
            default: '#0f3e8e',
            darker: '#0a1e3c',
            lightest: '#e1e8f7',
            correct: '#4caf50',
            incorrect: '#f44336',
        }
    },
});

const ENGLISH_TAGS = [
    "Information and Ideas",
    "Expression of Ideas",
    "Craft and Structure",
    "Standard English Conventions"
];

const ENGLISH_DETAILED_TAGS = {
    "Information and Ideas": ["Command of Evidence", "Inference", "Central Ideas and Details"],
    "Craft and Structure": ["Words in Context", "Text Structure and Purpose", "Cross-Text Connections"],
    "Expression of Ideas": ["Rhetorical Synthesis", "Transitions"],
    "Standard English Conventions": ["Boundaries", "Form, Structure, and Sense"]
};

const MATH_TAGS = [
    "Algebra",
    "Advanced Math",
    "Geometry and Trigonometry",
    "Problem-Solving and Data Analysis"
];

const MATH_DETAILED_TAGS = {
    "Algebra": [
        "Linear equations in one variable",
        "Linear functions in one variable",
        "Linear equations in two variables",
        "Systems of two linear equations in two variables",
        "Linear inequalities in one or two variables"
    ],
    "Geometry and Trigonometry": [
        "Area and volume",
        "Lines, angles, and triangles",
        "Right triangles and trigonometry",
        "Circles"
    ],
    "Advanced Math": [
        "Equivalent expressions",
        "Nonlinear equations in one variable and systems of equations in two variables",
        "Nonlinear functions"
    ],
    "Problem-Solving and Data Analysis": [
        "Ratios, rates, proportional relationships, and units",
        "Percentages",
        "One-variable data: Distributions and measures of center and spread",
        "Two-variable data: Models and scatterplots",
        "Probability and conditional probability",
        "Inference from sample statistics and margin of error",
        "Evaluating statistical claims: Observational studies and experiments"
    ]
};

const getDifficultyLabel = (difficulty) => {
    switch (difficulty) {
        case 3:
            return '난이도 하';
        case 2:
            return '난이도 중';
        case 1:
            return '난이도 상';
        default:
            return '';
    }
};

const CustomCircularProgress = styled(CircularProgress)`
  & .MuiCircularProgress-circle {
    stroke-linecap: round;
  }
`;

const HeaderBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

const CustomLinearProgress = styled(LinearProgress)`
  border-radius: 10px;
  &.MuiLinearProgress-root {
    background-color: ${theme.palette.custom.incorrect};
    height: 15px;
  }
  & .MuiLinearProgress-bar {
    border-radius: 10px;
    background-color: ${theme.palette.custom.default};
    height: 15px;
  }
`;

const BundleMockTestEndPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { lectureID, attemptID } = useParams();
    const { questions, score, classID, timer, category, bundleID, bundle, pastquestion, testdifficulty, bundleAttemptID } = location.state || {};
    const [userName, setUserName] = useState('');
    const [testDate, setTestDate] = useState('');
    const [tagReports, setTagReports] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // 로딩 상태 관리
    const [isUpdating, setIsUpdating] = useState(false); // Backend update state
    const apiBaseUrl = useApi();

    // Combine questions and pastquestions into totalQuestions for rendering
    const totalQuestions = [...questions, ...pastquestion];

    // Calculate total test score
    const [totalTestScore, setTotalTestScore] = useState(800);

    useEffect(() => {
        const token = localStorage.getItem('token');
        const decodedToken = jwtDecode(token);
        setUserName(decodedToken.name);
        setTestDate(new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }));
    }, []);

    useEffect(() => {
        // localStorage 초기화
        localStorage.removeItem(`timer_${classID}_${lectureID}_${bundleID}_bundle_second`);
        localStorage.removeItem(`questions_${classID}_${lectureID}_${bundleID}_bundle_second`);
        localStorage.removeItem(`currentOrder_${classID}_${lectureID}_${bundleID}_bundle_second`);
        localStorage.removeItem(`mockTest_${classID}_${lectureID}_${bundleID}_bundle_second`);
        localStorage.removeItem(`highlights_${classID}_${lectureID}_${bundleID}_bundle_second`);
        localStorage.removeItem(`evaluated_${classID}_${lectureID}_${bundleID}_bundle_second`);
        localStorage.removeItem(`difficulty_${classID}_${lectureID}_${bundleID}_bundle_main`);

        localStorage.removeItem(`timer_${classID}_${lectureID}_${bundleID}_bundle_main`);
        localStorage.removeItem(`questions_${classID}_${lectureID}_${bundleID}_bundle_main`);
        localStorage.removeItem(`currentOrder_${classID}_${lectureID}_${bundleID}_bundle_main`);
        localStorage.removeItem(`mockTest_${classID}_${lectureID}_${bundleID}_bundle_main`);
        localStorage.removeItem(`highlights_${classID}_${lectureID}_${bundleID}_bundle_main`);

        setIsLoading(false); // 로딩 상태를 해제
    }, [classID, lectureID, bundleID]);

    useEffect(() => {
        const TAGS = category === 'Math' ? MATH_TAGS : ENGLISH_TAGS;
        const DETAILED_TAGS = category === 'Math' ? MATH_DETAILED_TAGS : ENGLISH_DETAILED_TAGS;

        const tagMap = TAGS.reduce((acc, tag) => {
            acc[tag] = { total: 0, correct: 0, detailedTags: {} };
            DETAILED_TAGS[tag].forEach(detailedTag => {
                acc[tag].detailedTags[detailedTag] = { total: 0, correct: 0 };
            });
            return acc;
        }, {});

        totalQuestions.forEach(question => {
            const tag = question.tags; // Assume this is a string
            const detailedTag = question.detailedTags; // Assume this is a string
            if (TAGS.includes(tag) && tagMap[tag]) {
                tagMap[tag].total += 1;
                if (question.isCorrect) {
                    tagMap[tag].correct += 1;
                }
                if (DETAILED_TAGS[tag].includes(detailedTag) && tagMap[tag].detailedTags[detailedTag]) {
                    tagMap[tag].detailedTags[detailedTag].total += 1;
                    if (question.isCorrect) {
                        tagMap[tag].detailedTags[detailedTag].correct += 1;
                    }
                }
            }
        });

        const tagReportsArray = TAGS.map(tag => ({
            tagName: tag,
            totalQuestions: tagMap[tag].total,
            correctQuestions: tagMap[tag].correct,
            detailedTags: DETAILED_TAGS[tag].map(detailedTag => ({
                name: detailedTag,
                total: tagMap[tag].detailedTags[detailedTag].total,
                correct: tagMap[tag].detailedTags[detailedTag].correct
            }))
        }));

        setTagReports(tagReportsArray);
    }, [category]);

    useEffect(() => {
        const calculateTotalTestScore = () => {
            const totalDifficulty = totalQuestions.reduce((sum, question) => sum + question.difficulty, 0);
            const unitScore = 600 / totalDifficulty;
            let score = 800;

            totalQuestions.forEach((question) => {
                const isCorrect = question.selectedOption === question.answer;

                if (!isCorrect) {
                    score -= unitScore * question.difficulty;
                }
            });

            return Math.round(score / 10) * 10;
        };

        const newTotalTestScore = calculateTotalTestScore();

        setTotalTestScore(newTotalTestScore);
        const updateTotalScore = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch(`${apiBaseUrl}/bundle-mock-test-attempts-totalscore/${bundleAttemptID}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({ totalScore: newTotalTestScore })
                });

                if (!response.ok) {
                    throw new Error('Failed to update total score');
                }
            } catch (error) {
                console.error('Error updating total score:', error);
            } finally {
            }
        };

        updateTotalScore();
    }, [totalQuestions]);

    if (isLoading || isUpdating) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: 'black' }}>
                <CircularProgress color="primary" size='8rem' />
            </Box>
        );
    }

    if (!totalQuestions || score === undefined) {
        return <div>Error: No result data found.</div>;
    }

    const handleBackToLecture = () => {
        navigate(`/student`);
    };

    const correctCount = totalQuestions.filter(q => q.isCorrect).length;
    const incorrectCount = totalQuestions.length - correctCount;
    const correctPercentage = (correctCount / totalQuestions.length) * 100;

    return (
        <ThemeProvider theme={theme}>
            <Container disableGutters sx={{ display: 'flex', flexDirection: 'column', height: '100vh', width: '100vw', minWidth: '700px' }}>
                <HeaderBox>
                    <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
                        SAT Score Report
                    </Typography>
                </HeaderBox>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 3 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '30%' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mt: 2 }}>
                            <Typography variant="body1">Your Total Score</Typography>
                            <Typography variant="h1" sx={{ fontWeight: 'bold' }}>{totalTestScore}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', mt: 2 }}>
                            <Typography variant="body1">Test No: {attemptID}</Typography>
                            <Typography variant="body1">Date: {testDate}</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', width: '70%' }}>
                    </Box>
                </Box>

                <Divider sx={{ mt: 3, borderColor: theme.palette.custom.light, borderWidth: 5, mx: '-2vw' }} />

                <Box sx={{ mt: 5, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Box sx={{ mt: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '30%' }}>
                        <Box position="relative" display="inline-flex" sx={{ mt: 3 }}>
                            <Box
                                position={'absolute'}
                                top={-15}
                                left={-15}
                                bottom={0}
                                right={0}
                                sx={{
                                    borderRadius: '50%',
                                    width: 190,
                                    height: 190,
                                    boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.1)',
                                }}
                            />
                            <CustomCircularProgress
                                variant="determinate"
                                value={100}
                                size={160}
                                thickness={5}
                                sx={{ color: theme.palette.custom.lightest }}
                            />
                            <CustomCircularProgress
                                variant="determinate"
                                value={(totalTestScore / 800) * 100}
                                size={160}
                                thickness={3}
                                sx={{ color: theme.palette.custom.light, position: 'absolute', top: 0, left: 0 }}
                            />

                            <Box
                                top={0}
                                left={0}
                                bottom={0}
                                right={0}
                                position={'absolute'}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Typography variant="h4" component="div" color="textPrimary" sx={{ fontWeight: 'bold' }}>
                                    {totalTestScore}
                                </Typography>
                            </Box>
                        </Box>
                        <Box position="relative" left={0} sx={{ mt: 5, width: '80%' }}>
                            <CustomLinearProgress variant="determinate" value={correctPercentage} />
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                <Typography variant="body1" color={theme.palette.custom.default}>Correct: {correctCount}</Typography>
                                <Typography variant="body1" color={theme.palette.custom.incorrect}>Incorrect: {incorrectCount}</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '70%' }}>
                        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>Your Score Report</Typography>
                        {category === 'English' ? (
                            <Grid container spacing={2}>
                                {tagReports.map((tagReport, index) => (
                                    (tagReport.tagName === 'Information and Ideas' || tagReport.tagName === 'Craft and Structure') ? (
                                        <Grid item lg={12 * 3 / 5} xs={12} key={index}>
                                            <TagScoreReport
                                                tagName={tagReport.tagName}
                                                totalQuestions={tagReport.totalQuestions}
                                                correctQuestions={tagReport.correctQuestions}
                                                detailedTags={tagReport.detailedTags} // pass detailed tags data
                                                category={category}
                                            />
                                        </Grid>
                                    ) : (
                                        <Grid item lg={12 * 2 / 5} xs={12} key={index}>
                                            <TagScoreReport
                                                tagName={tagReport.tagName}
                                                totalQuestions={tagReport.totalQuestions}
                                                correctQuestions={tagReport.correctQuestions}
                                                detailedTags={tagReport.detailedTags} // pass detailed tags data
                                                category={category}
                                            />
                                        </Grid>
                                    )
                                ))}
                            </Grid>
                        ) : (
                            <Grid container spacing={1}>
                                {tagReports.map((tagReport, index) => (
                                    <Grid item lg={12} xs={12} key={index}>
                                        <TagScoreReport
                                            tagName={tagReport.tagName}
                                            totalQuestions={tagReport.totalQuestions}
                                            correctQuestions={tagReport.correctQuestions}
                                            detailedTags={tagReport.detailedTags} // pass detailed tags data
                                            category={category}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                    </Box>
                </Box>

                <Grid container spacing={1} sx={{ mt: 3 }}>
                    {/* Main Test Section */}
                    <Grid item xs={12}>
                        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
                            Main Test
                        </Typography>
                    </Grid>
                    {pastquestion.map((question, index) => (
                        <Grid item xs={12} sm={4} md={4} lg={3} xl={2} key={`main-${index}`}>
                            <Box display="flex" alignItems="center">
                                <Typography variant="h5" sx={{ fontWeight: 'bold', flexGrow: 1 }}>
                                    {question.order}
                                </Typography>
                                <Typography variant="body2" sx={{ flexGrow: 1 }}>
                                    {getDifficultyLabel(question.difficulty)}
                                </Typography>
                                {question.isCorrect ? (
                                    <CheckCircleIcon sx={{ color: theme.palette.custom.correct }} />
                                ) : (
                                    <CancelIcon sx={{ color: theme.palette.custom.incorrect }} />
                                )}
                            </Box>
                        </Grid>
                    ))}

                    {/* Conditional Section for Easy or Hard Test */}
                    <Grid item xs={12} sx={{ mt: 4 }}>
                        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
                            {`second Test (${testdifficulty})`}
                        </Typography>
                    </Grid>
                    {questions.map((question, index) => (
                        <Grid item xs={12} sm={4} md={4} lg={3} xl={2} key={`test-${index}`}>
                            <Box display="flex" alignItems="center">
                                <Typography variant="h5" sx={{ fontWeight: 'bold', flexGrow: 1 }}>
                                    {question.order}
                                </Typography>
                                <Typography variant="body2" sx={{ flexGrow: 1 }}>
                                    {getDifficultyLabel(question.difficulty)}
                                </Typography>
                                {question.isCorrect ? (
                                    <CheckCircleIcon sx={{ color: theme.palette.custom.correct }} />
                                ) : (
                                    <CancelIcon sx={{ color: theme.palette.custom.incorrect }} />
                                )}
                            </Box>
                        </Grid>
                    ))}
                </Grid>

                <Box sx={{ mt: 5, textAlign: 'center' }}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleBackToLecture}
                        sx={{
                            bgcolor: 'white',
                            borderColor: theme.palette.custom.default,
                            borderWidth: 4,
                            borderRadius: '20px',
                            my: 2,
                            py: 2,
                            '&:hover': {
                                bgcolor: theme.palette.custom.default,
                                color: 'white', // Change the text color on hover
                                borderWidth: 4,
                            },
                        }}
                    >
                        Back to Lecture
                    </Button>
                </Box>
            </Container>
        </ThemeProvider>
    );
};

export default BundleMockTestEndPage;
