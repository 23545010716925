// Footer.js
import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#0f3e8e',
        },
        custom: {
            light: '#4f7aa2',
            dark: '#0a2b4d',
            default: '#0f3e8e',
            darker: '#0a1e3c',
            lightest: '#f0f0f0',
            bookmark: '#d32f2f',
            black: '#000000',
            footer: '#241e29'
        }
    },
});

const Footer = () => {
    return (
        <ThemeProvider theme={theme}>
            <Box component="footer" sx={{ bgcolor: theme.palette.custom.footer, py: 6 }}>
                <Container >
                    <Grid container spacing={4} justifyContent="center">
                        <Grid item xs={12} md={9} display="flex" flexDirection="column" alignItems="start" gap={2}>
                            <Typography variant="h6" gutterBottom sx={{ color: 'white' }}>
                                Edu Agora
                            </Typography>
                            <Typography variant="body2" align="center" sx={{ color: 'white' }}>
                                (주)아고란  대표자명 : 김가령 | 사업자등록번호 : 815-81-02369 | 서울시 강남구 강남대로 84길 8, 우인빌딩 3층, 4층, 6층 | 02-2039-9242
                            </Typography>
                            <Typography variant="body2" align="center" sx={{ color: 'white' }}>
                                통신판매번호 : 제 2021-서울강남-06731 호
                            </Typography>
                            <Typography variant="body2" align="center" sx={{ color: 'white' }}>
                                Yeoksam-dong-3,4,6F, 8, Gangnam-daero 84-gil,, 8, Gangnam-daero 84-gil, Gangnam-gu, Seoul, Republic of Korea
                            </Typography>
                            <Typography variant="body2" align="center" sx={{ color: 'white' }}>
                                에듀아고라학원 제13600호 학교교과교습학원(종합: 국제화-외국어, 진학상담·지도)
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={3} display="flex" flexDirection="column" alignItems="center" gap={1}>
                            <Typography variant="h6" gutterBottom sx={{ color: 'white' }}>
                                Follow Us
                            </Typography>
                            <Box display="flex" flexDirection="row" alignItems="center" gap={1} >
                                <IconButton aria-label="Facebook" href="https://facebook.com">
                                    <FacebookIcon sx={{ color: 'white' }} />
                                </IconButton>
                                <IconButton aria-label="Twitter" href="https://twitter.com">
                                    <TwitterIcon sx={{ color: 'white' }} />
                                </IconButton>
                                <IconButton aria-label="Instagram" href="https://instagram.com">
                                    <InstagramIcon sx={{ color: 'white' }} />
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>
                    <Typography variant="body2" color="textSecondary" align="center" sx={{ pt: 4, color: 'white' }}>
                        © {new Date().getFullYear()} EduAgora. All rights reserved.
                    </Typography>
                </Container>
            </Box>
        </ThemeProvider>
    );
};

export default Footer;
