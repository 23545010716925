import React from 'react';
import { Button, ListItem, IconButton } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import './quillEditorStyles.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0f3e8e',
    },
    custom: {
      light: '#4f7aa2',
      dark: '#0a2b4d',
      default: '#0f3e8e',
      darker: '#0a1e3c',
      lightest: '#f0f0f0',
    },
  },
});

function QuizOption({ optionKey, optionType, optionValue, isSelected, isDisabled, onOptionSelect, onToggleDisable, showCancelIcon }) {
  return (
    <ThemeProvider theme={theme}>
      <ListItem disablePadding sx={{ display: 'flex', alignItems: 'center' }}>
        <Button
          variant="outlined"
          onClick={() => onOptionSelect(optionKey)}
          disabled={isDisabled}
          sx={{
            justifyContent: 'flex-start',
            textTransform: 'none',
            borderRadius: 3,
            width: '100%',
            minHeight: '7vh', // 버튼의 최소 높이 지정
            height: 'auto', // 버튼 높이를 내용에 맞게 자동 조절
            bgcolor: isSelected ? theme.palette.custom.default : 'white',
            color: isSelected ? 'white' : isDisabled ? 'grey' : theme.palette.custom.dark,
            fontSize: '1rem',
            textAlign: 'left', // 텍스트 왼쪽 정렬
            textDecoration: isDisabled ? 'line-through' : 'none',
            '&:hover': {
              bgcolor: isDisabled ? 'white' : theme.palette.custom.dark,
              color: isDisabled ? 'grey' : 'white',
              borderWidth: 2, // 테두리 두께 조절
            },
            borderColor: isDisabled ? 'grey' : theme.palette.custom.dark,
            borderWidth: 2, // 테두리 두께 조절
            mt: '1.5vh',
          }}
        >
          <div style={{ textAlign: 'left' }}>{optionKey}</div>
          <div style={{ marginLeft: '1rem' }}>
            {optionType === 'image' ? (
              <img src={optionValue} alt={`Option ${optionKey}`} style={{ maxWidth: '100%' }} />
            ) : (
              <div dangerouslySetInnerHTML={{ __html: optionValue }} />
            )}
          </div>
        </Button>
        {showCancelIcon && (
          <IconButton
            onClick={() => onToggleDisable(optionKey)}
            sx={{ color: isDisabled ? 'grey' : 'black', ml: 2, mt: '1.5vh' }}
          >
            <CancelIcon />
          </IconButton>
        )}
      </ListItem>
    </ThemeProvider>
  );
}

export default QuizOption;
