import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode'; 
import { useDispatch } from 'react-redux';
import { loginSuccess, loginFailure } from '../../actions/authActions';
import { FormControl, InputLabel, Input, InputAdornment, IconButton, Box, Typography, Button, Paper } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Swal from 'sweetalert2';
import CloseIcon from '@mui/icons-material/Close';
import { useApi } from '../../contexts/Apicontext';

export default function LoginPage({ closeDrawer }) {
    const emailRegEx = /^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,3}$/i;
    const passwordRegEx = /^[A-Za-z0-9 ]{8,20}$/;
    const imgURL = "https://agoran.kr/_next/static/media/logo.3863fbf2.png";

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailIsValid, setEmailIsValid] = useState(true);
    const [passwordIsValid, setPasswordIsValid] = useState(true);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(false);
    const apiBaseUrl = useApi();

    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
        setEmailIsValid(!newEmail || emailRegEx.test(newEmail));
    };

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        setPasswordIsValid(!newPassword || passwordRegEx.test(newPassword));
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const loginAndCloseDrawer = async (userData) => {
        dispatch(loginSuccess(userData));
        closeDrawer();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const emailCheck = email.trim() !== "" && emailIsValid;
        const passwordCheck = password.trim() !== "" && passwordIsValid;
        
        if (emailCheck && passwordCheck) {
            try {
                const response = await fetch(`${apiBaseUrl}/login`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email, password }),
                });

                if (response.ok) {
                    const data = await response.json();
                    


                    localStorage.setItem('token', data.token);
                    const { userID, role, email, name, isAdmin } = jwtDecode(data.token);
                    dispatch(loginSuccess({ userID, role, email, name, isAdmin }));
                    loginAndCloseDrawer({ userID, role, email, name, isAdmin });

                    if (data.requirePhoneNumber) {
                        Swal.fire('전화번호 필요', '전화번호를 입력해야 서비스를 이용할 수 있습니다.', 'warning');
                        navigate('/myprofile'); // Redirect to myprofile page
                        return;
                    }
                    
                    Swal.fire({
                        icon: 'success',
                        title: '로그인 성공',
                        text: `환영합니다! ${name}님`,
                    });

                    if (isAdmin) {
                        navigate('/admin', { state: { userID, role, name, email, isAdmin } });
                    } else {
                        switch (role) {
                            case 'teacher':
                            case 'student':
                                navigate('/', { state: { userID, role, name, email, isAdmin } });
                                break;
                            default:
                                navigate('/');
                                break;
                        }
                    }
                } else {
                    console.error('Login failed');
                    dispatch(loginFailure());
                    Swal.fire('로그인 실패', '이메일 또는 비밀번호를 확인해주세요.', 'error');
                }
            } catch (error) {
                console.error('Error during login:', error);
                Swal.fire('로그인 실패', '서버에 문제가 발생했습니다.', 'error');
                dispatch(loginFailure());
            }
        } else {
            setEmailIsValid(emailCheck);
            setPasswordIsValid(passwordCheck);
        }
    };

    return (
        <Paper elevation={0} sx={{ p: 4, borderRadius: 0, maxWidth: 500, margin: 'auto', position: 'relative', height: '90%', mt: 9 }}>
            <IconButton
                onClick={closeDrawer}
                sx={{ position: 'absolute', top: 8, right: 8 }}
            >
                <CloseIcon />
            </IconButton>
            <Typography variant="h4" sx={{ mt: 2, mb: 2, textAlign: 'center', color: '#0f3e8e' }}>Log In</Typography>
            <form onSubmit={handleSubmit}>
                <FormControl variant="outlined" fullWidth margin="normal">
                    <InputLabel htmlFor="email">Email</InputLabel>
                    <Input
                        id="email"
                        type="email"
                        value={email}
                        onChange={handleEmailChange}
                        error={!emailIsValid && email.trim() !== ""}
                    />
                    {!emailIsValid && email.trim() !== "" && <Typography sx={{ color: 'red', mt: 1 }}>이메일은 @와 .를 포함해야 합니다.</Typography>}
                </FormControl>
                
                <FormControl variant="outlined" fullWidth margin="normal">
                    <InputLabel htmlFor="password">Password</InputLabel>
                    <Input
                        id="password"
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={handlePasswordChange}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={togglePasswordVisibility}
                                    edge="end"
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                        error={!passwordIsValid && password.trim() !== ""}
                    />
                    {!passwordIsValid && password.trim() !== "" && <Typography sx={{ color: 'red', mt: 1 }}>비밀번호는 8-20자 사이여야 합니다.</Typography>}
                </FormControl>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    <Typography sx={{ color: 'green', cursor: 'pointer', textDecoration: 'underline' }}>Forgot Password?</Typography>
                </Box>
                
                <Button 
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 4, bgcolor: '#0f3e8e', borderRadius: 2, p: 2, fontWeight: 'bold', '&:hover': { bgcolor: '#0a2a5e' } }}
                >
                    Log In
                </Button>
            </form>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                <img src={imgURL} alt="logo" style={{ maxWidth: '80%' }} />
            </Box>
        </Paper>
    );
}
