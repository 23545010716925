import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ParagraphDivider from './ParagraphDivider';

const EducationalContent = ({ summary, detail, number }) => {
    return (
        <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ width: '100%', mt: 1, display: 'flex', justifyContent: 'flex-start' }}>
                <ParagraphDivider number={number} />
            </Box>
            
            <Box sx={{ display: 'flex', mt: 2 }}>
                <Box sx={{ width: '35%', pr: 5 }}>
                    <Typography paragraph sx={{ fontStyle: 'italic', wordBreak: 'keep-all' }}>
                        {summary}
                    </Typography>
                </Box>
                <Box sx={{ width: '65%' }}>
                    <Typography paragraph sx={{ wordBreak: 'keep-all', mt: -7 }}>
                        {detail}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default EducationalContent;
