// src/reducers/authReducer.js

const initialState = {
    isAuthenticated: null,
    user: null,
    error: false,
  };
  
  const authReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'LOGIN_SUCCESS':
        return {
          ...state,
          isAuthenticated: true,
          user: action.payload,
          error: false,
        };
        case 'LOGOUT_USER':
        return {
          ...state,
          isAuthenticated: false,
          user: null,
          error: false,
        };
        case 'LOGIN_FAILURE':
            return {
            ...state,
            error: true,
            };
        case 'SET_USER':
        return {
            ...state,
            user: action.payload,
            isAuthenticated: true,
            error: false,
            
        };
        default:
            return state;
    }
  };
  
  export default authReducer;
  