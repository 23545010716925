import React from 'react';
import { Typography, Box, CircularProgress } from '@mui/material';
import styled from '@emotion/styled';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        custom: {
            light: '#1434c7',
            lightest: '#e1e8f7',
        }
    },
});

const CustomCircularProgress = styled(CircularProgress)`
  & .MuiCircularProgress-circle {
    stroke-linecap: round;
  }
`;

const ScoreDisplay = ({ score }) => {
    return (
        <Box position="relative" display="inline-flex" sx={{ mt: 3}}>
            <Box
                position={'absolute'}
                top={-15}
                left={-15}
                bottom={0}
                right={0}
                sx={{
                    borderRadius: '50%',
                    width: 190,
                    height: 190,
                    boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.1)',
                }}
            />
            <CustomCircularProgress
                variant="determinate"
                value={100}
                size={160}
                thickness={5}
                sx={{ color: theme.palette.custom.lightest }}
            />
            <CustomCircularProgress
                variant="determinate"
                value={(score / 800) * 100}
                size={160}
                thickness={3}
                sx={{ color: theme.palette.custom.light, position: 'absolute', top: 0, left: 0 }}
            />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position={'absolute'}
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="h4" component="div" color="textPrimary" sx={{ fontWeight: 'bold' }}>
                    {score}
                </Typography>
            </Box>
        </Box>
    );
};

export default ScoreDisplay;
