import React, { useEffect, useState } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { Box, Grid, Typography, Divider, TextField, Button, InputAdornment, IconButton } from '@mui/material';
import { Search as SearchIcon, Clear as ClearIcon } from '@mui/icons-material';
import { useApi } from '../../contexts/Apicontext';
import { jwtDecode } from 'jwt-decode';

const MyProgress = () => {
    const [weeklyScores, setWeeklyScores] = useState({ math: [], english: [] });
    const [latestMathScore, setLatestMathScore] = useState(null);
    const [latestEnglishScore, setLatestEnglishScore] = useState(null);
    const [combinedScore, setCombinedScore] = useState(null);
    const [reachSchools, setReachSchools] = useState([]);
    const [safetySchools, setSafetySchools] = useState([]);
    const [noSATRequiredSchools, setNoSATRequiredSchools] = useState([]);
    const [customMathScore, setCustomMathScore] = useState('');
    const [customEnglishScore, setCustomEnglishScore] = useState('');
    const userID = jwtDecode(localStorage.getItem('token')).userID;
    const apiBaseUrl = useApi();

    const fetchColleges = async (mathScore, englishScore) => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${apiBaseUrl}/college-recommendations`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ mathScore, englishScore })
            });

            if (response.ok) {
                const data = await response.json();
                setReachSchools(data.reachSchools);
                setSafetySchools(data.safetySchools);
                setNoSATRequiredSchools(data.noSATRequiredSchools);
            } else {
                throw new Error('Failed to fetch colleges');
            }
        } catch (error) {
            console.error('Error fetching colleges:', error);
        }
    };

    const fetchWeeklyScores = async () => {
        try {
            const token = localStorage.getItem('token');

            // Fetch Math scores
            const mathResponse = await fetch(`${apiBaseUrl}/weekly-progress-Math`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!mathResponse.ok) {
                throw new Error('Failed to fetch weekly Math scores');
            }

            const mathData = await mathResponse.json();

            // Fetch English scores
            const englishResponse = await fetch(`${apiBaseUrl}/weekly-progress-English`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!englishResponse.ok) {
                throw new Error('Failed to fetch weekly English scores');
            }

            const englishData = await englishResponse.json();

            // Sort and map the data by weekStart date
            const sortedMathData = mathData
                .sort((a, b) => new Date(a.weekStart) - new Date(b.weekStart))
                .map(week => ({
                    x: new Date(week.weekStart), // Use Date objects for the x values
                    y: week.averageScore
                }));

            const sortedEnglishData = englishData
                .sort((a, b) => new Date(a.weekStart) - new Date(b.weekStart))
                .map(week => ({
                    x: new Date(week.weekStart), // Use Date objects for the x values
                    y: week.averageScore
                }));

            setWeeklyScores({
                math: [{ id: "Math Score", data: sortedMathData }],
                english: [{ id: "English Score", data: sortedEnglishData }],
            });
            console.log('Math scores:', sortedMathData);

            // Set the latest Math, English, and combined average score
            if (sortedMathData.length > 0 || sortedEnglishData.length > 0) {
                const latestMath = sortedMathData.length > 0 ? sortedMathData[sortedMathData.length - 1].y : 0;
                const latestEnglish = sortedEnglishData.length > 0 ? sortedEnglishData[sortedEnglishData.length - 1].y : 0;
                const combined = (latestMath + latestEnglish);
                setLatestMathScore(latestMath);
                setLatestEnglishScore(latestEnglish);
                setCombinedScore(combined);
                fetchColleges(latestMath, latestEnglish);
            }
        } catch (error) {
            console.error('Error fetching weekly scores:', error);
        }
    };

    const handleApplyCustomScore = () => {
        if (customMathScore && customEnglishScore) {
            fetchColleges(customMathScore, customEnglishScore);
        }
    };

    const handleResetToLatestScores = () => {
        if (latestMathScore !== null && latestEnglishScore !== null) {
            setCustomMathScore('');
            setCustomEnglishScore('');
            fetchColleges(latestMathScore, latestEnglishScore);
        }
    };

    useEffect(() => {
        fetchWeeklyScores();
    }, [userID, apiBaseUrl]);

    const isApplyButtonDisabled = !customMathScore || !customEnglishScore;

    return (
        <Box sx={{ padding: 3 }}>
            <Grid container spacing={4}>
                {/* Left Sidebar - Latest Scores and Custom Score Input */}
                <Grid item xs={12} md={3} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, justifyContent: 'center', alignItems: 'center', mt: 10, width: '70%' }}>
                        <Divider sx={{ width: '100%', mb: 2, borderColor: 'black', borderWidth: '2.5px' }} />
                        <Typography variant="h4" sx={{ mb: 2, fontWeight: 'bold' }}>최근 점수</Typography>
                        <Typography variant="h6" sx={{fontWeight:'bold'}}>Math: {latestMathScore !== null ? latestMathScore.toFixed(0) : 'Loading...'}</Typography>
                        <Typography variant="h6" sx={{fontWeight:'bold'}}>English: {latestEnglishScore !== null ? latestEnglishScore.toFixed(0) : 'Loading...'}</Typography>
                        <Typography variant="h6" sx={{fontWeight:'bold'}}>Total: {combinedScore !== null ? combinedScore.toFixed(0) : 'Loading...'}</Typography>
                        <Divider sx={{ width: '100%', mt: 2, borderColor: 'black', borderWidth: '2.5px' }} />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', mt: 25, width: '70%' }}>
                        <Divider sx={{ width: '100%', mb: 4, borderColor: 'black', borderWidth: '2.5px' }} />
                        <TextField
                            variant="standard"
                            placeholder="Custom Math Score"
                            value={customMathScore}
                            onChange={(e) => setCustomMathScore(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: customMathScore && (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => setCustomMathScore('')}>
                                            <ClearIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                disableUnderline: true, // Removes the default underline
                            }}
                            sx={{
                                paddingLeft: '10px',
                                paddingBottom: '8px',
                                borderBottom: '1.5px solid #000', // Custom underline style
                                fontSize: '14px',
                                fontWeight: 'bold',
                            }}
                        />
                        <TextField
                            variant="standard"
                            placeholder="Custom English Score"
                            value={customEnglishScore}
                            onChange={(e) => setCustomEnglishScore(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: customEnglishScore && (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => setCustomEnglishScore('')}>
                                            <ClearIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                disableUnderline: true, // Removes the default underline
                            }}
                            sx={{
                                paddingLeft: '10px',
                                paddingBottom: '8px',
                                borderBottom: '1.5px solid #000', // Custom underline style
                                fontSize: '14px',
                                fontWeight: 'bold',
                                mt: 2, // Add margin-top between fields
                            }}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleApplyCustomScore}
                            sx={{ mt: 2 }}
                            disabled={isApplyButtonDisabled} // Disable button if fields are not filled
                        >
                            Apply Custom Scores
                        </Button>
                        <Button variant="outlined" onClick={handleResetToLatestScores} sx={{ mt: 1 }}>
                            Reset to Latest Scores
                        </Button>
                        <Divider sx={{ width: '100%', mb: 2, borderColor: 'black', borderWidth: '2.5px', mt:4 }} />
                    </Box>
                </Grid>

                {/* Right Content - Line Graph */}
                <Grid item xs={12} md={9}>
                    <Typography variant="h3" sx={{ my: 4, mx: 5, fontWeight: 'bold' }}>
                        나의 학습 진행 상황
                    </Typography>
                    <Box sx={{ height: '400px', mx: 10 }}>
                        {weeklyScores.math.length > 0 || weeklyScores.english.length > 0 ? (
                            <ResponsiveLine
                                data={[...weeklyScores.math, ...weeklyScores.english]}
                                margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                                xScale={{ type: 'time', format: '%Y-%m-%d' }}
                                yScale={{
                                    type: 'linear',
                                    min: 0,
                                    max: 800,  // Adjust based on the expected score range
                                    stacked: false,
                                    reverse: false
                                }}
                                xFormat="time:%Y-%m-%d"
                                yFormat={value => `${Math.round(value)}점`}
                                curve="natural"
                                axisTop={null}
                                axisRight={null}
                                axisBottom={{
                                    orient: 'bottom',
                                    format: '%Y-%m-%d',
                                    tickValues: 'every 1 week',
                                    legend: 'Week',
                                    legendOffset: 36,
                                    legendPosition: 'middle'
                                }}
                                axisLeft={{
                                    orient: 'left',
                                    legend: 'Average Score',
                                    legendOffset: -40,
                                    legendPosition: 'middle'
                                }}
                                colors={{ scheme: 'nivo' }}
                                pointSize={10}
                                pointColor={{ theme: 'background' }}
                                pointBorderWidth={2}
                                pointBorderColor={{ from: 'serieColor' }}
                                pointLabelYOffset={-12}
                                useMesh={true}
                                isInteractive={true}
                                enableSlices="x"
                                tooltip={({ point }) => (
                                    <div
                                        style={{
                                            background: 'white',
                                            padding: '5px 10px',
                                            border: '1px solid #ccc',
                                            borderRadius: '3px',
                                            boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)'
                                        }}
                                    >
                                        <strong>{point.data.xFormatted}</strong>
                                        <br />
                                        {`점수: ${point.data.yFormatted}`}
                                    </div>
                                )}
                                legends={[
                                    {
                                        anchor: 'bottom-right',
                                        direction: 'column',
                                        justify: false,
                                        translateX: 100,
                                        translateY: 0,
                                        itemsSpacing: 0,
                                        itemDirection: 'left-to-right',
                                        itemWidth: 80,
                                        itemHeight: 20,
                                        itemOpacity: 0.75,
                                        symbolSize: 12,
                                        symbolShape: 'circle',
                                        symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                        effects: [
                                            {
                                                on: 'hover',
                                                style: {
                                                    itemBackground: 'rgba(0, 0, 0, .03)',
                                                    itemOpacity: 1
                                                }
                                            }
                                        ]
                                    }
                                ]}
                                motionConfig="stiff"
                            />
                        ) : (
                            <p>Loading...</p>
                        )}
                    </Box>
                    <Box sx={{ mt: 4 }}>
                        <Typography variant="h3" sx={{ my: 4, mx: 5, fontWeight: 'bold' }}>갈 수 있는 대학 목록</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <Typography variant="h6" sx={{ mb: 1, fontWeight: 'bold' }}>Reach Schools</Typography>
                                <ul>
                                    {reachSchools.length > 0 ? reachSchools.sort((a, b) => a.ranking - b.ranking).map((school, index) => (
                                        <li key={school.id}>
                                            <strong>{index + 1}. {school.college_name}</strong>
                                            <Divider sx={{ mt: 1, mb: 1 }} />
                                        </li>
                                    )) : <li>No Reach Schools available</li>}
                                </ul>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="h6" sx={{ mb: 1, fontWeight: 'bold' }}>Safety Schools</Typography>
                                <ul>
                                    {safetySchools.length > 0 ? safetySchools.sort((a, b) => a.ranking - b.ranking).map((school, index) => (
                                        <li key={school.id}>
                                            <strong>{index + 1}. {school.college_name}</strong>
                                            <Divider sx={{ mt: 1, mb: 1 }} />
                                        </li>
                                    )) : <li>No Safety Schools available</li>}
                                </ul>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="h6" sx={{ mb: 1, fontWeight: 'bold' }}>No SAT Required Schools</Typography>
                                <ul>
                                    {noSATRequiredSchools.length > 0 ? noSATRequiredSchools.sort((a, b) => a.ranking - b.ranking).map((school, index) => (
                                        <li key={school.id}>
                                            <strong>{index + 1}. {school.college_name}</strong>
                                            <Divider sx={{ mt: 1, mb: 1 }} />
                                        </li>
                                    )) : <li>No No SAT Required Schools available</li>}
                                </ul>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ mt: 4 }}>
                        <Divider sx={{ width: '100%', mb: 2, borderColor: 'black', borderWidth: '1.5px' }} />
                        <Typography variant="body2" sx={{ mt: 2, mx: 5, color: '#888' }}>
                            <strong>Ranking Source:</strong> https://www.usnews.com/best-colleges/rankings/national-universities?_sort=rank&_sortDirection=asc<br />
                            <strong>Acceptance Rate and SAT Score Range Source:</strong> https://bigfuture.collegeboard.org/colleges/stanford-university/admissions<br />
                            <em>Note: College profiles are based primarily on information supplied by the colleges themselves through participation in College Board's BigFuture College Profiles higher education data collection portal, with some data provided via federal and state agencies. Costs, dates, policies, and programs are subject to change, so please confirm important facts with college admission personnel.</em>
                        </Typography>
                    </Box>
                </Grid>

                {/* Attribution Section */}
                <Grid item xs={12}>

                </Grid>
            </Grid>
        </Box>
    );
};

export default MyProgress;
