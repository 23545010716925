import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Dialog, DialogTitle, DialogContent, Card, CardContent } from '@mui/material';
import { DialogActions, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { createTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

const DictionarySearch = ({ open, onClose }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResult, setSearchResult] = useState(null);
    const theme = createTheme({
        palette: {
            primary: {
                main: '#0f3e8e',
            },
            custom: {
                light: '#4f7aa2',
                dark: '#0a2b4d',
                default: '#0f3e8e',
                darker: '#0a1e3c',
                lightest: '#f0f0f0'
            }
        },
    });

    const searchDictionary = async (term) => {
        try {
            const response = await fetch(`https://api.dictionaryapi.dev/api/v2/entries/en/${term}`);
            if (!response.ok) throw new Error('단어를 찾을 수 없습니다.');
            const data = await response.json();
            setSearchResult(data);
        } catch (error) {
            setSearchResult(null);
            console.error("검색 에러:", error);
        }
    };

    const handleSearch = () => {
        if (!searchTerm.trim()) return;
        searchDictionary(searchTerm);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    const handleClose = () => {
        onClose(); // 부모 컴포넌트에서 전달된 onClose 함수 호출
        setSearchResult(null); // Dialog가 닫힐 때 검색 결과 초기화
        setSearchTerm(''); // Dialog가 닫힐 때 검색어 초기화
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
                <DialogTitle>Word Searching</DialogTitle>
                <DialogActions sx={{ m: 0, p: 0 }}>
                    <IconButton onClick={handleClose} sx={{ color: theme.palette.custom.darker }}>
                        <CloseIcon />
                    </IconButton>
                </DialogActions>
            </Box>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', mb: 2, width: '100%' }}>
                        <TextField
                            label="Search Word"
                            variant="outlined"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            onKeyPress={handleKeyPress} // 엔터 키 입력 처리
                            fullWidth
                            sx={{ mr: 1, '& .MuiOutlinedInput-root': { borderRadius: '20px' }, '& .MuiInputLabel-root': { color: theme.palette.custom.darker } }}
                        />
                        <Button variant="contained" onClick={handleSearch} sx={{ ml: 1, bgcolor: theme.palette.custom.dark, color: 'white', borderRadius: '20px', '&:hover': { bgcolor: 'grey' } }}>Search</Button>
                    </Box>
                    {searchResult && searchResult.map((entry, entryIndex) => (
                        <Card key={entryIndex} sx={{ mb: 2, width: '100%', bgcolor: theme.palette.custom.lightest }}>
                            <CardContent>
                                <Typography variant="h5" sx={{ color: theme.palette.custom.dark }}>
                                    {entry.word}
                                </Typography>
                                <Divider sx={{ my: 1 }} />
                                {entry.meanings.map((meaning, meaningIndex) => (
                                    <Box key={meaningIndex} sx={{ mb: 2 }}>
                                        <Typography variant="h6" sx={{ fontStyle: 'italic', color: theme.palette.custom.darker }}>
                                            {meaning.partOfSpeech}
                                        </Typography>
                                        <Typography variant="body1" sx={{ color: theme.palette.custom.dark }}>
                                            {meaning.definitions[0].definition}
                                        </Typography>
                                        {meaning.definitions[0].example && (
                                            <Typography variant="body2" sx={{ mt: 1, color: theme.palette.custom.light }}>
                                                Example: {meaning.definitions[0].example}
                                            </Typography>
                                        )}
                                        <Divider sx={{ my: 1, bgcolor: theme.palette.custom.light }} />
                                    </Box>
                                ))}
                            </CardContent>
                        </Card>
                    ))}
                    {searchResult === null && <Typography>검색 결과가 없습니다.</Typography>}
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default DictionarySearch;
