import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import MainPageHeader from '../MainPage_components/MainPageHeader';
import LecturePageBody from './LecturePage_body';
import { useApi } from '../../contexts/Apicontext';

function LectureList() {
    const [lectures, setLectures] = useState([]);
    const [loading, setLoading] = useState(true); // 로딩 상태 추가
    const [hasLectures, setHasLectures] = useState(false); // 강의 존재 여부 상태 추가
    const { classID } = useParams();
    const location = useLocation();
    const className = location.state.className;
    const apiBaseUrl = useApi();

    useEffect(() => {
        async function fetchQuizzes() {
            const token = localStorage.getItem('token'); // localStorage에서 토큰 가져오기

            try {
                const response = await fetch(`${apiBaseUrl}/classes/${classID}/lectures`, {
                    headers: {
                        'Authorization': `Bearer ${token}` // Authorization 헤더에 토큰 추가
                    }
                });

                if (response.ok) {
                    const data = await response.json();
                    setLectures(data);
                    setHasLectures(data.length > 0); // 강의 존재 여부 상태 업데이트
                } else {
                    console.error('Failed to fetch quizzes');
                }
            } catch (error) {
                console.error('Error fetching quizzes:', error);
            } finally {
                setLoading(false); // 로딩 완료 후 상태 업데이트
            }
        }

        fetchQuizzes();
    }, [classID]);

    return (
        <div>
            <MainPageHeader />
            <LecturePageBody 
                lectures={lectures} 
                className={className} 
                classID={classID} 
                loading={loading} // 로딩 상태 전달
                hasLectures={hasLectures} // 강의 존재 여부 전달
            />
        </div>
    );
}

export default LectureList;
