import React, { useState } from 'react';
import { Box, Toolbar, Container } from '@mui/material';
import AdminHeader from './AdminHeader';
import AdminMenuDrawer from './AdminMenuDrawer';
import QuizzesTable from './QuizzesTable';
import QuizQuestionsPage from './QuizQuestionsPage';
import QuizQuestionDetailsPage from './QuizQuestionDetailsPage';
import { Routes, Route, useNavigate } from 'react-router-dom';

const drawerWidth = 240;

const QuizAdminPage = () => {
    const [mobileOpen, setMobileOpen] = useState(false);
    const navigate = useNavigate();

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handlePageChange = (page, state) => {
        navigate(`/admin/${page}`, { state });
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <AdminHeader handleDrawerToggle={handleDrawerToggle} />
            <AdminMenuDrawer handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} />
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    bgcolor: 'background.default',
                    p: 3,
                    width: { sm: '100%' } // Calculate width excluding drawer
                }}
            >
                <Toolbar />
                <Box sx={{ width: '100%' }}>
                    <Routes>
                        <Route path="/" element={<QuizzesTable onPageChange={handlePageChange} />} />
                        <Route path="/:quizID/questions" element={<QuizQuestionsPage onPageChange={handlePageChange}/>} />
                        <Route path="/:quizID/questions/:questionID" element={<QuizQuestionDetailsPage onPageChange={handlePageChange}/>} />
                    </Routes>
                </Box>
            </Box>
        </Box>
    );
};

export default QuizAdminPage;
