import React, { useState, useEffect } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField, Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useLocation, useNavigate } from 'react-router-dom';
import { useApi } from '../../contexts/Apicontext';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const DetailedTagsTable = () => {
    const [detailedTags, setDetailedTags] = useState([]);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [newDetailedTag, setNewDetailedTag] = useState({ detailedTagName: '' });
    const [currentDetailedTag, setCurrentDetailedTag] = useState({ detailedTagID: null, detailedTagName: '' });
    const location = useLocation();
    const { tagID, tagName } = location.state || {};
    const apiBaseUrl = useApi();
    const navigate = useNavigate();

    useEffect(() => {
        fetchDetailedTags();
    }, [tagID]);

    const fetchDetailedTags = async () => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/tags/${tagID}/detailed`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        setDetailedTags(data);
        console.log(data);
    };

    const handleOpenAddDialog = () => {
        setOpenAddDialog(true);
    };

    const handleCloseAddDialog = () => {
        setOpenAddDialog(false);
        setNewDetailedTag({ detailedTagName: '' });
    };

    const handleOpenEditDialog = (detailedTag) => {
        setCurrentDetailedTag(detailedTag);
        setOpenEditDialog(true);
    };

    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
        setCurrentDetailedTag({ detailedTagID: null, detailedTagName: '' });
    };

    const handleAddDetailedTag = async () => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/tags/${tagID}/detailed`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(newDetailedTag)
        });

        if (response.ok) {
            alert('Added! New detailed tag has been added.');
            handleCloseAddDialog();
            fetchDetailedTags();
        } else {
            alert('Error! Failed to add new detailed tag.');
        }
    };

    const handleEditDetailedTag = async () => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/tags/detailed/${currentDetailedTag.detailedTagID}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ detailedTagName: currentDetailedTag.detailedTagName })
        });

        if (response.ok) {
            alert('Updated! Detailed tag has been updated.');
            handleCloseEditDialog();
            fetchDetailedTags();
        } else {
            alert('Error! Failed to update detailed tag.');
        }
    };

    const handleDeleteClick = async (detailedTagID) => {
        const firstConfirmation = window.confirm('Are you sure you want to delete this detailed tag? This action cannot be undone.');

        if (firstConfirmation) {
            const secondConfirmation = window.confirm('This is a permanent action. Are you absolutely sure you want to delete this detailed tag?');

            if (secondConfirmation) {
                const token = localStorage.getItem('token');
                const response = await fetch(`${apiBaseUrl}/admin/tags/detailed/${detailedTagID}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (response.ok) {
                    alert('Deleted! Detailed tag has been deleted.');
                    fetchDetailedTags();
                } else {
                    alert('Error! Failed to delete detailed tag.');
                }
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewDetailedTag({ ...newDetailedTag, [name]: value });
    };

    const handleEditInputChange = (e) => {
        const { name, value } = e.target;
        setCurrentDetailedTag({ ...currentDetailedTag, [name]: value });
    };

    return (
        <div className='w-full'>
            <IconButton onClick={() => navigate(-1)} color="primary">
                <ArrowBackIcon />
            </IconButton>
            <Typography variant="h4" gutterBottom>
                Detailed Tags for Tag : {tagName}
            </Typography>
            <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleOpenAddDialog} sx={{ mb: 2 }}>
                Add New Detailed Tag
            </Button>
            <TableContainer component={Paper} sx={{ width: '100%' }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Detailed Tag ID</TableCell>
                            <TableCell>Detailed Tag Name</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {detailedTags.map((detailedTag) => (
                            <TableRow key={detailedTag.detailedTagID}>
                                <TableCell>{detailedTag.detailedTagID}</TableCell>
                                <TableCell>{detailedTag.detailedTagName}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => handleOpenEditDialog(detailedTag)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton onClick={() => handleDeleteClick(detailedTag.detailedTagID)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog open={openAddDialog} onClose={handleCloseAddDialog} fullWidth maxWidth="sm">
                <DialogTitle>Add New Detailed Tag</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter the details of the new detailed tag.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="detailedTagName"
                        label="Detailed Tag Name"
                        value={newDetailedTag.detailedTagName}
                        onChange={handleInputChange}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAddDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleAddDetailedTag} color="primary">
                        Add
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openEditDialog} onClose={handleCloseEditDialog} fullWidth maxWidth="sm">
                <DialogTitle>Edit Detailed Tag</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Update the details of the detailed tag.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="detailedTagName"
                        label="Detailed Tag Name"
                        value={currentDetailedTag.detailedTagName}
                        onChange={handleEditInputChange}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEditDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleEditDetailedTag} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default DetailedTagsTable;
