import { useState, useEffect } from 'react';
import Header from './MainPageHeader';
import Footer from './MainPageFooter';
import Divider from '@mui/material/Divider';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Box, Typography, Button, Paper, FormControl, InputLabel, Input, InputAdornment, IconButton, Checkbox, FormControlLabel, Grid, Select, MenuItem } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useApi } from '../../contexts/Apicontext';
import axios from 'axios';

export default function SignUpPage() {
    const emailRegEx = /^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,3}$/i;
    const passwordRegEx = /^[A-Za-z0-9 ]{8,20}$/;

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [phone, setPhone] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [emailIsValid, setEmailIsValid] = useState(true);
    const [passwordIsValid, setPasswordIsValid] = useState(true);
    const [phoneIsValid, setPhoneIsValid] = useState(true);
    const [countryCode, setCountryCode] = useState(''); // Start with an empty string
    const [confirmPasswordIsValid, setConfirmPasswordIsValid] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [consentAll, setConsentAll] = useState(false);
    const [consentPrivacy, setConsentPrivacy] = useState(false);
    const [consentNewsletter, setConsentNewsletter] = useState(false);
    const [consentTerms, setConsentTerms] = useState(false);
    const [countryCodes, setCountryCodes] = useState([]); // Store the fetched country codes

    const navigate = useNavigate();
    const apiBaseUrl = useApi();

    useEffect(() => {
        const fetchCountryCodes = async () => {
            try {
                const response = await axios.get('https://restcountries.com/v3.1/all');
                const codes = response.data
                    .map((country, index) => ({
                        name: country.name.common,
                        code: country.idd?.root ? `${country.idd.root}${country.idd.suffixes[0] || ''}` : '',
                        id: index, // Add a unique identifier using index
                    }))
                    .filter(country => country.code)
                    .sort((a, b) => a.name.localeCompare(b.name)); // Sort by country name

                setCountryCodes(codes);

                if (codes.length > 0) {
                    setCountryCode(codes[0].code); // Set to the first country code in the list
                }
            } catch (error) {
                console.error('Error fetching country codes:', error);
            }
        };

        fetchCountryCodes();
    }, []);

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
        setEmailIsValid(emailRegEx.test(newEmail));
    };

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        setPasswordIsValid(passwordRegEx.test(newPassword));
    };

    const handleConfirmPasswordChange = (e) => {
        const newConfirmPassword = e.target.value;
        setConfirmPassword(newConfirmPassword);
        setConfirmPasswordIsValid(password === newConfirmPassword);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleConsentAllChange = (e) => {
        const checked = e.target.checked;
        setConsentAll(checked);
        setConsentPrivacy(checked);
        setConsentNewsletter(checked);
        setConsentTerms(checked);
    };

    const handleConsentPrivacyChange = (e) => {
        const checked = e.target.checked;
        setConsentPrivacy(checked);
        if (!checked) {
            setConsentAll(false);
        } else if (consentNewsletter && consentTerms) {
            setConsentAll(true);
        }
    };

    const handleConsentNewsletterChange = (e) => {
        const checked = e.target.checked;
        setConsentNewsletter(checked);
        if (!checked) {
            setConsentAll(false);
        } else if (consentPrivacy && consentTerms) {
            setConsentAll(true);
        }
    };

    const handleConsentTermsChange = (e) => {
        const checked = e.target.checked;
        setConsentTerms(checked);
        if (!checked) {
            setConsentAll(false);
        } else if (consentPrivacy && consentNewsletter) {
            setConsentAll(true);
        }
    };

    const validatePhone = (phoneNumber) => {
        const phoneRegEx = /^\d{7,15}$/;
        return phoneRegEx.test(phoneNumber);
    };

    const handlePhoneChange = (e) => {
        const newPhone = e.target.value;
        setPhone(newPhone);
        setPhoneIsValid(validatePhone(newPhone));
    };

    const handleCountryCodeChange = (e) => {
        setCountryCode(e.target.value);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (emailIsValid && passwordIsValid && confirmPasswordIsValid && consentPrivacy && consentTerms && phoneIsValid) {
            try {
                const signupDate = new Date().toISOString(); // Get the current date and time in ISO format
    
                const response = await fetch(`${apiBaseUrl}/signup`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        name,
                        email,
                        password,
                        phoneNumber: phone,
                        countryCode,
                        signupDate, // Include signupDate in the request body
                    }),
                });
    
                if (response.ok) {
                    Swal.fire('회원가입 성공', '회원가입이 완료되었습니다. 로그인해주세요.', 'success');
                    navigate('/'); // Redirect to login page
                } else if (response.status === 409) {  
                    Swal.fire('회원가입 실패', '이미 사용 중인 이메일입니다.', 'error');
                } else {
                    const errorData = await response.json();
                    Swal.fire('회원가입 실패', errorData.message || '회원가입에 실패했습니다.', 'error');
                }
            } catch (error) {
                console.error('Error during sign-up:', error);
                Swal.fire('회원가입 실패', '서버에 문제가 발생했습니다.', 'error');
            }
        } else {
            Swal.fire('유효성 검사 실패', '입력한 정보를 확인해주세요.', 'error');
        }
    };
    

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Header />
            <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                <Paper elevation={0} sx={{ width: '100%', maxWidth: '700px', p: 4 }}>
                    <Typography variant="h4" align="center" gutterBottom>
                        Sign Up
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <FormControl variant="outlined" fullWidth margin="normal">
                            <InputLabel htmlFor="name">Name</InputLabel>
                            <Input
                                id="name"
                                value={name}
                                onChange={handleNameChange}
                            />
                        </FormControl>
                        <FormControl variant="outlined" fullWidth margin="normal">
                            <InputLabel htmlFor="email">Email</InputLabel>
                            <Input
                                id="email"
                                type="email"
                                value={email}
                                onChange={handleEmailChange}
                                error={!emailIsValid && email.trim() !== ""}
                            />
                            {!emailIsValid && email.trim() !== "" && <Typography sx={{ color: 'red', mt: 1 }}>이메일은 @와 .를 포함해야 합니다.</Typography>}
                        </FormControl>
                        <FormControl variant="outlined" fullWidth margin="normal">
                            <InputLabel htmlFor="password">Password</InputLabel>
                            <Input
                                id="password"
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={handlePasswordChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={togglePasswordVisibility}
                                            edge="end"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                error={!passwordIsValid && password.trim() !== ""}
                            />
                            {!passwordIsValid && password.trim() !== "" && <Typography sx={{ color: 'red', mt: 1 }}>비밀번호는 8-20자 사이여야 합니다.</Typography>}
                        </FormControl>
                        <FormControl variant="outlined" fullWidth margin="normal">
                            <InputLabel htmlFor="confirm-password">Confirm Password</InputLabel>
                            <Input
                                id="confirm-password"
                                type={showPassword ? 'text' : 'password'}
                                value={confirmPassword}
                                onChange={handleConfirmPasswordChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={togglePasswordVisibility}
                                            edge="end"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                error={!confirmPasswordIsValid && confirmPassword.trim() !== ""}
                            />
                            {!confirmPasswordIsValid && confirmPassword.trim() !== "" && <Typography sx={{ color: 'red', mt: 1 }}>비밀번호가 일치하지 않습니다.</Typography>}
                        </FormControl>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={3}>
                                <FormControl variant="outlined" fullWidth margin="normal">
                                    <InputLabel htmlFor="country-code">Country Code</InputLabel>
                                    <Select
                                        id="country-code"
                                        value={countryCode}
                                        onChange={handleCountryCodeChange}
                                        label="Country Code"
                                    >
                                        {countryCodes.map((country) => (
                                            <MenuItem key={`${country.name}-${country.code}`} value={country.code}>
                                                {`${country.name} (${country.code})`}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={9}>
                                <FormControl variant="outlined" fullWidth margin="normal">
                                    <InputLabel htmlFor="phone">Phone Number</InputLabel>
                                    <Input
                                        id="phone"
                                        value={phone}
                                        onChange={handlePhoneChange}
                                        error={!phoneIsValid && phone.trim() !== ""}
                                    />
                                    {!phoneIsValid && phone.trim() !== "" && <Typography sx={{ color: 'red', mt: 1 }}>전화번호가 올바르지 않습니다.</Typography>}
                                </FormControl>
                            </Grid>
                        </Grid>
                        <FormControlLabel
                            control={<Checkbox checked={consentAll} onChange={handleConsentAllChange} />}
                            label="Select all consents"
                            sx={{ alignItems: 'flex-start', marginTop: 2 }}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={consentPrivacy} onChange={handleConsentPrivacyChange} />}
                            label="Customer data privacy. The personal data provided by you is used to answer inquiries, process orders or allow access to specific information. You have the right to modify and delete any personal data posted on the 'My Account' page. *"
                            sx={{ alignItems: 'flex-start' }}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={consentNewsletter} onChange={handleConsentNewsletterChange} />}
                            label="Sign up for our newsletter. You can unsubscribe at any time. For that purpose, please find our contact info in the legal notice."
                            sx={{ alignItems: 'flex-start' }}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={consentTerms} onChange={handleConsentTermsChange} />}
                            label="I accept the general terms of use and privacy policy *"
                            sx={{ alignItems: 'flex-start' }}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 4, bgcolor: '#0f3e8e', borderRadius: 2, p: 2, fontWeight: 'bold', '&:hover': { bgcolor: '#0a2a5e' } }}
                            disabled={!consentPrivacy || !consentTerms}
                        >
                            Sign Up
                        </Button>
                    </form>
                </Paper>
            </Box>
            <Divider variant="middle" sx={{ mt: 4 }} />
            <Footer />
        </Box>
    );
}
