import React from 'react';
import { Button, ListItem } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './quillEditorStyles.css';

const theme = createTheme({
    palette: {
        primary: {
            main: '#0f3e8e',
        },
        custom: {
            light: '#4f7aa2',
            dark: '#0a2b4d',
            default: '#0f3e8e',
            darker: '#0a1e3c',
            lightest: '#f0f0f0',
        },
    },
});


function QuizOption({ optionKey, optionType, optionValue, isSelected, onOptionSelect, answer }) {
    return (
        <ThemeProvider theme={theme}>
            <ListItem disablePadding sx={{ display: 'flex', alignItems: 'center' }}>
                <Button
                    variant="outlined"
                    onClick={() => onOptionSelect(optionKey)}
                    sx={{
                        justifyContent: 'flex-start',
                        textTransform: 'none',
                        borderRadius: 3,
                        width: '100%',
                        minHeight: '7vh', // 버튼의 최소 높이 지정
                        height: 'auto', // 버튼 높이를 내용에 맞게 자동 조절
                        bgcolor: isSelected ? (answer ? theme.palette.custom.default : 'red') : 'white',
                        color: isSelected ? 'white' : theme.palette.custom.dark,
                        fontSize: '1.0rem',
                        testAlign: 'left', // 텍스트 왼쪽 정렬
                        '&:hover': {
                            bgcolor: '#0f3e8e',
                            borderWidth: 2,
                            color: 'white',
                        },
                        borderColor: isSelected ? (answer ? theme.palette.custom.dark : 'red') : theme.palette.custom.dark,
                        borderWidth: 2,
                        mt: '1.5vh'
                    }}
                >
                    <div style={{ textAlign: 'left' }}>{optionKey}</div>
                    <div style={{ marginLeft: '1rem' }}>
                        {optionType === 'image' ? (
                            <img src={optionValue} alt={`Option ${optionKey}`} style={{ maxWidth: '100%' }} />
                        ) : (
                            <div dangerouslySetInnerHTML={{ __html: optionValue }} />
                        )}
                    </div>
                </Button>
            </ListItem>
        </ThemeProvider>
    );
}

export default QuizOption;
