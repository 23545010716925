import React, { useState, useEffect } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, IconButton, Dialog, DialogActions, DialogContent, DialogContentText,
    DialogTitle, Button, TextField, TablePagination,
    Box
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { useApi } from '../../contexts/Apicontext';

const LecturesTable = ({ onPageChange }) => {
    const [lectures, setLectures] = useState([]);
    const [editLectureId, setEditLectureId] = useState(null);
    const [editLecture, setEditLecture] = useState({ title: '', videoURL: '', resources: '' });
    const [newLecture, setNewLecture] = useState({ title: '', videoURL: '', resources: '' });
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const apiBaseUrl = useApi();

    useEffect(() => {
        fetchLectures();
    }, []);

    const fetchLectures = async () => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/lectures`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        setLectures(data);
    };

    const handleEditClick = (lecture) => {
        setEditLectureId(lecture.lectureID);
        setEditLecture(lecture);
        setOpenEditDialog(true);
    };

    const handleSaveClick = async () => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/lectures/${editLectureId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(editLecture)
        });

        if (response.ok) {
            setOpenEditDialog(false);
            fetchLectures();
        }
    };

    const handleDeleteClick = async (lectureID) => {
        const firstConfirmation = window.confirm('Are you sure you want to delete this lecture? This action cannot be undone.');

        if (firstConfirmation) {
            const secondConfirmation = window.confirm('This is a permanent action. Are you absolutely sure you want to delete this lecture?');

            if (secondConfirmation) {
                const token = localStorage.getItem('token');
                const response = await fetch(`${apiBaseUrl}/admin/lectures/${lectureID}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (response.ok) {
                    fetchLectures();
                }
            }
        }
    };

    const handleAddClick = () => {
        setOpenAddDialog(true);
    };

    const handleAddLecture = async () => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/lectures`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(newLecture)
        });

        if (response.ok) {
            setOpenAddDialog(false);
            fetchLectures();
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setEditLecture({ ...editLecture, [name]: value });
    };

    const handleNewLectureChange = (event) => {
        const { name, value } = event.target;
        setNewLecture({ ...newLecture, [name]: value });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Box>
            <Typography variant="h4" gutterBottom>
                Lectures Management
            </Typography>
            <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleAddClick} sx={{ mb: 2 }}>
                Add New Lecture
            </Button>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Title</TableCell>
                            <TableCell>Video URL</TableCell>
                            <TableCell>Resources</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {lectures.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((lecture) => (
                            <TableRow key={lecture.lectureID}>
                                <TableCell>{lecture.lectureID}</TableCell>
                                <TableCell>{lecture.title}</TableCell>
                                <TableCell>{lecture.videoURL}</TableCell>
                                <TableCell>{lecture.resources}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => handleEditClick(lecture)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton onClick={() => handleDeleteClick(lecture.lectureID)}>
                                        <DeleteIcon />
                                    </IconButton>
                                    <Button variant="outlined" onClick={() => onPageChange(`lectures/${lecture.lectureID}/classes`, { lectureID: lecture.lectureID })}>
                                        View Classes
                                    </Button>
                                    <Button variant="outlined" onClick={() => onPageChange(`lectures/${lecture.lectureID}/quizzes`, { lectureID: lecture.lectureID })}>
                                        View Quizzes
                                    </Button>
                                    <Button variant="outlined" onClick={() => onPageChange(`lectures/${lecture.lectureID}/quizbundles`, { lectureID: lecture.lectureID })}>
                                        View Quizbundles
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                count={lectures.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            {/* Edit Dialog */}
            <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)} fullWidth maxWidth="sm">
                <DialogTitle>Edit Lecture</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        name="title"
                        label="Title"
                        value={editLecture.title}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <TextField
                        margin="dense"
                        name="videoURL"
                        label="Video URL"
                        value={editLecture.videoURL}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <TextField
                        margin="dense"
                        name="resources"
                        label="Resources"
                        value={editLecture.resources}
                        onChange={handleInputChange}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenEditDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveClick} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Add Dialog */}
            <Dialog open={openAddDialog} onClose={() => setOpenAddDialog(false)} fullWidth maxWidth="sm">
                <DialogTitle>Add New Lecture</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter the details of the new lecture.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="title"
                        label="Title"
                        value={newLecture.title}
                        onChange={handleNewLectureChange}
                        fullWidth
                    />
                    <TextField
                        margin="dense"
                        name="videoURL"
                        label="Video URL"
                        value={newLecture.videoURL}
                        onChange={handleNewLectureChange}
                        fullWidth
                    />
                    <TextField
                        margin="dense"
                        name="resources"
                        label="Resources"
                        value={newLecture.resources}
                        onChange={handleNewLectureChange}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenAddDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleAddLecture} color="primary">
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default LecturesTable;
