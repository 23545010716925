import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../actions/authActions';
import Drawer from '@mui/material/Drawer';
import LoginPage from './LoginPage';
import { useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import { Box } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#0f3e8e',
        },
        custom: {
            light: '#4f7aa2',
            dark: '#0a2b4d',
            default: '#0f3e8e',
            darker: '#0a1e3c',
            lightest: '#f0f0f0',
            bookmark: '#d32f2f',
            black: '#000000'
        }
    },
});

const Header = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    let isAdmin = false;
    const token = localStorage.getItem('token');
    if (token) {
        const decodedToken = jwtDecode(token);
        isAdmin = decodedToken.isAdmin === 1;
    }

    const handleLogout = () => {
        const checklogout = window.confirm('Are you sure you want to logout?');
        if (!checklogout) {
            return;
        }
        dispatch(logoutUser());
        navigate('/');
        localStorage.removeItem('token');
    };

    const closeDrawer = () => {
        setIsDrawerOpen(false);
    };

    const user = useSelector(state => state.auth.user);

    const goHOME = () => {
        navigate('/');
    }

    const buttonStyle = {
        bgcolor: 'inherit',
        color: 'white',
        fontWeight: 'bold',
        fontSize: 18,
        borderRadius: '8px',
        border: '2px solid #0f3e8e',
        mx: 0.5,
        py: 0.5,
        px: 1,
        height: '100%',
        '&:hover': {
            bgcolor: 'grey.900',
            border: '2px solid white',
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <AppBar position="fixed" color="default" elevation={0} sx={{ borderBottom: '1px solid #0f3e8e', width: '100%', zIndex: 1201 }}>
                <Toolbar sx={{ flexWrap: 'wrap', bgcolor: '#0f3e8e' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '70%' }}>
                            <AccountBalanceIcon sx={{ mr: 1, color: 'white' }} onClick={goHOME} style={{ cursor: 'pointer' }} />
                            <Typography variant="h5" sx={{ color: 'white', fontWeight: 'bold', mr: 5, py: 0.5 }} onClick={goHOME} style={{ cursor: 'pointer' }}>
                                EDU AGORA
                            </Typography>
                            <Button component={Link} to="/courses" sx={buttonStyle}>
                                Courses
                            </Button>
                            <Button component={Link} to="/board" sx={buttonStyle}>
                                Board
                            </Button>
                            {!isAuthenticated ? (
                                <></>
                            ) : (
                                <>
                                    {user.role === 'student' && (
                                        <>
                                            <Button color="inherit" onClick={() => navigate('/student')} sx={buttonStyle}>
                                                My Lectures
                                            </Button>
                                            <Button color="inherit" onClick={() => navigate('/myprogress')} sx={buttonStyle}>
                                                My Progress
                                            </Button>
                                        </>
                                    )}
                                    {user.role === 'teacher' && (
                                        <Button color="inherit" onClick={() => navigate('/teacher')} sx={buttonStyle}>
                                            My Courses
                                        </Button>
                                    )}
                                    {isAdmin && (
                                        <Button color="inherit" onClick={() => navigate('/admin')} sx={buttonStyle}>
                                            Admin Page
                                        </Button>
                                    )}
                                </>
                            )}
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '30%' }}>
                            {isAuthenticated ? (
                                <>
                                    <Button onClick={handleLogout} sx={buttonStyle}>
                                        Logout
                                    </Button>
                                    <Button component={Link} to="/myprofile"
                                        sx={{
                                            bgcolor: 'inherit',
                                            color: 'white',
                                            fontWeight: 'bold',
                                            fontSize: 14,
                                            borderRadius: '8px',
                                            border: '2px solid #0f3e8e',
                                            mx: 0.5,
                                            py: 0.5,
                                            px: 1,
                                            height: '100%',
                                            '&:hover': {
                                                bgcolor: 'grey.600',
                                                border: '2px solid white',
                                            }
                                        }}>
                                        <PersonIcon sx={{ mr: 1 }} />
                                        My Profile
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Button onClick={() => setIsDrawerOpen(true)} sx={{
                                        color: 'white',
                                        fontWeight: 'bold',
                                        borderRadius: '8px',
                                        border: '2px solid white',
                                        mx: 0.5,
                                        py: 0.5,
                                        px: 1,
                                        height: '75%',
                                        '&:hover': {
                                            fontSize: 16,
                                            bgcolor: '#7c7c7c',
                                            fontWeight: 'bold',
                                            border: '2px solid white',
                                        },
                                        bgcolor: '#7c7c7c',
                                    }}>
                                        Login
                                    </Button>
                                    <Button component={Link} to="/signup" sx={{
                                        color: 'white',
                                        fontWeight: 'bold',
                                        borderRadius: '8px',
                                        border: '2px solid white',
                                        mx: 0.5,
                                        py: 0.5,
                                        px: 1,
                                        height: '75%',
                                        '&:hover': {
                                            bgcolor: '#7c7c7c',
                                            fontSize: 16,
                                            fontWeight: 'bold',
                                            border: '2px solid white',
                                        },
                                        bgcolor: '#7c7c7c',
                                    }}>
                                        Sign Up
                                    </Button>
                                </>
                            )}

                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer
                anchor='right'
                open={isDrawerOpen}
                onClose={closeDrawer}
                sx={{
                    '& .MuiDrawer-paper': {
                        bgcolor: 'transparent',
                        boxShadow: 'none'
                    }
                }}
                BackdropProps={{ invisible: true }}
            >
                <LoginPage closeDrawer={closeDrawer} />
            </Drawer>
            {/* Add this to create space for the fixed header */}
            <Toolbar />
        </ThemeProvider >
    );
};

export default Header;
