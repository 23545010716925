import React, { useState } from "react";
import { Box, Typography, TextField, Button, Divider, Grid, Tabs, Tab } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";

const theme = createTheme({
  palette: {
    primary: {
      main: '#0f3e8e',
    },
    custom: {
      light: '#1434c7',
      dark: '#0a2b4d',
      default: '#0f3e8e',
      darker: '#0a1e3c',
      lightest: '#e1e8f7',
      correct: '#4caf50',
      incorrect: '#f44336',
    }
  },
});

const BoardCommonHead = () => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const [tabValue, setTabValue] = useState(() => {
    if (location.pathname.includes('/announcement')) {
      return 1;
    }
    return 0;
  });

  const onChangeSearch = (e) => {
    setSearchKeyword(e.target.value);
  };

  const onClickSearchButton = () => {
    if (searchKeyword.trim() !== "") {
      performSearch(searchKeyword);
    }
  };

  const performSearch = (query) => {
    console.log(`Searching for: ${query}`);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);

    const basePath = location.pathname.split('/')[1];

    if (newValue === 0) {
      navigate(`/${basePath}`);
    } else if (newValue === 1) {
      navigate(`/${basePath}/announcement`);
    }
  };

  return (
    <Grid sx={{ display: 'flex', width: '100%', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 10,
          width: '70%'
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 5 }}>
          <Typography variant="subtitle1" sx={{ color: 'gray' }}>Library</Typography>
          <Typography variant="h4" sx={{ fontWeight: 'bold' }}>자료실</Typography>
        </Box>

        {/* Search Section */}
        <Box sx={{ display: 'flex', alignItems: 'center', borderRadius: '10px', boxShadow: 2, overflow: 'hidden', height: '60px' }}>
          <TextField
            placeholder="검색어를 입력하세요"
            value={searchKeyword}
            onChange={onChangeSearch}
            onKeyDown={(e) => { if (e.key === "Enter") { onClickSearchButton(); } }}
            variant="standard"
            InputProps={{
              disableUnderline: true,
              sx: { border: 'none', paddingRight: '0', paddingLeft: '10px', height: '100%' }
            }}
            sx={{ width: { xs: '15rem', sm: '20rem', md: '25rem' }, height: '100%', border: 'none', backgroundColor: "white" }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={onClickSearchButton}
            sx={{
              borderRadius: '10px', padding: '10px 5px', boxShadow: 'none', my: 1, mr: 1,
              backgroundColor: theme.palette.custom.default,
              '&:hover': { backgroundColor: theme.palette.custom.default }
            }}
          >
            검색
          </Button>
        </Box>
      </Box>

      {/* Tab Section */}
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        centered={false}
        sx={{
          mt: 3,
          width: '70%',
          justifyContent: 'flex-start',
          '& .MuiTab-root': {
            minWidth: '100px',
          },
          '& .Mui-selected': {
            color: theme.palette.custom.default,
            fontWeight: 'bold',
          },
          '& .MuiTabs-indicator': {
            backgroundColor: theme.palette.custom.default, // Change indicator color
          }
        }}
      >
        <Tab label="자료실" />
        <Tab label="공지사항" />
      </Tabs>

      <Divider sx={{ my: 0 }} />
    </Grid>
  );
};

export default BoardCommonHead;
