import React, { useState, useEffect } from "react";
import { Box, Typography, Button, Grid, Paper, CircularProgress } from "@mui/material";
import BoardItem from "./BoardItem";
import BoardForm from "./BoardForm";
import Pagination from "./Pagination";
import { useApi } from "../../../contexts/Apicontext";
import { useSelector } from 'react-redux';
import Loading from "../../Loading/Loading";
import { jwtDecode } from 'jwt-decode';

const BoardList = () => {
  const [boardItems, setBoardItems] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [selectedItem, setSelectedItem] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isAdmin, setIsAdmin] = useState(false);
  const apiBaseUrl = useApi();

  useEffect(() => {
    fetchBoardItems();
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      setIsAdmin(decodedToken.isAdmin === 1);
    }
  }, [currentPage]);

  const fetchBoardItems = async () => {
    const boardID = 1; // Setting boardID to 1 for initial fetch
    setIsLoading(true); // Start loading
    try {
      const response = await fetch(`${apiBaseUrl}/boards/${boardID}/posts?page=${currentPage}&limit=10`); // Fetch posts for boardID 1
      const data = await response.json();
      console.log(data);
      setBoardItems(data.items);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error("Failed to fetch board items:", error);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const handleEdit = (item) => {
    setIsEditing(true);
    setSelectedItem(item);
  };

  const handleDelete = async (id) => {
    if (window.confirm("정말로 삭제하시겠습니까?")) {
      try {
        await fetch(`${apiBaseUrl}/boards/1/posts/${id}`, { method: "DELETE" });
        fetchBoardItems();
      } catch (error) {
        console.error("Failed to delete board item:", error);
      }
    }
  };

  const handleCreate = () => {
    setIsEditing(true);
    setSelectedItem(null);
  };

  const handleFormClose = () => {
    setIsEditing(false);
    fetchBoardItems();
  };

  return (
    <Box sx={{ p: 3 }}>
      {isAdmin && (
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end", width: '70%',mb:2 }}> {/* Align button to the right */}
            <Button variant="contained" color="primary" onClick={handleCreate}>
              게시물 생성
            </Button>
          </Grid>
        </Box>
      )}
      <Grid container spacing={2}>

        {isLoading ? (
          // Show loading indicator while fetching
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress />
          </Grid>
        ) : (
          // Render board items when not loading
          boardItems.map((item) => (
            <Grid item xs={12} key={item.id} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <BoardItem item={item} onEdit={handleEdit} onDelete={handleDelete} isAdmin={isAdmin}/>
            </Grid>
          ))
        )}
        <Grid item xs={12}>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
          />
        </Grid>
      </Grid>

      {isEditing && (
        <BoardForm
          onClose={handleFormClose}
          item={selectedItem}
          isAdmin={isAdmin}
        />
      )}
    </Box>
  );
};

export default BoardList;
