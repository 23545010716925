import React from 'react';
import { Typography, Box, List, ListItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import 'katex/dist/katex.min.css';
import { InlineMath } from 'react-katex';

const MathDirection = () => {
    return (
        <Box sx={{ maxHeight: '50vh' }}>
            <img src="https://agoran-bucket.s3.ap-northeast-2.amazonaws.com/mathref.png" alt="math directions" style={{ width: '100%', height: 'auto' }} />
            <Typography variant="body2" color="black" paragraph>
                The number of degrees of arc in a circle is 360.
            </Typography>
            <Typography variant="body2" color="black" paragraph>
                The number of radians of arc in a circle is 2π.
            </Typography>
            <Typography variant="body2" color="black" paragraph>
                The sum of the measures in degrees of the angles of a triangle is 180.
            </Typography>
        </Box>
    );
};

export default MathDirection;
