import React from "react";
import StudentMainPageBody from "./StudentMainPageBody";
import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode"; // jwtDecode import 수정
import MainpageHeader from "../MainPage_components/MainPageHeader";
import MainPageFooter from "../MainPage_components/MainPageFooter";
import { useApi } from "../../contexts/Apicontext";
import { Box, CircularProgress } from "@mui/material";

export default function StudentMainPage() {
    const { userID, email, name } = jwtDecode(localStorage.getItem('token')); // 로컬 스토리지에서 JWT 토큰을 가져와서 디코딩합니다.
    const [classes, setClasses] = useState([]); // 사용자 강의 정보를 저장할 상태
    const [loading, setLoading] = useState(true); // 로딩 상태 추가
    const apiBaseUrl = useApi(); // API URL을 가져옵니다.

    useEffect(() => {
        // 사용자의 강의 정보를 불러오는 함수
        const fetchClasses = async () => {
            const token = localStorage.getItem('token'); // 로컬 스토리지에서 JWT 토큰을 가져옵니다.
            if (!token) return; // 토큰이 없으면 함수를 종료합니다.
            try {
                const response = await fetch(`${apiBaseUrl}/classes`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`, // 요청 헤더에 JWT 토큰을 포함시킵니다.
                        'Content-Type': 'application/json',
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setClasses(data); // 상태를 업데이트하여 화면에 강의 정보를 표시합니다.
                } else {
                    console.error('Failed to fetch classes');
                }
            } catch (error) {
                console.error('Error fetching classes:', error);
            } finally {
                setLoading(false); // 데이터 로드 완료 시 로딩 상태를 false로 설정
            }
        };
        fetchClasses(); // 함수 호출
    }, [userID]); // userID가 변경될 때마다 함수를 다시 호출합니다.

    return (
        <div>
            <MainpageHeader />
            <Box sx={{ flexGrow: 1 }}>
                <StudentMainPageBody
                    userID={userID}
                    email={email}
                    name={name}
                    classes={classes}
                    loading={loading} // 로딩 상태를 body에 전달
                />

            </Box>

            <MainPageFooter />
        </div>
    );
}
