import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress'; // CircularProgress 컴포넌트 추가
import ClassList from './BodyComponents/ClassList';
import Loading from '../Loading/Loading';

export default function StudentMainPageBody({ userID, name, email, classes, loading }) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'start',
                paddingLeft: '20%',
                paddingRight: '20%',
                flexGrow: 1,
                mb: 10
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: 'full',
                    mt: 10
                }}
            >
                <Box sx={{ textAlign: 'center', flexGrow: 1 }}>
                    <Typography variant="h4" component="h4" sx={{ fontFamily: 'monospace', wordBreak: 'keep-all', fontWeight: 'bold' }}>
                        환영합니다! {name}님
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ mt: 6 }}>
                <Box sx={{ display: 'flex', mb: 4 }}>
                    <Box sx={{ textAlign: 'center', display: 'flex', width: '20%', alignItems: 'flex-start', justifyContent: 'center' }}>
                        <Typography variant="h5" component="h5" sx={{ fontWeight: 'bold', mt: 2 }}>
                            수강 중인 강의:
                        </Typography>
                    </Box>
                    <Box sx={{ mt: 0, display: 'flex', width: '80%', flexDirection: 'column' }}>
                        {loading ? ( // 로딩 중일 때 로딩 컴포넌트 표시
                            <Loading />
                        ) : classes.length > 0 ? (
                            <ClassList classes={classes} />
                        ) : (
                            <Typography variant="body1">듣고 있는 강의가 없습니다.</Typography>
                        )}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
