import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import EducationalContent from './MainPageBody_components/EducationalContent';
import { useEffect } from 'react';

const videoTitle = "Princeton University Introduction";
const videoUrl = "https://www.youtube.com/embed/7y2KsU_dhwI";

const Body = () => {

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Container maxWidth="md" component="main" sx={{ pt: 8, pb: 6, minHeight:'100vh' }}>
                <Typography variant="h2" align="center" color="textPrimary" gutterBottom>
                    Welcome to EDUAgora
                </Typography>
                <Typography variant="h5" align="center" color="textSecondary" component="div" mb={4}>
                    <div>Discover a range of courses and learn at your own pace. </div>
                    <div>Join EduAgora today and start your journey to knowledge.</div>
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 4 }}>
                    <iframe
                        width="560"
                        height="315"
                        src={videoUrl}
                        title={videoTitle}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </Box>

                {/* 회색 가로선 */}
                <Box sx={{ my: 2, height: '2px', backgroundColor: 'grey.300' }}></Box>

                <EducationalContent
                    summary={<><span style={{ fontWeight: 'bold' }}>*DIGITAL 학습으로의 전환*</span></>}
                    detail={<><span style={{ fontWeight: 'bold' }}>학업 시험의 </span>환경이 급격하게 변화하고 있습니다. ACT, AP, TOEFL, Duolingo를 비롯하여, 2024년을 기준으로 모든 국가에서 SAT가 디지털 환경에서 실시될 예정입니다. 이에 따라, 우리는 전통적인 책을 사용한 훈련이 아닌 컴퓨터 기반의 방식으로 근본적인 변화가 필요하다고 믿습니다.</>}
                    number={1}
                />

                <Box sx={{ my: 2, height: '2px', backgroundColor: 'grey.300' }}></Box> {/* 회색 가로선 */}

                <EducationalContent
                    summary={<><span style={{ fontWeight: 'bold' }}>무분별하게  AI로 찍어내는 ‘문제공장 학원’과는 다릅니다.</span></>}
                    detail={<><span style={{ fontWeight: 'bold' }}>디지털화의 </span>근본적인 측면은 여전히 전통적인 독해, 출제 의도 이해, 그리고 높은 학습량에서 출발합니다. 저희는 컴퓨터 기반 훈련을 통해 시험 환경과 상호 작용하는 학습 방식과 전통적인 학습 방법의 장점을 최대한 연결하고자 합니다. AI의 정밀한 기출 문제 분석 능력에, 최고의 명문대 강사진들의 노하우가 결합되어, 오차를 최소화하고 문제 퀄리티를 극대화했습니다.</>}
                    number={2}
                />

                <Box sx={{ my: 2, height: '2px', backgroundColor: 'grey.300' }}></Box> {/* 회색 가로선 */}

                <EducationalContent
                    summary={<><span style={{ fontWeight: 'bold' }}>AI훈련은 여러분의 약점과 강점을 여러분보다 더 정확하게 분석합니다.</span></>}
                    detail={<><span style={{ fontWeight: 'bold' }}>AIO-learning은 </span>여러분이 어떤 유형에서, 얼마나 많이, 어떻게 실수를 하는지 알고 있습니다. 우리의 선제적 학습 모델을 통해 여러분의 단점을 보완하세요. 분석 결과에 맞춰 AIO-learning은 사용자에게 알맞는 문제를 끊임 없이 출제하여, 무한한 학습을 제공합니다. 한 유형에 대한 지속적인 학습을 통해, 해당 유형에 숨어 있는 패턴을 인지하고, 이해하고, 체화하는 경험을 할 수 있습니다. 문장 구조를 이해하고, 출제 의도를 파악하는 것이 고득점의 핵심입니다.</>}
                    number={3}
                />

                <Box sx={{ my: 2, height: '2px', backgroundColor: 'grey.300' }}></Box> {/* 회색 가로선 */}
                
                <EducationalContent
                    summary={<><span style={{fontWeight: 'bold'}}>다년간의 강의 노하우를 가진 최고의 강사진을 만나보세요.  </span></>}
                    detail={<><span style={{fontWeight: 'bold'}}>프로그램에</span> 참여한 최고의 명문대 출신 선생님들은 오랜 기간 강의를 진행해왔습니다. 그들의 해설 녹화 강의에는 각 유형의 출제 포인트가 반영되었습니다. 정밀한 기출 문제 분석 능력에, 최고의 명문대 강사진들의 노하우가 결합되어, 오차를 최소화하고 문제 퀄리티를 극대화했습니다. </>}
                    number={4}
                />

                <Box sx={{ my: 2, height: '2px', backgroundColor: 'grey.300' }}></Box> {/* 회색 가로선 */}
                <EducationalContent
                    summary={<><span style={{fontWeight: 'bold'}}>'전략적으로 푸는 요령'을 가르칩니다. </span></>}
                    detail={<><span style={{fontWeight: 'bold'}}>수험생에게</span> 가장 중요한 것은 시간을 어떻게 분배하느냐입니다. AIO-learning을 통해 여러분의 강점과 약점을 정확하게 분석함으로써 시간을 효율적으로 투자할 수 있습니다. 우리는 학생들이 이제껏 경험하지 못한 새로운 학습 방식을 경험하며, 미래의 성공을 위한 기반을 마련할 수 있도록 노력하고 있습니다.</>}
                    number={5}
                />
            </Container>

        </Box>
    );
};

export default Body;
