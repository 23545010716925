import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { IconButton, Box } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import QuizSidebarDrawer from './QuizSidebarDrawer';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { jwtDecode } from 'jwt-decode';
import { useApi } from '../../contexts/Apicontext';

export const sidebarWidth = 60; // 세로 띠 너비
export const drawerWidth = 240; // Drawer 너비

function QuizSidebar({ isDrawerOpen, setIsDrawerOpen, responseCount , classID}) {
  const { lectureID, quizID, order } = useParams();
  const { userID } = jwtDecode(localStorage.getItem('token'));

  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const apiBaseUrl = useApi();

  useEffect(() => {
    const fetchQuestions = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch(
          `${apiBaseUrl}/quizzes/${quizID}/questions/detail?lectureID=${lectureID}&userID=${userID}&classID=${classID}`,
          {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        );
        if (!response.ok) {
          throw new Error('Failed to fetch questions');
        }
        const data = await response.json();
        
        setQuestions(data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchQuestions();
  }, [lectureID, quizID, userID,questions]);

  const findQuestionIndex = () => questions?.findIndex(question => question.order === parseInt(order));
  const currentQuestionIndex = findQuestionIndex();

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <Box sx={{ display: 'flex', height: '100vh', position: 'fixed', right: 0, }}>
      {/* 세로 띠 */}
      <Box
        sx={{
          width: `${sidebarWidth}px`,
          bgcolor: '#0f3e8e',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1200,
        }}
      >
        <IconButton component={Link} to={`/student`} sx={{ color: 'white' }}>
          <HomeIcon />
        </IconButton>
        <IconButton onClick={() => setIsDrawerOpen(!isDrawerOpen)} sx={{ color: 'white' }}>
          <MenuIcon />
        </IconButton>

        {/* 다음 문제로 이동 버튼 */}
        {currentQuestionIndex < questions.length - 1 && (
          <IconButton
            component={Link}
            to={`/${classID}/lectures/${lectureID}/quizzes/${quizID}/questions/${questions[currentQuestionIndex + 1].order}`}
            sx={{ color: 'white', position: 'absolute', bottom: '15%', left: '50%', transform: 'translateX(-50%)' }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        )}
        {/* 이전 문제로 이동 버튼 */}
        {currentQuestionIndex > 0 && (
          <IconButton
            component={Link}
            to={`/${classID}/lectures/${lectureID}/quizzes/${quizID}/questions/${questions[currentQuestionIndex - 1].order}`}
            sx={{ color: 'white', position: 'absolute', bottom: '5%', left: '50%', transform: 'translateX(-50%)' }}
          >
            <ArrowBackIosNewIcon />
          </IconButton>
        )}
      </Box>

      {/* Drawer */}
      <QuizSidebarDrawer
        isOpen={isDrawerOpen}
        toggleDrawer={() => setIsDrawerOpen(!isDrawerOpen)}
        questions={questions}
        lectureID={lectureID}
        quizID={quizID}
        classID={classID}
      />
    </Box>
  );
}

export default QuizSidebar;
