import React from 'react';
import { Box, Toolbar, Container, Typography } from '@mui/material';
import AdminHeader from './AdminHeader';
import AdminMenuDrawer from './AdminMenuDrawer';

const drawerWidth = 240;

const AdminMainPage = () => {
    const handleDrawerToggle = () => {};

    return (
        <Box sx={{ display: 'flex' }}>
            <AdminHeader handleDrawerToggle={handleDrawerToggle} />
            <AdminMenuDrawer />
            <Box
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar />
                <Container>
                    <Typography variant="h4" component="div">
                        Admin Main Page
                    </Typography>
                </Container>
            </Box>
        </Box>
    );
};

export default AdminMainPage;
