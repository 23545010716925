import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ children }) => {
  // 리덕스에서 isAuthenticated 상태를 가져옴
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const location = useLocation();

  // isAuthenticated가 null인 경우 로딩 중으로 처리하여 아무것도 렌더링하지 않음
  if (isAuthenticated === null) {
    return null; // 로딩 중이거나 상태가 확인되지 않았을 때 아무것도 렌더링하지 않음
  }

  // 사용자가 인증되었는지 확인하고, 인증되지 않았으면 로그인 페이지로 리다이렉트
  return isAuthenticated ? (
    children
  ) : (
    <Navigate to="/" state={{ from: location }} />
  );
};

export default PrivateRoute;
