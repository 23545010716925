import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useApi } from '../../contexts/Apicontext';
import {
    Box, Typography, Divider, IconButton, Button
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import MainPageHeader from '../MainPage_components/MainPageHeader';
import ScoreDisplay from './ScoreDisplay';
import styled from '@emotion/styled';
import QuizAttemptAccordion from './QuizAttemptAccordion';

const CardContainer = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 330px;
    perspective: 1000px;
    margin-bottom: 2rem;
    overflow: hidden;
`;

const Card = styled(Box)`
    width: 250px;
    height: 90%;
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    transform-style: preserve-3d;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    position: absolute;
    top: 0;
    left: ${({ position }) => position.left};
    transform: ${({ position }) => position.transform};
    opacity: ${({ position }) => position.opacity};
    z-index: ${({ position }) => position.zIndex};
    overflow: hidden;
`;

const calculatePosition = (index, currentCardIndex, totalCards) => {
    let left, transform, opacity, zIndex;
    const angle = 35;
    const distance = 300;

    if (index === currentCardIndex) {
        left = '50%';
        transform = 'translateX(-50%) rotateY(0deg)';
        opacity = 1;
        zIndex = 3;
    } else if (index === currentCardIndex - 1) {
        left = `calc(50% - ${distance}px)`;
        transform = `translateX(-50%) rotateY(${angle}deg)`;
        opacity = 0.4;
        zIndex = 2;
    } else if (index === currentCardIndex - 2) {
        left = `calc(50% - ${2 * distance}px)`;
        transform = `translateX(-50%) rotateY(${2 * angle}deg)`;
        opacity = 0.2;
        zIndex = 1;
    } else if (index === currentCardIndex + 1) {
        left = `calc(50% + ${distance}px)`;
        transform = `translateX(-50%) rotateY(-${angle}deg)`;
        opacity = 0.4;
        zIndex = 2;
    } else if (index === currentCardIndex + 2) {
        left = `calc(50% + ${2 * distance}px)`;
        transform = `translateX(-50%) rotateY(-${2 * angle}deg)`;
        opacity = 0.2;
        zIndex = 1;
    } else {
        left = '70%';
        transform = 'translateX(-50%) rotateY(-40deg)';
        opacity = 0;
        zIndex = 0;
    }

    return { left, transform, opacity, zIndex };
};

const QuizResults = () => {
    const navigate = useNavigate();
    const { quizID, classID } = useParams();
    const location = useLocation();
    const { className, mockTestProgress, category } = location.state || {};
    const [results, setResults] = useState(mockTestProgress || []);
    const [currentCardIndex, setCurrentCardIndex] = useState(0);
    const apiBaseUrl = useApi();

    useEffect(() => {
        if (!results.length) {
            async function fetchQuizResults() {
                const token = localStorage.getItem('token');
                try {
                    const response = await fetch(`${apiBaseUrl}/lectures/quizzes/${quizID}/results?classID=${classID}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });

                    if (response.ok) {
                        const data = await response.json();
                        setResults(data);
                    } else {
                        console.error('Failed to fetch quiz results');
                    }
                } catch (error) {
                    console.error('Error fetching quiz results:', error);
                }
            }

            fetchQuizResults();
        }
    }, [quizID, classID, apiBaseUrl, results.length]);

    const handleNext = () => {
        if (currentCardIndex < results.length - 1) {
            setCurrentCardIndex(currentCardIndex + 1);
        }
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}m : ${remainingSeconds}s`;
    };

    const handlePrev = () => {
        if (currentCardIndex > 0) {
            setCurrentCardIndex(currentCardIndex - 1);
        }
    };

    return (
        <div>
            <MainPageHeader />
            <div className="bg-gray-800 w-full h-24 flex items-center justify-start pl-4">
                <h4 className="text-2xl text-white font-bold">{className || 'Quiz Results'}</h4>
            </div>
            <Box sx={{ p: 2 }}>
                <IconButton onClick={() => navigate(-1)} color="primary">
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h4" component="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
                    Quiz Results
                </Typography>
                <Divider sx={{ mb: 5 }} />

                {results.length > 0 && (
                    <>
                        <CardContainer>
                            <Button 
                                onClick={handlePrev} 
                                sx={{ 
                                    position: 'absolute', 
                                    left: 0, 
                                    zIndex: 10,
                                    opacity: currentCardIndex > 0 ? 1 : 0.3
                                }}
                                disabled={currentCardIndex === 0}
                            >
                                <ArrowBackIosNewIcon />
                            </Button>
                            {results.map((result, index) => {
                                const position = calculatePosition(index, currentCardIndex, results.length);
                                return (
                                    <Card key={result.attemptID} position={position} sx={{mt:2}}>
                                        <Typography variant="h6">
                                            Attempt #{index + 1}
                                        </Typography>
                                        <ScoreDisplay score={result.score} />
                                        <Typography variant="body2" sx={{ mt: 3 }}>
                                            {formatTime(result.testTime)}
                                        </Typography>
                                    </Card>
                                );
                            })}
                            <Button 
                                onClick={handleNext} 
                                sx={{ 
                                    position: 'absolute', 
                                    right: 0, 
                                    zIndex: 10,
                                    opacity: currentCardIndex < results.length - 1 ? 1 : 0.3
                                }}
                                disabled={currentCardIndex === results.length - 1}
                            >
                                <ArrowForwardIosIcon />
                            </Button>
                        </CardContainer>
                        {results.map((result, index) => (
                            <QuizAttemptAccordion 
                                key={result.attemptID}
                                attemptID={result.attemptID}
                                score={result.score}
                                testTime={result.testTime}
                                index={index}
                                category={category}
                            />
                        ))}
                    </>
                )}

                {!results.length && (
                    <Typography variant="body1" component="p">
                        No results found for this quiz.
                    </Typography>
                )}
            </Box>
        </div>
    );
};

export default QuizResults;
