import React from "react";
import { Pagination as MuiPagination } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: '#0f3e8e',
    },
    custom: {
      light: '#1434c7',
      dark: '#0a2b4d',
      default: '#0f3e8e',
      darker: '#0a1e3c',
      lightest: '#e1e8f7',
      correct: '#4caf50',
      incorrect: '#f44336',
    }
  },
});

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handleChange = (event, page) => {
    onPageChange(page);
  };

  return (
    <ThemeProvider theme={theme}>
      <MuiPagination
        count={totalPages}
        page={currentPage}
        onChange={handleChange}
        color="primary"
        shape="rounded" // Add rounded shape
        sx={{
          mt: 3,
          display: "flex",
          justifyContent: "center",
          "& .MuiPaginationItem-root": {
            borderRadius: "20%", // Make all pagination items circular
          },
          "& .Mui-selected": {
            backgroundColor: theme.palette.custom.default, // Customize selected item color
            color: "white", // Customize selected text color
          },
        }}
      />
    </ThemeProvider>
  );
};

export default Pagination;
