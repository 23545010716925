import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { jwtDecode } from 'jwt-decode';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box } from '@mui/material';
import { useApi } from '../../contexts/Apicontext';

function LectureQuizzes({ lecture, index, total, classID, className }) {
    const [expandedPractice, setExpandedPractice] = useState(false);
    const [expandedReal, setExpandedReal] = useState(false);
    const [progressData, setProgressData] = useState([]);
    const [mockTestProgress, setMockTestProgress] = useState([]);
    const [bundleTestProgress, setBundleTestProgress] = useState([]);
    const [quizBundles, setQuizBundles] = useState([]);
    const [isLoadingPractice, setIsLoadingPractice] = useState(false);
    const [isLoadingReal, setIsLoadingReal] = useState(false);
    const [loadingBundle, setLoadingBundle] = useState(null);
    const navigate = useNavigate();
    const apiBaseUrl = useApi();

    const theme = createTheme({
        palette: {
            primary: {
                main: '#0f3e8e',
            },
            custom: {
                light: '#4f7aa2',
                dark: '#0a2b4d',
                default: '#0f3e8e',
                darker: '#0a1e3c',
                lightest: '#f0f0f0'
            }
        },
    });

    useEffect(() => {
        if (expandedPractice) {
            setIsLoadingPractice(true);
            fetchQuizProgress();
            fetchMockTestProgress();
        }
    }, [expandedPractice]);

    useEffect(() => {
        if (expandedReal) {
            setIsLoadingReal(true);
            fetchQuizBundles();
            fetchBundleTestProgress();
        }
    }, [expandedReal]);

    const fetchQuizProgress = async () => {
        const token = localStorage.getItem('token');
        const userID = jwtDecode(token).userID;
        const response = await fetch(`${apiBaseUrl}/lectures/${lecture.lectureID}/quizzes-progress?userID=${userID}&classID=${classID}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        setProgressData(data);
        setIsLoadingPractice(false);
    };

    const fetchMockTestProgress = async () => {
        const token = localStorage.getItem('token');
        const userID = jwtDecode(token).userID;
        const response = await fetch(`${apiBaseUrl}/lectures/${lecture.lectureID}/mock-tests-progress?userID=${userID}&classID=${classID}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        setMockTestProgress(data);
        setIsLoadingPractice(false);
    };

    const fetchBundleTestProgress = async () => {
        const token = localStorage.getItem('token');
        const userID = jwtDecode(token).userID;
        const response = await fetch(`${apiBaseUrl}/lectures/${lecture.lectureID}/bundle-mock-tests-progress?&classID=${classID}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        console.log(data);
        setBundleTestProgress(data);
    };

    const fetchQuizBundles = async () => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/lectures/${lecture.lectureID}/quizbundles`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        setQuizBundles(data);
        setIsLoadingReal(false);
    };

    const handleTogglePractice = () => {
        setExpandedPractice(!expandedPractice);
    };

    const handleToggleReal = () => {
        setExpandedReal(!expandedReal);
    };

    const getProgressData = (quizID, field) => {
        const progress = progressData.find((item) => item.quizID === quizID);
        return progress ? progress[field] : '기록이 없습니다.';
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    const getMockTestProgressData = (quizID, field) => {
        const progressArray = mockTestProgress.filter((item) => item.quizID === quizID);

        if (progressArray.length > 0) {
            const latestProgress = progressArray.reduce((latest, current) => {
                return new Date(latest.startTime) > new Date(current.startTime) ? latest : current;
            });

            if (field === 'score') {
                return latestProgress[field];
            } else {
                return formatTime(latestProgress[field]);
            }
        } else {
            return 'NO Record';
        }
    };

    const handleStartQuiz = async (quizID) => {
        const token = localStorage.getItem('token');
        const userID = jwtDecode(token).userID;

        const response = await fetch(`${apiBaseUrl}/lectures/start-quiz-attempt`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ lectureID: lecture.lectureID, quizID, userID, classID })
        });

        const data = await response.json();
        if (data.attemptID) {
            navigate(`/${classID}/lectures/${lecture.lectureID}/quizzes/${quizID}/questions/1`, { state: { classID: classID } });
        }
    };

    const handleStartMockTest = async (quizID, category) => {
        const existingTimer = localStorage.getItem(`timer_${classID}_${lecture.lectureID}_${quizID}`);
        const existingQuestions = localStorage.getItem(`questions_${classID}_${lecture.lectureID}_${quizID}`);
        const existingCurrentOrder = localStorage.getItem(`currentOrder_${classID}_${lecture.lectureID}_${quizID}`);
        const existingMockTest = localStorage.getItem(`mockTest_${classID}_${lecture.lectureID}_${quizID}`);

        if (existingTimer && existingQuestions && existingCurrentOrder && existingMockTest) {
            const continueExisting = window.confirm('기존에 풀던 모의고사가 있습니다! 이어서 푸시겠습니까?');

            if (continueExisting) {
                const storedData = JSON.parse(existingMockTest);
                navigate(`/lectures/${lecture.lectureID}/mock-tests/${storedData.attemptID}/start`, { state: { mockTest: storedData, quizID, classID, category } });
                return;
            } else {
                const confirmNewTest = window.confirm('새로 모의고사를 시작하면 기존 기록이 삭제됩니다. 계속 하시겠습니까?');
                if (!confirmNewTest) {
                    return;
                }
                localStorage.removeItem(`timer_${classID}_${lecture.lectureID}_${quizID}`);
                localStorage.removeItem(`questions_${classID}_${lecture.lectureID}_${quizID}`);
                localStorage.removeItem(`currentOrder_${classID}_${lecture.lectureID}_${quizID}`);
                localStorage.removeItem(`mockTest_${classID}_${lecture.lectureID}_${quizID}`);
            }
        }
        else {
            const confirmStartNew = window.confirm('새로운 모의고사를 시작하시겠습니까?');
            if (!confirmStartNew) {
                return;
            }

            const token = localStorage.getItem('token');
            const userID = jwtDecode(token).userID;
            const starttime = new Date().toISOString();

            const response = await fetch(`${apiBaseUrl}/mock-tests/start-mock-test`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ lectureID: lecture.lectureID, userID, quizID, classID, starttime })
            });

            const data = await response.json();

            if (data.attemptID && data.questions.length > 0) {
                data.questions.sort((a, b) => a.order - b.order);
                localStorage.setItem(`mockTest_${classID}_${lecture.lectureID}_${quizID}`, JSON.stringify(data));
                navigate(`/lectures/${lecture.lectureID}/mock-tests/${data.attemptID}/start`, { state: { mockTest: data, quizID, classID, category } });
            } else {
                alert('모의고사를 시작할 수 없습니다. 모의고사 문제가 없습니다.');
            }
        }
    };

    const handleModuleSelection = async (lectureID, classID, bundle) => {
        const bundleID = bundle.bundleID;
        const mainQuizID = bundle.mainQuizID;
        const hardQuizID = bundle.hardQuizID;
        const easyQuizID = bundle.easyQuizID;
        const category = bundle.mainQuizCategory;

        const savedMainQuestions = localStorage.getItem(`questions_${classID}_${lectureID}_${bundleID}_bundle_main`);
        const savedMainMockTest = localStorage.getItem(`mockTest_${classID}_${lectureID}_${bundleID}_bundle_main`);
        const savedMainTimer = localStorage.getItem(`timer_${classID}_${lectureID}_${bundleID}_bundle_main`);
        const savedMainIndex = localStorage.getItem(`currentOrder_${classID}_${lectureID}_${bundleID}_bundle_main`);
        const savedMainHighlights = localStorage.getItem(`highlights_${classID}_${lectureID}_${bundleID}_bundle_main`);

        console.log(`mockTest_${classID}_${lectureID}_${bundleID}_bundle_second`);

        const savedSecondQuestions = localStorage.getItem(`questions_${classID}_${lectureID}_${bundleID}_bundle_second`);
        const savedSecondMockTest = localStorage.getItem(`mockTest_${classID}_${lectureID}_${bundleID}_bundle_second`);
        const savedSecondTimer = localStorage.getItem(`timer_${classID}_${lectureID}_${bundleID}_bundle_second`);
        const savedSecondIndex = localStorage.getItem(`currentOrder_${classID}_${lectureID}_${bundleID}_bundle_second`);
        const savedSecondHighlights = localStorage.getItem(`highlights_${classID}_${lectureID}_${bundleID}_bundle_second`);
        const saveddifficulty = localStorage.getItem(`difficulty_${classID}_${lectureID}_${bundleID}_bundle_second`);
        const savedevaluated = localStorage.getItem(`evaluated_${classID}_${lectureID}_${bundleID}_bundle_second`);

        if (savedSecondMockTest && savedSecondQuestions && savedSecondTimer && savedSecondIndex) {
            // Second mock test exists
            const continueSecondTest = window.confirm('두 번째 모의고사를 이어서 푸시겠습니까?');
            if (continueSecondTest) {
                const storedData = JSON.parse(savedSecondMockTest);
                const pastquestions = JSON.parse(savedevaluated);
                navigate(`/lectures/${lectureID}/bundle-mock-tests/${storedData.attemptID}/second`, {
                    state: { mockTest: storedData, quizID: hardQuizID, classID, category: bundle.hardQuizCategory, hardQuizID, easyQuizID, bundleID, bundle, pastattemptID: pastquestions.attemptID, pastquestion: pastquestions, testdifficulty: saveddifficulty }
                });
                return;
            }
            else {
                const confirmNewSecondTest = window.confirm('새로 모의고사를 시작하면 첫번째 모의고사를 포함한 기존 기록이 삭제됩니다. 계속 하시겠습니까?');
                if (!confirmNewSecondTest) {
                    return;
                }
                // Clear existing data for second test
                localStorage.removeItem(`timer_${classID}_${lectureID}_${bundleID}_bundle_main`);
                localStorage.removeItem(`questions_${classID}_${lectureID}_${bundleID}_bundle_main`);
                localStorage.removeItem(`currentOrder_${classID}_${lectureID}_${bundleID}_bundle_main`);
                localStorage.removeItem(`mockTest_${classID}_${lectureID}_${bundleID}_bundle_main`);

                localStorage.removeItem(`timer_${classID}_${lectureID}_${bundleID}_bundle_second`);
                localStorage.removeItem(`questions_${classID}_${lectureID}_${bundleID}_bundle_second`);
                localStorage.removeItem(`currentOrder_${classID}_${lectureID}_${bundleID}_bundle_second`);
                localStorage.removeItem(`mockTest_${classID}_${lectureID}_${bundleID}_bundle_second`);
                localStorage.removeItem(`highlights_${classID}_${lectureID}_${bundleID}_bundle_second`);
                localStorage.removeItem(`difficulty_${classID}_${lectureID}_${bundleID}_bundle_second`);
                localStorage.removeItem(`evaluated_${classID}_${lectureID}_${bundleID}_bundle_second`);

                const token = localStorage.getItem('token');
                const userID = jwtDecode(token).userID;
                const starttime = new Date().toISOString();

                const response = await fetch(`${apiBaseUrl}/mock-tests/start-bundle-main-mock-test`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({ lectureID, userID, quizID: mainQuizID, classID, starttime, bundleID })
                });

                const data = await response.json();
                console.log(data);

                if (data.attemptID && data.questions.length > 0) {
                    data.questions.sort((a, b) => a.order - b.order);
                    localStorage.setItem(`mockTest_${classID}_${lectureID}_${bundleID}_bundle_main`, JSON.stringify(data));
                    console.log(bundleID);
                    navigate(`/lectures/${lectureID}/bundle-mock-tests/${data.attemptID}/start`, {
                        state: { mockTest: data, quizID: mainQuizID, classID, category, hardQuizID, easyQuizID, bundleID: bundleID, bundle }
                    });
                } else {
                    alert('모의고사를 시작할 수 없습니다. 모의고사 문제가 없습니다.');
                }
            }
        }
        else if (savedMainMockTest && savedMainQuestions && savedMainTimer && savedMainIndex) {
            // Main mock test exists
            const continueMainTest = window.confirm('기존에 풀던 메인 모의고사를 이어서 푸시겠습니까?');
            if (continueMainTest) {
                const storedData = JSON.parse(savedMainMockTest);
                navigate(`/lectures/${lectureID}/bundle-mock-tests/${storedData.attemptID}/start`, {
                    state: { mockTest: storedData, quizID: mainQuizID, classID, category, hardQuizID, easyQuizID, bundleID, bundle }
                });
                return;
            } else {
                const confirmNewMainTest = window.confirm('새로 모의고사를 시작하면 기존 기록이 삭제됩니다. 계속 하시겠습니까?');
                if (!confirmNewMainTest) {
                    return;
                }
                // Clear existing data for main test
                localStorage.removeItem(`timer_${classID}_${lectureID}_${bundleID}_bundle_main`);
                localStorage.removeItem(`questions_${classID}_${lectureID}_${bundleID}_bundle_main`);
                localStorage.removeItem(`currentOrder_${classID}_${lectureID}_${bundleID}_bundle_main`);
                localStorage.removeItem(`mockTest_${classID}_${lectureID}_${bundleID}_bundle_main`);

                const token = localStorage.getItem('token');
                const userID = jwtDecode(token).userID;
                const starttime = new Date().toISOString();

                const response = await fetch(`${apiBaseUrl}/mock-tests/start-bundle-main-mock-test`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({ lectureID, userID, quizID: mainQuizID, classID, starttime, bundleID })
                });

                const data = await response.json();
                console.log(data);

                if (data.attemptID && data.questions.length > 0) {
                    data.questions.sort((a, b) => a.order - b.order);
                    localStorage.setItem(`mockTest_${classID}_${lectureID}_${bundleID}_bundle_main`, JSON.stringify(data));
                    navigate(`/lectures/${lectureID}/bundle-mock-tests/${data.attemptID}/start`, {
                        state: { mockTest: data, quizID: mainQuizID, classID, category, hardQuizID, easyQuizID, bundleID, bundle }
                    });
                } else {
                    alert('모의고사를 시작할 수 없습니다. 모의고사 문제가 없습니다.');
                }
            }
        }
        else {
            // If no existing data or user chooses to start a new test
            const confirmStartNew = window.confirm('새로운 모의고사를 시작하시겠습니까?');
            if (!confirmStartNew) {
                return;
            }

            const token = localStorage.getItem('token');
            const userID = jwtDecode(token).userID;
            const starttime = new Date().toISOString();

            const response = await fetch(`${apiBaseUrl}/mock-tests/start-bundle-main-mock-test`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ lectureID, userID, quizID: mainQuizID, classID, starttime, bundleID })
            });

            const data = await response.json();
            console.log(data);

            if (data.attemptID && data.questions.length > 0) {
                data.questions.sort((a, b) => a.order - b.order);
                localStorage.setItem(`mockTest_${classID}_${lectureID}_${bundleID}_bundle_main`, JSON.stringify(data));
                navigate(`/lectures/${lectureID}/bundle-mock-tests/${data.attemptID}/start`, {
                    state: { mockTest: data, quizID: mainQuizID, classID, category, hardQuizID, easyQuizID, bundleID, bundle }
                });
            } else {
                alert('모의고사를 시작할 수 없습니다. 모의고사 문제가 없습니다.');
            }
        }
    };

    const handleViewResults = (quizID, category) => {
        const filteredProgress = mockTestProgress.filter(item => item.quizID === quizID);

        navigate(`/${classID}/lectures/${lecture.lectureID}/quizzes/${quizID}/results`, {
            state: {
                className,
                mockTestProgress: filteredProgress,
                classID,
                quizID,
                category
            }
        });
    };

    const handleViewBundleResults = async (bundleID, category) => {
        const filteredBundleProgress = bundleTestProgress.filter(item => item.bundleID === bundleID);

        navigate(`/${classID}/lectures/${lecture.lectureID}/bundlemocktests/${bundleID}/results`, {
            state: {
                className,
                category: category,
                bundleTestProgress: filteredBundleProgress
            }
        });
    };

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', py: 2, mx: 4 }}>
                <Typography variant="h4" component="div" className="text-lg font-semibold">
                    {lecture.title}
                </Typography>
            </Box>
            <Divider sx={{ bgcolor: 'black', my: 2, mx: 2 }} />
            <div key={lecture.lectureID} className="mx-4 my-4">
                <Accordion expanded={expandedPractice} onChange={handleTogglePractice}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel-practice-content-${lecture.lectureID}`}
                        id={`panel-practice-header-${lecture.lectureID}`}
                    >
                        <Typography variant="h6" component="h6" className="text-lg font-semibold">연습모드</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {isLoadingPractice ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <div className="flex flex-col w-full">
                                {lecture.quizzes && lecture.quizzes.map((quiz, quizIndex) => (
                                    <div key={quiz.quizID} className="mb-0">
                                        <Typography variant="h5" component="div" className="text-md font-medium text-gray-700">
                                            {quiz.title}
                                        </Typography>
                                        <div className="flex justify-around items-center mt=2">
                                            <div className="flex justify-start w-3/5">
                                            </div>
                                            <div className="flex justify-start w-1/5">
                                                <Button
                                                    onClick={() => handleStartQuiz(quiz.quizID)}
                                                    sx={{
                                                        border: `2.5px solid ${theme.palette.custom.default}`,
                                                        bgcolor: 'white',
                                                        px: 1,
                                                        py: 2,
                                                        mx: 1,
                                                        color: 'black',
                                                        fontWeight: 'bold',
                                                        borderRadius: 3,
                                                        borderWidth: 4,
                                                        '&:hover': {
                                                            bgcolor: 'white',
                                                            borderColor: theme.palette.custom.default,
                                                        }
                                                    }}
                                                >
                                                    문제 풀기
                                                </Button>
                                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                                    <Typography variant="body1" component="div" sx={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                                        진도율: {getProgressData(quiz.quizID, 'totalQuestions')}/{getProgressData(quiz.quizID, 'totalQuizQuestions')}
                                                    </Typography>
                                                    <Typography variant="body1" component="div" sx={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                                        정답률: {getProgressData(quiz.quizID, 'correctAnswers')}/{getProgressData(quiz.quizID, 'totalQuizQuestions')}
                                                    </Typography>
                                                </Box>
                                            </div>
                                            <div className="flex justify-start w-1/5">
                                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '50%' }}>
                                                    <Button
                                                        onClick={() => handleStartMockTest(quiz.quizID, quiz.category)}
                                                        sx={{
                                                            border: `2.5px solid ${theme.palette.custom.default}`,
                                                            px: 1,
                                                            py: 2,
                                                            mx: 1,
                                                            bgcolor: 'white',
                                                            color: 'black',
                                                            fontWeight: 'bold',
                                                            borderRadius: 3,
                                                            borderWidth: 4,
                                                            '&:hover': {
                                                                bgcolor: 'white',
                                                                borderColor: theme.palette.custom.default,
                                                            }
                                                        }}
                                                    >
                                                        모의고사 보기
                                                    </Button>
                                                </Box>
                                                <Box sx={{ mt: 0, width: '50%' }}>
                                                    <Typography variant="body1" component="div" sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
                                                        latest result
                                                    </Typography>
                                                    <Typography variant="body1" component="div" sx={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                                        걸린 시간: {getMockTestProgressData(quiz.quizID, 'testTime')}
                                                    </Typography>
                                                    <Typography variant="body1" component="div" sx={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                                        Score: {getMockTestProgressData(quiz.quizID, 'score')}
                                                    </Typography>
                                                </Box>
                                            </div>
                                            <div className="flex justify-center w-1/6">
                                                <Button
                                                    onClick={() => handleViewResults(quiz.quizID, quiz.category)}
                                                    sx={{
                                                        border: `2.5px solid ${theme.palette.custom.default}`,
                                                        px: 1,
                                                        py: 2,
                                                        bgcolor: 'white',
                                                        color: 'black',
                                                        fontWeight: 'bold',
                                                        borderRadius: 3,
                                                        borderWidth: 4,
                                                        '&:hover': {
                                                            bgcolor: 'white',
                                                            borderColor: theme.palette.custom.default,
                                                        }
                                                    }}
                                                >
                                                    결과보기
                                                </Button>
                                            </div>
                                        </div>
                                        {lecture.quizzes.length - 1 > quizIndex && <Divider sx={{ bgcolor: 'black', my: 1 }} />}
                                    </div>
                                ))}
                            </div>
                        )}
                    </AccordionDetails>
                </Accordion>
            </div>

            <div className="mx-4 mt-4 mb-12">
                <Accordion expanded={expandedReal} onChange={handleToggleReal}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel-real-content-${lecture.lectureID}`}
                        id={`panel-real-header-${lecture.lectureID}`}
                    >
                        <Typography variant="h6" component="h6" className="text-lg font-semibold">실전모드</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {isLoadingReal ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <div className="flex flex-col w-full">
                                <div className="flex flex-col w-full">
                                    {quizBundles.length > 0 ? (
                                        quizBundles.map((bundle, bundleIndex) => {
                                            const hasResults = bundleTestProgress.some(item => item.bundleID === bundle.bundleID);
                                            return (
                                                <div key={bundle.bundleID} className="mb-0">
                                                    <Typography variant="h6" component="div" className="text-md font-medium text-gray-700">
                                                        {bundle.bundleName}
                                                    </Typography>
                                                    <div className="flex justify-around items-center mt=2">
                                                        <div className="flex justify-start w-4/5">
                                                        </div>
                                                        <div className="flex justify-center w-1/5">
                                                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '50%' }}>
                                                                <Button
                                                                    onClick={() => handleModuleSelection(lecture.lectureID, classID, bundle)}
                                                                    sx={{
                                                                        border: `2.5px solid ${theme.palette.custom.default}`,
                                                                        px: 1,
                                                                        py: 2,
                                                                        mx: 1,
                                                                        bgcolor: 'white',
                                                                        color: 'black',
                                                                        fontWeight: 'bold',
                                                                        borderRadius: 3,
                                                                        borderWidth: 4,
                                                                        '&:hover': {
                                                                            bgcolor: 'white',
                                                                            borderColor: theme.palette.custom.default,
                                                                        }
                                                                    }}
                                                                >
                                                                    모의고사 보기
                                                                </Button>
                                                            </Box>
                                                            <Box sx={{ mt: 0, width: '50%' }}>
                                                            </Box>
                                                        </div>
                                                        <div className="flex justify-center w-1/6 mb=2">
                                                            <Button
                                                                onClick={() => handleViewBundleResults(bundle.bundleID, bundle.mainQuizCategory)}
                                                                disabled={!hasResults}
                                                                sx={{
                                                                    border: `2.5px solid ${theme.palette.custom.default}`,
                                                                    px: 1,
                                                                    py: 2,
                                                                    bgcolor: hasResults ? 'white' : theme.palette.custom.lightest,
                                                                    color: hasResults ? 'black' : theme.palette.custom.light,
                                                                    fontWeight: 'bold',
                                                                    borderRadius: 3,
                                                                    borderWidth: 4,
                                                                    '&:hover': {
                                                                        bgcolor: hasResults ? 'white' : theme.palette.custom.lightest,
                                                                        borderColor: hasResults ? theme.palette.custom.default : theme.palette.custom.lightest,
                                                                    }
                                                                }}
                                                            >
                                                                {loadingBundle === bundle.bundleID ? (
                                                                    <CircularProgress size={24} />
                                                                ) : (
                                                                    '결과보기'
                                                                )}
                                                            </Button>

                                                        </div>
                                                    </div>
                                                    {quizBundles.length - 1 > bundleIndex && <Divider sx={{ bgcolor: 'black', my: 1 }} />}
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <Typography variant="body1" component="div" className="text-md font-medium text-gray-700">
                                            해당 퀴즈에는 실전 모의고사가 설정되지 않았습니다.
                                        </Typography>
                                    )}
                                </div>
                            </div>
                        )}
                    </AccordionDetails>
                </Accordion>
                {total - 1 > index && <Divider sx={{ bgcolor: 'black', my: 2 }} />}
            </div>
        </ThemeProvider>
    );
}

export default LectureQuizzes;
