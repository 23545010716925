import React from 'react';
import { Box, Toolbar, Container, Typography } from '@mui/material';
import MathGeneratedQuestionsTable from './MathGeneratedQuestionsTable';
import MathGeneratedQuestionDetailsTable from './MathGeneratedQuestionDetailTable';
import AdminHeader from '../AdminHeader';
import AdminMenuDrawer from '../AdminMenuDrawer';
import { Routes, Route } from 'react-router-dom';

const drawerWidth = 240;

const MathGeneratedAdminPage = () => {
    return (
        <Box sx={{ display: 'flex' }}>
            <AdminHeader />
            <AdminMenuDrawer />
            <Box
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar />
                <Container maxWidth="lg">
                    <Typography variant="h4" gutterBottom>
                        Math Generated Questions Management
                    </Typography>
                    <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <Routes>
                            <Route path="/" element={<MathGeneratedQuestionsTable/>} />
                            <Route path="/:id" element={<MathGeneratedQuestionDetailsTable/>} />
                        </Routes>
                    </Container>
                </Container>
            </Box>
        </Box>
    );
};

export default MathGeneratedAdminPage;
