import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, IconButton, Autocomplete } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useApi } from '../../contexts/Apicontext';
import Swal from 'sweetalert2';

const UserDetailPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const user = location.state?.user || {};
    const apiBaseUrl = useApi();
    const [classes, setClasses] = useState([]);
    const [availableClasses, setAvailableClasses] = useState([]);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [newClass, setNewClass] = useState({ classID: '', enrollmentDate: new Date().toISOString().split('T')[0] });
    const [selectedClass, setSelectedClass] = useState(null);
    const [selectedClassID, setSelectedClassID] = useState(null);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [editEnrollmentDate, setEditEnrollmentDate] = useState('');

    useEffect(() => {
        fetchUserClasses();
        fetchAvailableClasses();
    }, [user.userID]);

    const fetchUserClasses = async () => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/users/${user.userID}/classes`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        setClasses(data);
    };

    const demo = async () => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/quizzes/words-in-context`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
    };


    const fetchAvailableClasses = async () => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/classes`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        console.log(data);
        setAvailableClasses(data);
    };

    const handleOpenAddDialog = () => {
        setOpenAddDialog(true);
    };

    const handleCloseAddDialog = () => {
        setOpenAddDialog(false);
        setNewClass({ classID: '', enrollmentDate: new Date().toISOString().split('T')[0] });
        setSelectedClass(null);
    };

    const handleAddClass = async () => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/users/${user.userID}/classes`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ classID: selectedClass.classID, enrollmentDate: newClass.enrollmentDate })
        });

        if (response.ok) {
            Swal.fire('Added!', 'Class has been added to the user.', 'success');
            fetchUserClasses();
            handleCloseAddDialog();
        } else {
            Swal.fire('Error!', 'Failed to add class to the user.', 'error');
        }
    };

    const handleEditClick = (classItem) => {
        setSelectedClassID(classItem.userClassID);
        setEditEnrollmentDate(classItem.enrollmentDate);
        setOpenEditDialog(true);
    };

    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
        setSelectedClassID(null);
        setEditEnrollmentDate('');
    };

    const handleSaveEdit = async () => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/user-classes/${selectedClassID}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ enrollmentDate: editEnrollmentDate })
        });

        if (response.ok) {
            Swal.fire('Saved!', 'Enrollment date has been updated.', 'success');
            fetchUserClasses();
            handleCloseEditDialog();
        } else {
            Swal.fire('Error!', 'Failed to update enrollment date.', 'error');
        }
    };

    const handleDeleteClick = async (userClassID) => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/user-classes/${userClassID}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (response.ok) {
            Swal.fire('Deleted!', 'Class has been removed from the user.', 'success');
            fetchUserClasses();
        } else {
            Swal.fire('Error!', 'Failed to remove class from the user.', 'error');
        }
    };

    return (
        <Box sx={{ p: 3, width:'100%'}}>
            <Button variant="contained" color="primary" onClick={() => navigate(-1)} sx={{ mb: 3 }}>
                Back
            </Button>
            <Paper sx={{ p: 3 }}>
                <Typography variant="h5" gutterBottom>User Details</Typography>
                <Typography variant="body1"><strong>Name:</strong> {user.name}</Typography>
                <Typography variant="body1"><strong>Email:</strong> {user.email}</Typography>
                <Typography variant="body1"><strong>Password:</strong> {user.password}</Typography>
                <Typography variant="body1"><strong>Role:</strong> {user.role}</Typography>
                <Typography variant="body1"><strong>Is Admin:</strong> {user.isAdmin ? 'True' : 'False'}</Typography>
                <Typography variant="body1"><strong>Signup Date:</strong> {new Date(user.signupDate).toLocaleString()}</Typography>
                <Typography variant="body1"><strong>Last Login:</strong> {new Date(user.lastLogin).toLocaleString()}</Typography>
            </Paper>
            <Box sx={{ mt: 3 }}>
                <Typography variant="h6">Classes</Typography>
                <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleOpenAddDialog} sx={{ mb: 2 }}>
                    Add Class
                </Button>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Class Name</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Enrollment Date</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {classes.map((classItem) => (
                                <TableRow key={classItem.userClassID}>
                                    <TableCell>{classItem.className}</TableCell>
                                    <TableCell>{classItem.description}</TableCell>
                                    <TableCell>{new Date(classItem.enrollmentDate).toLocaleDateString()}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleEditClick(classItem)}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton onClick={() => handleDeleteClick(classItem.userClassID)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            <Dialog open={openAddDialog} onClose={handleCloseAddDialog} fullWidth maxWidth="sm">
                <DialogTitle>Add Class</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter the details of the new class.
                    </DialogContentText>
                    <Autocomplete
                        options={availableClasses}
                        getOptionLabel={(option) => `${option.className} (${option.classID})`}
                        value={selectedClass}
                        onChange={(event, newValue) => setSelectedClass(newValue)}
                        renderInput={(params) => <TextField {...params} label="Select Class" margin="dense" fullWidth />}
                    />
                    <TextField
                        margin="dense"
                        name="enrollmentDate"
                        label="Enrollment Date"
                        type="date"
                        value={newClass.enrollmentDate}
                        onChange={(e) => setNewClass({ ...newClass, enrollmentDate: e.target.value })}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAddDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleAddClass} color="primary" disabled={!selectedClass}>
                        Add
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openEditDialog} onClose={handleCloseEditDialog} fullWidth maxWidth="sm">
                <DialogTitle>Edit Enrollment Date</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Edit the enrollment date of the class.
                    </DialogContentText>
                    <TextField
                        margin="dense"
                        name="enrollmentDate"
                        label="Enrollment Date"
                        type="date"
                        value={editEnrollmentDate}
                        onChange={(e) => setEditEnrollmentDate(e.target.value)}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEditDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveEdit} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default UserDetailPage;
