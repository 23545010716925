// src/contexts/ApiContext.js

import React, { createContext, useContext } from 'react';

const ApiContext = createContext();

export const ApiProvider = ({ children }) => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:4001';
    // const apiBaseUrl = 'http://localhost:4001';
    return (
        <ApiContext.Provider value={apiBaseUrl}>
            {children}
        </ApiContext.Provider>
    );
};

export const useApi = () => {
    return useContext(ApiContext);
};
