import React from 'react';
import { Typography, Box, List, ListItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import 'katex/dist/katex.min.css';
import { InlineMath } from 'react-katex';

const MathDirection = () => {
    return (
        <Box sx={{maxHeight:'50vh'}}>
            <Typography variant="body2" color="black" paragraph>
                The questions in this section address a number of important math skills.
                Use of a calculator is permitted for all questions.
                A reference sheet, calculator, and these directions can be accessed throughout the test.
            </Typography>
            <Typography variant="body2" color="black" paragraph>
                Unless otherwise indicated:
            </Typography>
            <List sx={{ listStyleType: 'disc', pl: 4 }}>
                <ListItem sx={{ display: 'list-item' }}>
                    <Typography variant="body2" color="black">
                        All variables and expressions represent real numbers.
                    </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                    <Typography variant="body2" color="black">
                        Figures provided are drawn to scale.
                    </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                    <Typography variant="body2" color="black">
                        All figures lie in a plane.
                    </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                    <Typography variant="body2" color="black">
                        The domain of a given function f is the set of all real numbers x for which f(x) is a real number.
                    </Typography>
                </ListItem>
            </List>
            <Typography variant="body2" color="black" paragraph>
                For multiple-choice questions, solve each problem, choose the correct answer from the choices provided.
                Each multiple-choice question has a single correct answer.
            </Typography>

            <Typography variant="body2" color="black" paragraph>
                For student-produced response questions, solve each problem and enter your answer as described below.
            </Typography>

            <List sx={{ listStyleType: 'disc', pl: 4 }}>
                <ListItem variant="body2" sx={{ display: 'list-item' }}>
                    <Typography variant="body2" color="black">
                        If you find more than one correct answer, enter only one answer.
                    </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                    <Typography variant="body2" color="black">
                        Your answer can be up to 5 characters for a positive answer and up to 6 characters (including the negative sign) for a negative answer.
                    </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                    <Typography variant="body2" color="black">
                        If your answer is a fraction that does not fit in the provided space, enter the decimal equivalent.
                    </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                    <Typography variant="body2" color="black">
                        If your answer is a decimal that does not fit in the provided space, enter it by truncating it or rounding at the fourth digit.
                    </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                    <Typography variant="body2" color="black">
                        If your answer is a mixed number (such as <InlineMath math="3 \frac{1}{2}" />), enter it as an improper fraction (<InlineMath math="\frac{7}{2}" />) or its decimal equivalent (3.5).
                    </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                    <Typography variant="body2" color="black">
                        Don't enter symbols such as a percent sign, comma, or dollar sign.
                    </Typography>
                </ListItem>
            </List>
            <Typography variant="body2" color="black" paragraph>
                Examples
            </Typography>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" sx={{ fontSize: '12px', border: '1px solid black' }}>Answer</TableCell>
                            <TableCell align="center" sx={{ fontSize: '12px', border: '1px solid black' }}>Acceptable ways to enter answer</TableCell>
                            <TableCell align="center" sx={{ fontSize: '12px', border: '1px solid black' }}>Unacceptable: will NOT receive credit</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell rowSpan={3} align="center" sx={{ fontSize: '12px', border: '1px solid black' }}>3.5</TableCell>
                            <TableCell align="center" sx={{ fontSize: '12px', border: '1px solid black' }}>3.5</TableCell>
                            <TableCell align="center" sx={{ fontSize: '12px', border: '1px solid black' }}>31/2</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" sx={{ fontSize: '12px', border: '1px solid black' }}>3.5</TableCell>
                            <TableCell align="center" sx={{ fontSize: '12px', border: '1px solid black' }}>3 1/2</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" sx={{ fontSize: '12px', border: '1px solid black' }}>7/2</TableCell>
                            <TableCell align="center" sx={{ fontSize: '12px', border: '1px solid black' }}></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell rowSpan={5} align="center" sx={{ fontSize: '12px', border: '1px solid black' }}>2/3</TableCell>
                            <TableCell align="center" sx={{ fontSize: '12px', border: '1px solid black' }}>2/3</TableCell>
                            <TableCell align="center" sx={{ fontSize: '12px', border: '1px solid black' }}></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" sx={{ fontSize: '12px', border: '1px solid black' }}>.6666</TableCell>
                            <TableCell align="center" sx={{ fontSize: '12px', border: '1px solid black' }}>0.66</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" sx={{ fontSize: '12px', border: '1px solid black' }}>.6667</TableCell>
                            <TableCell align="center" sx={{ fontSize: '12px', border: '1px solid black' }}>.66</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" sx={{ fontSize: '12px', border: '1px solid black' }}>0.666</TableCell>
                            <TableCell align="center" sx={{ fontSize: '12px', border: '1px solid black' }}>0.67</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" sx={{ fontSize: '12px', border: '1px solid black' }}>0.667</TableCell>
                            <TableCell align="center" sx={{ fontSize: '12px', border: '1px solid black' }}>.67</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell rowSpan={3} align="center" sx={{ fontSize: '12px', border: '1px solid black' }}>-1/3</TableCell>
                            <TableCell align="center" sx={{ fontSize: '12px', border: '1px solid black' }}>-1/3</TableCell>
                            <TableCell align="center" sx={{ fontSize: '12px', border: '1px solid black' }}>-.33</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" sx={{ fontSize: '12px', border: '1px solid black' }}>-.3333</TableCell>
                            <TableCell align="center" sx={{ fontSize: '12px', border: '1px solid black' }}>-0.33</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" sx={{ fontSize: '12px', border: '1px solid black' }}>-0.333</TableCell>
                            <TableCell align="center" sx={{ fontSize: '12px', border: '1px solid black' }}></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default MathDirection;
