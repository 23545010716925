import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, Grid, LinearProgress, CircularProgress } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useApi } from '../../contexts/Apicontext';
import ScoreDisplay from './ScoreDisplay';
import { Divider } from '@mui/material';
import TagScoreReport from '../MockTest_components/TagScoreReport';
import { createTheme } from '@mui/material/styles';
import styled from 'styled-components';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const ENGLISH_TAGS = [
    "Information and Ideas",
    "Expression of Ideas",
    "Craft and Structure",
    "Standard English Conventions"
];

const ENGLISH_DETAILED_TAGS = {
    "Information and Ideas": ["Command of Evidence", "Inference", "Central Ideas and Details"],
    "Craft and Structure": ["Words in Context", "Text Structure and Purpose", "Cross-Text Connections"],
    "Expression of Ideas": ["Rhetorical Synthesis", "Transitions"],
    "Standard English Conventions": ["Boundaries", "Form, Structure, and Sense"]
};

const MATH_TAGS = [
    "Algebra",
    "Advanced Math",
    "Geometry and Trigonometry",
    "Problem-Solving and Data Analysis"
];

const MATH_DETAILED_TAGS = {
    "Algebra": [
        "Linear equations in one variable",
        "Linear functions in one variable",
        "Linear equations in two variables",
        "Systems of two linear equations in two variables",
        "Linear inequalities in one or two variables"
    ],
    "Geometry and Trigonometry": [
        "Area and volume",
        "Lines, angles, and triangles",
        "Right triangles and trigonometry",
        "Circles"
    ],
    "Advanced Math": [
        "Equivalent expressions",
        "Nonlinear equations in one variable and systems of equations in two variables",
        "Nonlinear functions"
    ],
    "Problem-Solving and Data Analysis": [
        "Ratios, rates, proportional relationships, and units",
        "Percentages",
        "One-variable data: Distributions and measures of center and spread",
        "Two-variable data: Models and scatterplots",
        "Probability and conditional probability",
        "Inference from sample statistics and margin of error",
        "Evaluating statistical claims: Observational studies and experiments"
    ]
};

const theme = createTheme({
    palette: {
        custom: {
            light: '#1434c7',
            dark: '#0a2b4d',
            default: '#0f3e8e',
            darker: '#0a1e3c',
            lightest: '#e1e8f7',
            correct: '#4caf50',
            incorrect: '#f44336',
            empty: '#d3d3d3',
            low: '#f35672'
        }
    },
});

const CustomLinearProgress = styled(LinearProgress)`
  border-radius: 10px;
  &.MuiLinearProgress-root {
    background-color: ${theme.palette.custom.empty};
    height: 10px;
  }
  & .MuiLinearProgress-bar {
    border-radius: 10px;
    height: 10px;
    background-color: ${({ value }) => (value <= 50 ? theme.palette.custom.low : theme.palette.custom.light)};
  }
`;

const HeaderBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
};

const BundleAttemptAccordion = ({ bundleAttemptID, score, results, index, category }) => {
    const [mainAttempt, setMainAttempt] = useState(null);
    const [secondaryAttempt, setSecondaryAttempt] = useState(null);
    const [expanded, setExpanded] = useState(false);
    const [loading, setLoading] = useState(false); // Loading state
    const apiBaseUrl = useApi();
    const [tagReports, setTagReports] = useState([]);
    const [combinedCorrectCount, setCombinedCorrectCount] = useState(0);
    const [combinedIncorrectCount, setCombinedIncorrectCount] = useState(0);

    useEffect(() => {
        if (expanded) {
            async function fetchQuestionDetails() {
                setLoading(true); // Set loading to true before fetching data
                try {
                    const response = await fetch(`${apiBaseUrl}/bundle-mock-test/attempt/${bundleAttemptID}/details`, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                    });

                    if (response.ok) {
                        const data = await response.json();

                        // Add the scores to the mainAttempt and secondaryAttempt objects
                        const mainAttemptWithScore = { ...data.mainAttempt, score: results.first.score, testTime: results.first.testTime };
                        const secondaryAttemptWithScore = { ...data.secondaryAttempt, score: results.second.score, testTime: results.second.testTime };

                        // Set the state with the updated objects
                        setMainAttempt(mainAttemptWithScore);
                        setSecondaryAttempt(secondaryAttemptWithScore);

                        const combinedQuestions = [...data.mainAttempt.questions, ...data.secondaryAttempt.questions];
                        const combinedCorrect = combinedQuestions.filter(q => q.responses.some(response => response.isCorrect)).length;
                        const combinedIncorrect = combinedQuestions.length - combinedCorrect;

                        setCombinedCorrectCount(combinedCorrect);
                        setCombinedIncorrectCount(combinedIncorrect);

                        const tagReportsArray = calculateTagReports(combinedQuestions, category);
                        setTagReports(tagReportsArray);

                    } else {
                        console.error('Failed to fetch question details');
                    }
                } catch (error) {
                    console.error('Error fetching question details:', error);
                } finally {
                    setLoading(false); // Set loading to false after fetching data
                }
            }

            fetchQuestionDetails();
        }
    }, [expanded, bundleAttemptID, apiBaseUrl, results.first.score, results.second.score]);


    const calculateTagReports = (questions, category) => {
        const TAGS = category === 'Math' ? MATH_TAGS : ENGLISH_TAGS;
        const DETAILED_TAGS = category === 'Math' ? MATH_DETAILED_TAGS : ENGLISH_DETAILED_TAGS;

        const tagMap = TAGS.reduce((acc, tag) => {
            acc[tag] = { total: 0, correct: 0, detailedTags: {} };
            DETAILED_TAGS[tag].forEach(detailedTag => {
                acc[tag].detailedTags[detailedTag] = { total: 0, correct: 0 };
            });
            return acc;
        }, {});

        questions.forEach(question => {
            const tag = question.tags;
            const detailedTag = question.detailedTags;
            if (TAGS.includes(tag) && tagMap[tag]) {
                tagMap[tag].total += 1;
                if (question.responses.some(response => response.isCorrect)) {
                    tagMap[tag].correct += 1;
                }
                if (DETAILED_TAGS[tag].includes(detailedTag) && tagMap[tag].detailedTags[detailedTag]) {
                    tagMap[tag].detailedTags[detailedTag].total += 1;
                    if (question.responses.some(response => response.isCorrect)) {
                        tagMap[tag].detailedTags[detailedTag].correct += 1;
                    }
                }
            }
        });

        return TAGS.map(tag => ({
            tagName: tag,
            totalQuestions: tagMap[tag].total,
            correctQuestions: tagMap[tag].correct,
            detailedTags: DETAILED_TAGS[tag].map(detailedTag => ({
                name: detailedTag,
                total: tagMap[tag].detailedTags[detailedTag].total,
                correct: tagMap[tag].detailedTags[detailedTag].correct
            }))
        }));
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}m : ${remainingSeconds}s`;
    };

    return (
        <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel-content-${bundleAttemptID}`}
                id={`panel-header-${bundleAttemptID}`}
            >
                <Typography variant="h6">
                    Attempt #{index + 1} - Combined Score: {score}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', minHeight: '200px' }}>
                        <CircularProgress />
                        <Typography variant="body1" sx={{ ml: 2 }}>Loading...</Typography>
                    </Box>
                ) : (
                    <>
                        <HeaderBox>
                            <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
                                SAT Score Report
                            </Typography>
                        </HeaderBox>
                        <Divider sx={{ my: 3, borderColor: theme.palette.custom.light, borderWidth: 5, mx: '-2vw' }} />
                        <Typography variant="body1" component="div">
                            Time Taken: {formatTime(results.first.testTime + results.second.testTime)}
                        </Typography>
                        <Box sx={{ mt: 5, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '20%' }}>
                                <ScoreDisplay score={score} />
                                <Box sx={{ mt: 3, width: '80%' }}>
                                    <CustomLinearProgress variant="determinate" value={(combinedCorrectCount / (combinedCorrectCount + combinedIncorrectCount)) * 100} />
                                </Box>
                                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', width: '80%' }}>
                                    <Typography variant="body2" color="textPrimary">Correct: {combinedCorrectCount}</Typography>
                                    <Typography variant="body2" color="textSecondary">Incorrect: {combinedIncorrectCount}</Typography>
                                </Box>
                            </Box>
                            <Box sx={{ mt: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '80%' }}>
                                {category === 'English' ? (
                                    <Grid container spacing={1}>
                                        {tagReports.map((tagReport, index) => (
                                            (tagReport.tagName === 'Information and Ideas' || tagReport.tagName === 'Craft and Structure') ? (
                                                <Grid item lg={12 * 3 / 5} xs={12} key={index}>
                                                    <TagScoreReport
                                                        tagName={tagReport.tagName}
                                                        totalQuestions={tagReport.totalQuestions}
                                                        correctQuestions={tagReport.correctQuestions}
                                                        detailedTags={tagReport.detailedTags}
                                                        category={category}
                                                    />
                                                </Grid>
                                            ) : (
                                                <Grid item lg={12 * 2 / 5} xs={12} key={index}>
                                                    <TagScoreReport
                                                        tagName={tagReport.tagName}
                                                        totalQuestions={tagReport.totalQuestions}
                                                        correctQuestions={tagReport.correctQuestions}
                                                        detailedTags={tagReport.detailedTags}
                                                        category={category}
                                                    />
                                                </Grid>
                                            )
                                        ))}
                                    </Grid>
                                ) : (
                                    <Grid container spacing={1}>
                                        {tagReports.map((tagReport, index) => (
                                            <Grid item lg={12} xs={12} key={index}>
                                                <TagScoreReport
                                                    tagName={tagReport.tagName}
                                                    totalQuestions={tagReport.totalQuestions}
                                                    correctQuestions={tagReport.correctQuestions}
                                                    detailedTags={tagReport.detailedTags}
                                                    category={category}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                )}
                            </Box>
                        </Box>
                        <Box sx={{ mt: 5 }}>
                            <Typography variant="h5">Question Details</Typography>
                            <Divider sx={{ my: 2 }} />

                            {/* Main Attempt Section */}
                            <Box sx={{ mt: 3 }}>
                                <Typography variant="h6">Main Attempt</Typography>
                                <Grid container spacing={2}>
                                    {(mainAttempt?.questions || []).map((question, qIndex) => (
                                        <Grid item xs={2} key={qIndex}>
                                            <Box>
                                                <Typography variant="subtitle1">
                                                    {qIndex + 1}. (Main)
                                                </Typography>
                                                <Typography variant="body2" color={question.responses[0].isCorrect ? 'green' : 'red'}>
                                                    {question.responses[0].isCorrect ? <CheckCircleIcon /> : <CancelIcon />} - Your Answer: {question.responses[0].selectedOption}
                                                </Typography>
                                                <Typography variant="body2">
                                                    Tags: {question.tags}
                                                </Typography>
                                                <Typography variant="body2">
                                                    Detailed Tags: {question.detailedTags}
                                                </Typography>
                                                <Divider sx={{ mt: 1 }} />
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>

                            {/* Secondary Attempt Section */}
                            <Box sx={{ mt: 3 }}>
                                <Typography variant="h6">Secondary Attempt</Typography>
                                <Grid container spacing={2}>
                                    {(secondaryAttempt?.questions || []).map((question, qIndex) => (
                                        <Grid item xs={2} key={qIndex}>
                                            <Box>
                                                <Typography variant="subtitle1">
                                                    {qIndex + 1}. (Secondary)
                                                </Typography>
                                                <Typography variant="body2" color={question.responses[0].isCorrect ? 'green' : 'red'}>
                                                    {question.responses[0].isCorrect ? <CheckCircleIcon /> : <CancelIcon />} - Your Answer: {question.responses[0].selectedOption}
                                                </Typography>


                                                <Typography variant="body2">
                                                    Tags: {question.tags}
                                                </Typography>
                                                <Typography variant="body2">
                                                    Detailed Tags: {question.detailedTags}
                                                </Typography>
                                                <Divider sx={{ mt: 1 }} />
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        </Box>
                    </>
                )}
            </AccordionDetails>
        </Accordion >
    );
};

export default BundleAttemptAccordion;
