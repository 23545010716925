import React, { useState, useEffect } from 'react';
import { TextField, Button, IconButton, InputAdornment, Typography, Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Swal from 'sweetalert2';
import { useApi } from '../../contexts/Apicontext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#0f3e8e',
        },
        custom: {
            light: '#4f7aa2',
            dark: '#0a2b4d',
            default: '#0f3e8e',
            darker: '#0a1e3c',
            lightest: '#f0f0f0',
            bookmark: '#d32f2f',
            black: '#000000'
        }
    },
});

export default function MyProfile() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [countryCode, setCountryCode] = useState(''); // Initialize as empty
    const [countryCodes, setCountryCodes] = useState([]);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const navigate = useNavigate();
    const apiBaseUrl = useApi();

    useEffect(() => {
        const fetchCountryCodes = async () => {
            try {
                const response = await axios.get('https://restcountries.com/v3.1/all');
                const codes = response.data
                    .map((country, index) => ({
                        name: country.name.common,
                        code: country.idd?.root ? `${country.idd.root}${country.idd.suffixes?.[0] || ''}` : '',
                        uniqueKey: `${country.name.common}-${index}`
                    }))
                    .filter(country => country.code)
                    .sort((a, b) => a.name.localeCompare(b.name));

                setCountryCodes(codes);

                // Dynamically set the default to South Korea's code if available
                const korea = codes.find(country => country.name === 'South Korea');
                if (korea) {
                    setCountryCode(korea.code);
                }
            } catch (error) {
                console.error('Error fetching country codes:', error);
            }
        };

        const fetchUserProfile = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(`${apiBaseUrl}/user-profile`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setName(data.name);
                    setEmail(data.email);
                    setPhoneNumber(data.phoneNumber || '');

                    // Set user's country code if available, otherwise use default
                    if (data.countryCode) {
                        setCountryCode(data.countryCode);
                    } else {
                        const korea = countryCodes.find(country => country.name === 'South Korea');
                        if (korea) {
                            setCountryCode(korea.code);
                        }
                    }
                } else {
                    Swal.fire('Error', 'Failed to fetch user profile', 'error');
                }
            } catch (error) {
                Swal.fire('Error', 'An error occurred while fetching the profile', 'error');
            }
        };

        fetchCountryCodes();
        fetchUserProfile();
    }, [apiBaseUrl]);

    const handlePasswordVisibilityToggle = () => {
        setShowPassword(!showPassword);
    };

    const handleSaveChanges = async () => {
        if (newPassword !== confirmPassword) {
            Swal.fire('Error', 'New password and confirmation do not match', 'error');
            return;
        }

        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`${apiBaseUrl}/user-profile`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    name,
                    email,
                    phoneNumber,
                    countryCode,
                    currentPassword: currentPassword || undefined,
                    newPassword: newPassword || undefined
                })
            });

            if (response.ok) {
                Swal.fire('Success', 'Profile updated successfully', 'success').then(() => {
                    navigate('/'); // Redirect to home page
                });
            } else if (response.status === 401) {
                Swal.fire('Error', 'Current password is incorrect', 'error');
            } else {
                Swal.fire('Error', 'Failed to update profile', 'error');
            }
        } catch (error) {
            Swal.fire('Error', 'An error occurred while updating the profile', 'error');
        }
    };

    const isSaveButtonEnabled = () => {
        return name && email && phoneNumber && countryCode && currentPassword && newPassword && confirmPassword;
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
            <Box sx={{ width: '50%', maxWidth: '600px' }}>
                <Typography variant="h4" gutterBottom sx={{fontWeight:'bold'}}>
                    개인 정보 수정
                </Typography>
                
                {/* Name Field with 수정 불가 label */}
                <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel sx={{ color: 'grey', fontSize: 12 }}>실명</InputLabel>
                    <Box sx={{ position: 'relative' }}>
                        <TextField
                            value={name}
                            fullWidth
                            variant="standard"
                            sx={{mt:5}}
                            InputProps={{
                                readOnly: true,
                                disableUnderline: false,
                                sx: {
                                    '& .MuiInputBase-input.Mui-disabled': {
                                        WebkitTextFillColor: 'gray',
                                        borderBottom: '1px solid gray',
                                    },
                                },
                            }}
                            disabled
                        />
                        <Typography
                            sx={{
                                position: 'absolute',
                                top: '0',
                                right: '0',
                                backgroundColor: '#f0f0f0',
                                padding: '2px 4px',
                                borderRadius: '4px',
                                fontSize: 12,
                                color: 'grey',
                                mt:5
                            }}
                        >
                            수정 불가
                        </Typography>
                    </Box>
                </FormControl>

                {/* Country Code and Phone Number Fields */}
                <Box sx={{ display: 'flex', gap: '10px', marginBottom: '16px' }}>
                    <FormControl fullWidth>
                        <InputLabel>국가 코드</InputLabel>
                        <Select
                            value={countryCode}
                            onChange={(e) => setCountryCode(e.target.value)}
                            fullWidth
                            variant="filled"
                            sx={{ bgcolor: 'white' }}
                        >
                            {countryCodes.map((country) => (
                                <MenuItem key={country.uniqueKey} value={country.code}>
                                    {`${country.name} (${country.code})`}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        label="휴대폰 번호"
                        value={phoneNumber}
                        fullWidth
                        variant="filled"
                        sx={{ bgcolor: 'white' }}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                </Box>

                {/* Email Field with 수정 불가 label */}
                <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel sx={{ color: 'grey', fontSize: 12 }}>이메일</InputLabel>
                    <Box sx={{ position: 'relative' }}>
                        <TextField
                            value={email}
                            fullWidth
                            variant="standard"
                            InputProps={{
                                readOnly: true,
                                disableUnderline: false,
                                sx: {
                                    '& .MuiInputBase-input.Mui-disabled': {
                                        WebkitTextFillColor: 'gray',
                                        borderBottom: '1px solid gray',
                                    },
                                },
                            }}
                            sx={{mt:5}}
                            disabled
                        />
                        <Typography
                            sx={{
                                position: 'absolute',
                                top: '0',
                                right: '0',
                                backgroundColor: '#f0f0f0',
                                padding: '2px 4px',
                                borderRadius: '4px',
                                fontSize: 12,
                                mt:5,
                                color: 'grey'
                            }}
                        >
                            수정 불가
                        </Typography>
                    </Box>
                </FormControl>

                {/* Password Fields */}
                <TextField
                    label="현재 비밀번호"
                    type={showPassword ? 'text' : 'password'}
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    fullWidth
                    margin="normal"
                    variant="filled"
                    sx={{ bgcolor: 'white' }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handlePasswordVisibilityToggle}>
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    label="새 비밀번호"
                    type={showPassword ? 'text' : 'password'}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    fullWidth
                    margin="normal"
                    variant="filled"
                    sx={{ bgcolor: 'white' }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handlePasswordVisibilityToggle}>
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    label="새 비밀번호 확인"
                    type={showPassword ? 'text' : 'password'}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    fullWidth
                    margin="normal"
                    variant="filled"
                    sx={{ bgcolor: 'white' }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handlePasswordVisibilityToggle}>
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <Button
                    variant="contained"
                    fullWidth
                    sx={{ 
                        marginTop: '20px',
                        backgroundColor: isSaveButtonEnabled() ? theme.palette.custom.default : theme.palette.custom.light,
                        color: isSaveButtonEnabled() ? 'white' : 'grey',
                        '&:hover': {
                            backgroundColor: isSaveButtonEnabled() ? theme.palette.custom.dark : theme.palette.custom.light,
                        },
                        py:2
                    }}
                    onClick={handleSaveChanges}
                    disabled={!isSaveButtonEnabled()}
                >
                    변경된 정보 저장하기
                </Button>
            </Box>
        </Box>
    );
}
