import React, { useState, useEffect } from 'react';
import {
    Paper, Typography, IconButton, Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, MenuItem, Select, Autocomplete
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AWS from 'aws-sdk';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './quillEditorStyles.css'; // 스타일 파일을 import 합니다.
import { useApi } from '../../contexts/Apicontext';
import { useLocation, useNavigate } from 'react-router-dom';
import katex from "katex";
import "katex/dist/katex.min.css";
window.katex = katex;

// AWS S3 설정
AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY, // 여기에 액세스 키를 입력하세요
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY, // 여기에 시크릿 키를 입력하세요
    region: process.env.REACT_APP_AWS_REGION // 여기에 AWS 리전을 입력하세요
});

const s3 = new AWS.S3();

const uploadFileToS3 = (file, quizID, order, imageIndex, optionName) => {
    const params = {
        Bucket: 'agoran-bucket', // 여기에 버킷 이름을 입력하세요
        Key: `${quizID}/${order}/option-${optionName}-${imageIndex}-${file.name}`, // S3에 저장될 파일 이름
        Body: file
    };
    console.log(params);

    return s3.upload(params).promise();
};

const QuizQuestionDetailsPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { quizID, questionID } = location.state || {};
    const [question, setQuestion] = useState(null);
    const [editQuestion, setEditQuestion] = useState(null);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadedFileUrl, setUploadedFileUrl] = useState(null);
    const [uploadedImageCount, setUploadedImageCount] = useState(0);
    const [difficultyError, setDifficultyError] = useState(false);
    const [tags, setTags] = useState([]);
    const [detailedTags, setDetailedTags] = useState([]);
    const [selectedTag, setSelectedTag] = useState(null);
    const [selectedDetailedTag, setSelectedDetailedTag] = useState(null);
    const apiBaseUrl = useApi();

    useEffect(() => {
        fetchQuestionDetails();
        fetchTags();
    }, [questionID]);

    const fetchQuestionDetails = async () => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/questions/${questionID}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        setQuestion(data);
        console.log(data);
        setUploadedImageCount(data.content.filter(item => item.type === 'image').length); // 기존 이미지 개수 설정
        setSelectedTag(data.tag);
        setSelectedDetailedTag(data.detailedTag);
    };

    const fetchTags = async () => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/tags`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        setTags(data);
    };

    const fetchDetailedTags = async (tagID) => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/tags/${tagID}/detailed`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        setDetailedTags(data);
    };

    const handleEditClick = () => {
        setEditQuestion(question);
        setOpenEditDialog(true);
    };

    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
        setEditQuestion(null);
        setUploadedFileUrl(null); // 다이얼로그를 닫을 때 업로드된 파일 URL을 초기화
    };

    const handleSaveClick = async () => {
        if (!editQuestion.difficulty) {
            setDifficultyError(true);
            return;
        }

        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/questions/${questionID}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ ...editQuestion, tag: selectedTag, detailedTag: selectedDetailedTag })
        });

        if (response.ok) {
            alert('Question information has been updated.');
            handleCloseEditDialog();
            fetchQuestionDetails();
        } else {
            alert('Failed to update question information.');
        }
    };

    const handleUploadClick = async (optionName) => {
        if (selectedFile) {
            try {
                const uploadResult = await uploadFileToS3(selectedFile, quizID, editQuestion.order, uploadedImageCount + 1, optionName);
                setUploadedFileUrl(uploadResult.Location); // 업로드된 파일의 URL을 상태에 저장
                setUploadedImageCount(uploadedImageCount + 1); // 업로드된 이미지 개수 증가
                alert('File has been uploaded to S3.');
            } catch (error) {
                alert('Failed to upload image.');
            }
        } else {
            alert('No file selected.');
        }
    };

    const handleDeleteClick = async () => {
        const result = window.confirm('Are you sure you want to delete this question?');

        if (result) {
            const token = localStorage.getItem('token');
            const response = await fetch(`${apiBaseUrl}/admin/questions/${questionID}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.ok) {
                alert('Question has been deleted.');
                navigate(-1);
            } else {
                alert('Failed to delete question.');
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditQuestion({ ...editQuestion, [name]: value });
    };

    const handleDifficultyChange = (e) => {
        const difficultyMapping = {
            '하': 3,
            '중': 2,
            '상': 1
        };
        const selectedDifficulty = e.target.value;
        setEditQuestion({ ...editQuestion, difficulty: difficultyMapping[selectedDifficulty] });
        setDifficultyError(!selectedDifficulty); // If difficulty is not set, set error
    };

    const handleContentChange = (index, field, value) => {
        const newContent = [...editQuestion.content];
        newContent[index][field] = value;
        setEditQuestion({ ...editQuestion, content: newContent });
    };

    const handleAddContent = () => {
        setEditQuestion({
            ...editQuestion,
            content: [...editQuestion.content, { type: 'text', value: '' }]
        });
    };

    const handleRemoveContent = (index) => {
        const newContent = [...editQuestion.content];
        newContent.splice(index, 1);
        setEditQuestion({ ...editQuestion, content: newContent });
    };

    const handleOptionChange = (index, field, value) => {
        const newOptions = [...editQuestion.options];
        newOptions[index][field] = value;
        setEditQuestion({ ...editQuestion, options: newOptions });
    };

    const handleAddOption = () => {
        setEditQuestion({
            ...editQuestion,
            options: [...editQuestion.options, { type: 'text', name: '', value: '' }]
        });
    };

    const handleRemoveOption = (index) => {
        const newOptions = [...editQuestion.options];
        newOptions.splice(index, 1);
        setEditQuestion({ ...editQuestion, options: newOptions });
    };

    const handleTagChange = (event, newValue) => {
        setSelectedTag(newValue);
        if (newValue) {
            fetchDetailedTags(newValue.tagID);
        } else {
            setDetailedTags([]);
            setSelectedDetailedTag(null);
        }
    };

    const handleDetailedTagChange = (event, newValue) => {
        setSelectedDetailedTag(newValue);
    };

    const renderContent = () => {
        if (!Array.isArray(question.content)) {
            return null;
        }
        return question.content.map((item, index) => {
            if (item.type === 'text') {
                return (
                    <div key={index} className="prose">
                        <div dangerouslySetInnerHTML={{ __html: item.value }} />
                    </div>
                );
            } else if (item.type === 'image') {
                return <img key={index} src={item.value} alt={`content-${index}`} style={{ maxWidth: '100%' }} />;
            } else {
                return null;
            }
        });
    };

    const renderOptions = () => {
        if (!Array.isArray(question.options)) {
            return null;
        }
        return question.options.map((option, index) => {
            if (option.type === 'text') {
                return (
                    <div key={index} className="prose">
                        
                        <div dangerouslySetInnerHTML={{ __html: `<strong>${option.name}</strong>: ${option.value}` }} />
                    </div>
                );
            } else if (option.type === 'image') {
                return (
                    <div key={index}>
                        <Typography><strong>{option.name}:</strong></Typography>
                        <img src={option.value} alt={`option-${index}`} style={{ maxWidth: '100%' }} />
                    </div>
                );
            } else {
                return null;
            }
        });
    };

    const difficultyMapping = {
        3: '하',
        2: '중',
        1: '상'
    };

    if (!question) {
        return <Typography>Loading...</Typography>;
    }

    return (
        <div className='w-full'>
            <IconButton onClick={() => navigate(-1)} color="primary">
                <ArrowBackIcon />
            </IconButton>
            <Typography variant="h5" gutterBottom>
                Question Details
            </Typography>
            <Paper sx={{ p: 2, mb: 2, display: 'flex', border: '1px solid gray' }}>
                <Box sx={{ flex: 1, pr: 2 }}>
                    <Typography variant="h6"><strong>Order:</strong> {question.order}</Typography>
                    <Typography variant="h6"><strong>Difficulty:</strong> {difficultyMapping[question.difficulty]}</Typography>

                    <Typography variant="h6"><strong>Content:</strong></Typography>
                    {renderContent()}
                    <Typography variant="h6"><strong>Question Text:</strong> {question.questiontext}</Typography>
                    <Typography variant="h6"><strong>Options:</strong></Typography>
                    {renderOptions()}
                </Box>
                <Box sx={{ flex: 1, pl: 2 }}>
                    <Typography variant="h6"><strong>Answer:</strong> {question.answer}</Typography>
                    <Typography variant="h6"><strong>Question Type:</strong> {question.questionType}</Typography>
                    <Typography variant="h6"><strong>Explanation:</strong> {question.explanation}</Typography>
                    <Typography variant="h6"><strong>Tag:</strong> {question.tag?.tagName}</Typography>
                    <Typography variant="h6"><strong>Detailed Tag:</strong> {question.detailedTag?.detailedTagName}</Typography>
                    <IconButton onClick={handleEditClick}>
                        <EditIcon />
                    </IconButton>
                    <IconButton onClick={handleDeleteClick}>
                        <DeleteIcon />
                    </IconButton>
                </Box>
            </Paper>

            <Dialog open={openEditDialog} onClose={handleCloseEditDialog} fullWidth maxWidth="lg">
                <DialogTitle>Edit Question</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Edit the details of the question.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="order"
                        label="Order"
                        value={editQuestion?.order || ''}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="h6">Difficulty</Typography>
                        <Select
                            name="difficulty"
                            value={editQuestion?.difficulty === 3 ? '하' : editQuestion?.difficulty === 2 ? '중' : '상'}
                            onChange={handleDifficultyChange}
                            fullWidth
                            error={difficultyError}
                            helpertext={difficultyError ? 'This field is required' : ''}
                        >
                            <MenuItem value="상">상</MenuItem>
                            <MenuItem value="중">중</MenuItem>
                            <MenuItem value="하">하</MenuItem>
                        </Select>
                    </Box>
                    <TextField
                        margin="dense"
                        name="questiontext"
                        label="Question Text"
                        value={editQuestion?.questiontext || ''}
                        onChange={handleInputChange}
                        fullWidth
                    />

                    <Box sx={{ mt: 2 }}>
                        <Typography variant="h6">Content</Typography>
                        {editQuestion?.content.map((item, index) => (
                            <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2, border: '1px solid gray', p: 1 }}>
                                <Select
                                    value={item.type}
                                    onChange={(e) => handleContentChange(index, 'type', e.target.value)}
                                    sx={{ mr: 2 }}
                                >
                                    <MenuItem value="text">Text</MenuItem>
                                    <MenuItem value="image">Image</MenuItem>
                                </Select>
                                {item.type === 'text' ? (
                                    <ReactQuill
                                        value={item.value}
                                        onChange={(content) => handleContentChange(index, 'value', content)}
                                        modules={{
                                            toolbar: [
                                                [{ 'size': [] }, { 'font': [] }],
                                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                                                ['clean'], [{ 'formula': 'formula' }],
                                                [{ 'color': [] }, { 'background': [] }],
                                            ],
                                            clipboard: {
                                                matchVisual: false,
                                            },
                                        }}
                                        formats={[
                                            'size', 'font',
                                            'bold', 'italic', 'underline', 'strike', 'blockquote',
                                            'list', 'bullet', 'indent', 'color', 'background', 'formula'
                                        ]}
                                        className="prose quill-editor"
                                    />
                                ) : (
                                    <TextField
                                        label="Value"
                                        value={item.value}
                                        onChange={(e) => handleContentChange(index, 'value', e.target.value)}
                                        fullWidth
                                    />
                                )}
                                <IconButton onClick={() => handleRemoveContent(index)} color="secondary">
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        ))}
                        <Button onClick={handleAddContent} color="primary" startIcon={<AddIcon />}>
                            Add Content
                        </Button>
                        <Box sx={{ mt: 2 }}>
                            <input
                                accept="image/*"
                                type="file"
                                onChange={(e) => setSelectedFile(e.target.files[0])}
                            />
                        </Box>
                        {uploadedFileUrl && (
                            <Box sx={{ mt: 2 }}>
                                <Typography variant="body1"><strong>Uploaded Image URL:</strong></Typography>
                                <Typography variant="body2">{uploadedFileUrl}</Typography>
                                <img src={uploadedFileUrl} alt="Uploaded file" style={{ maxWidth: '100%', marginTop: '10px' }} />
                            </Box>
                        )}
                        <Button onClick={handleUploadClick} color="primary" variant="contained" sx={{ mt: 2 }}>
                            Upload to S3
                        </Button>
                    </Box>

                    <Box sx={{ mt: 2 }}>
                        <Typography variant="h6">Options</Typography>
                        {editQuestion?.options.map((option, index) => (
                            <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2, border: '1px solid gray', p: 1 }}>
                                <Select
                                    value={option.type}
                                    onChange={(e) => handleOptionChange(index, 'type', e.target.value)}
                                    sx={{ mr: 2 }}
                                >
                                    <MenuItem value="text">Text</MenuItem>
                                    <MenuItem value="image">Image</MenuItem>
                                </Select>
                                <TextField
                                    label="Name"
                                    value={option.name}
                                    onChange={(e) => handleOptionChange(index, 'name', e.target.value)}
                                    sx={{ mr: 2 }}
                                />
                                {option.type === 'text' ? (
                                    <ReactQuill
                                        value={option.value}
                                        onChange={(content) => handleOptionChange(index, 'value', content)}
                                        modules={{
                                            toolbar: [
                                                [{ 'size': [] }, { 'font': [] }],
                                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                                                ['clean'], [{ 'formula': 'formula' }],
                                                [{ 'color': [] }, { 'background': [] }],
                                            ],
                                            clipboard: {
                                                matchVisual: false,
                                            },
                                        }}
                                        formats={[
                                            'size', 'font',
                                            'bold', 'italic', 'underline', 'strike', 'blockquote',
                                            'list', 'bullet', 'indent', 'color', 'background', 'formula'
                                        ]}
                                        className="prose quill-editor"
                                    />
                                ) : (
                                    <>
                                        <TextField
                                            label="Image URL"
                                            value={option.value}
                                            onChange={(e) => handleOptionChange(index, 'value', e.target.value)}
                                            fullWidth
                                        />
                                        <input
                                            accept="image/*"
                                            type="file"
                                            onChange={(e) => setSelectedFile(e.target.files[0])}
                                        />
                                        <Button onClick={() => handleUploadClick(option.name)} color="primary" variant="contained" sx={{ mt: 2 }}>
                                            Upload Image to S3
                                        </Button>
                                    </>
                                )}
                                <IconButton onClick={() => handleRemoveOption(index)} color="secondary">
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        ))}
                        <Button onClick={handleAddOption} color="primary" startIcon={<AddIcon />}>
                            Add Option
                        </Button>
                    </Box>

                    <TextField
                        margin="dense"
                        name="answer"
                        label="Answer"
                        value={editQuestion?.answer || ''}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <TextField
                        margin="dense"
                        name="questionType"
                        label="Question Type"
                        value={editQuestion?.questionType || ''}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <TextField
                        margin="dense"
                        name="explanation"
                        label="Explanation"
                        value={editQuestion?.explanation || ''}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="h6">Tags</Typography>
                        <Autocomplete
                            options={tags}
                            getOptionLabel={(option) => option.tagName}
                            value={selectedTag}
                            onChange={handleTagChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Tag"
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                        />
                    </Box>
                    {selectedTag && (
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="h6">Detailed Tags</Typography>
                            <Autocomplete
                                options={detailedTags}
                                getOptionLabel={(option) => option.detailedTagName}
                                value={selectedDetailedTag}
                                onChange={handleDetailedTagChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select Detailed Tag"
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEditDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveClick} color="primary" disabled={!editQuestion?.difficulty}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default QuizQuestionDetailsPage;
