import React, { useState, useEffect } from 'react';
import { 
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, 
    IconButton, Typography, Button, Dialog, DialogTitle, DialogContent, 
    DialogContentText, TextField, DialogActions, Checkbox, FormControlLabel 
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useApi } from '../../contexts/Apicontext';

const QuizBundlesPage = () => {
    const location = useLocation();
    const { lectureID } = location.state || {};
    const [quizBundles, setQuizBundles] = useState([]);
    const [existingQuizBundles, setExistingQuizBundles] = useState([]);
    const [quizzes, setQuizzes] = useState([]);
    const [allQuizzes, setAllQuizzes] = useState([]);
    const [showLectureQuizzesOnly, setShowLectureQuizzesOnly] = useState(true);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openSelectDialog, setOpenSelectDialog] = useState(false);
    const [newQuizBundle, setNewQuizBundle] = useState({ bundleName: '', mainQuizID: '', easyQuizID: '', hardQuizID: '' });
    const [selectedQuizBundle, setSelectedQuizBundle] = useState(null);
    const [isBundleAlreadyAdded, setIsBundleAlreadyAdded] = useState(false); // 추가 상태
    const apiBaseUrl = useApi();
    const navigate = useNavigate();

    useEffect(() => {
        fetchQuizBundles();
        fetchExistingQuizBundles();
        fetchQuizzes();
        fetchAllQuizzes();
    }, [lectureID]);

    const fetchQuizBundles = async () => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/lectures/${lectureID}/quizbundles`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        setQuizBundles(data);
    };

    const fetchExistingQuizBundles = async () => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/quizbundles`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        setExistingQuizBundles(data);
    };

    const fetchQuizzes = async () => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/lectures/${lectureID}/quizzes`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        setQuizzes(data);
    };

    const fetchAllQuizzes = async () => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/quizzes`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        setAllQuizzes(data);
    };

    const handleOpenAddDialog = () => {
        setOpenAddDialog(true);
    };

    const handleCloseAddDialog = () => {
        setOpenAddDialog(false);
        setNewQuizBundle({ bundleName: '', mainQuizID: '', easyQuizID: '', hardQuizID: '' });
    };

    const handleAddQuizBundle = async () => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/lectures/${lectureID}/quizbundles`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(newQuizBundle)
        });

        if (response.ok) {
            fetchQuizBundles();
            handleCloseAddDialog();
            window.alert('Quiz bundle added successfully!');
        } else {
            alert('Failed to add new quiz bundle.');
        }
    };

    const handleOpenSelectDialog = () => {
        setOpenSelectDialog(true);
    };

    const handleCloseSelectDialog = () => {
        setOpenSelectDialog(false);
        setSelectedQuizBundle(null);
        setIsBundleAlreadyAdded(false); // 다이얼로그를 닫을 때 초기화
    };

    const handleAddExistingQuizBundle = async () => {
        if (!selectedQuizBundle) {
            alert('Please select a quiz bundle first.');
            return;
        }
    
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/lectures/${lectureID}/existing-quizbundles`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ bundleID: selectedQuizBundle.bundleID })
        });
    
        if (response.ok) {
            fetchQuizBundles();
            handleCloseSelectDialog();
            window.alert('Quiz bundle added successfully!');
        } else if (response.status === 400) {
            const data = await response.json();
            window.alert(data.message);
        } else {
            alert('Failed to add existing quiz bundle.');
        }
    };

    // 새로운 함수: QuizBundle 선택 시 이미 존재하는지 확인
    const handleSelectQuizBundle = (event, newValue) => {
        setSelectedQuizBundle(newValue);

        if (newValue && quizBundles.some(bundle => bundle.bundleID === newValue.bundleID)) {
            setIsBundleAlreadyAdded(true);
        } else {
            setIsBundleAlreadyAdded(false);
        }
    };

    const handleDeleteQuizBundle = async (bundleID) => {
        const result = window.confirm('Are you sure you want to delete this quiz bundle from the lecture?');

        if (result) {
            const token = localStorage.getItem('token');
            const response = await fetch(`${apiBaseUrl}/admin/lectures/${lectureID}/quizbundles/${bundleID}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.ok) {
                fetchQuizBundles();
            } else {
                alert('Failed to delete quiz bundle.');
            }
        }
    };

    const handleShowLectureQuizzesOnlyChange = (event) => {
        setShowLectureQuizzesOnly(event.target.checked);
    };

    const filteredQuizzes = showLectureQuizzesOnly ? quizzes : allQuizzes;

    return (
        <div className='w-full'>
            <IconButton onClick={() => navigate(-1)} color="primary">
                <ArrowBackIcon />
            </IconButton>
            <Typography variant="h5" gutterBottom>
                Quiz Bundles for Lecture {lectureID}
            </Typography>
            <Button 
                variant="contained" 
                color="primary" 
                startIcon={<AddIcon />} 
                sx={{ mb: 2 }} 
                onClick={handleOpenAddDialog}
            >
                Add New Quiz Bundle
            </Button>
            <Button 
                variant="contained" 
                color="secondary" 
                startIcon={<AddIcon />} 
                sx={{ mb: 2, ml: 2 }} 
                onClick={handleOpenSelectDialog}
            >
                Add Existing Quiz Bundle
            </Button>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Bundle ID</TableCell>
                            <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Bundle Name</TableCell>
                            <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Main Quiz ID</TableCell>
                            <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Main Quiz Title</TableCell>
                            <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Easy Quiz ID</TableCell>
                            <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Easy Quiz Title</TableCell>
                            <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Hard Quiz ID</TableCell>
                            <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Hard Quiz Title</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {quizBundles.map((bundle) => (
                            <TableRow key={bundle.bundleID}>
                                <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>{bundle.bundleID}</TableCell>
                                <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>{bundle.bundleName}</TableCell>
                                <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>{bundle.mainQuizID || 'N/A'}</TableCell>
                                <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>{bundle.mainQuizTitle || 'N/A'}</TableCell>
                                <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>{bundle.easyQuizID || 'N/A'}</TableCell>
                                <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>{bundle.easyQuizTitle || 'N/A'}</TableCell>
                                <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>{bundle.hardQuizID || 'N/A'}</TableCell>
                                <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>{bundle.hardQuizTitle || 'N/A'}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => handleDeleteQuizBundle(bundle.bundleID)} color="secondary">
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Add New Quiz Bundle Dialog */}
            <Dialog open={openAddDialog} onClose={handleCloseAddDialog} fullWidth maxWidth="sm">
                <DialogTitle>Add New Quiz Bundle</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter the details of the new quiz bundle.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="bundleName"
                        label="Bundle Name"
                        value={newQuizBundle.bundleName}
                        onChange={(e) => setNewQuizBundle({ ...newQuizBundle, bundleName: e.target.value })}
                        fullWidth
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={showLectureQuizzesOnly}
                                onChange={handleShowLectureQuizzesOnlyChange}
                                name="showLectureQuizzesOnly"
                                color="primary"
                            />
                        }
                        label="Show only quizzes in this lecture"
                    />
                    <Autocomplete
                        options={filteredQuizzes}
                        getOptionLabel={(option) => option.title}
                        onChange={(event, newValue) => {
                            setNewQuizBundle({ ...newQuizBundle, mainQuizID: newValue ? newValue.quizID : '', easyQuizID: newValue ? newValue.quizID : '', hardQuizID: newValue ? newValue.quizID : '' });
                        }}
                        renderInput={(params) => <TextField {...params} label="Main Quiz" variant="outlined" margin="dense" fullWidth />}
                        fullWidth
                    />
                    <Autocomplete
                        options={filteredQuizzes}
                        getOptionLabel={(option) => option.title}
                        onChange={(event, newValue) => {
                            setNewQuizBundle({ ...newQuizBundle, easyQuizID: newValue ? newValue.quizID : '' });
                        }}
                        renderInput={(params) => <TextField {...params} label="Easy Quiz" variant="outlined" margin="dense" fullWidth />}
                        fullWidth
                    />
                    <Autocomplete
                        options={filteredQuizzes}
                        getOptionLabel={(option) => option.title}
                        onChange={(event, newValue) => {
                            setNewQuizBundle({ ...newQuizBundle, hardQuizID: newValue ? newValue.quizID : '' });
                        }}
                        renderInput={(params) => <TextField {...params} label="Hard Quiz" variant="outlined" margin="dense" fullWidth />}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAddDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleAddQuizBundle} color="primary">
                        Add
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Select Existing Quiz Bundle Dialog */}
            <Dialog open={openSelectDialog} onClose={handleCloseSelectDialog} fullWidth maxWidth="sm">
                <DialogTitle>Select Existing Quiz Bundle</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Choose an existing quiz bundle to add to this lecture.
                    </DialogContentText>
                    <Autocomplete
                        options={existingQuizBundles}
                        getOptionLabel={(option) => option.bundleName}
                        value={selectedQuizBundle}
                        onChange={handleSelectQuizBundle} // 상태를 추적하는 함수로 변경
                        renderInput={(params) => <TextField {...params} label="Search Quiz Bundles" variant="outlined" />}
                        fullWidth
                    />
                    {isBundleAlreadyAdded && (
                        <Typography color="error" variant="body2" sx={{ mt: 2 }}>
                            This quiz bundle is already added to the lecture.
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSelectDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleAddExistingQuizBundle} color="primary" disabled={isBundleAlreadyAdded}>
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default QuizBundlesPage;
