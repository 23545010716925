import React, { useState, useEffect } from 'react';
import {
    Paper, Typography, IconButton, Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, MenuItem, Select
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useApi } from '../../../contexts/Apicontext';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './quillEditorStyles.css'; // Assuming the styles are shared or already imported
import AWS from 'aws-sdk';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

const TAGS = [
    "Algebra",
    "Advanced Math",
    "Problem-Solving and Data Analysis",
    "Geometry and Trigonometry"
];

const DETAILED_TAGS = {
    "Algebra": [
        "Linear equations in one variable",
        "Linear functions in one variable",
        "Linear equations in two variables",
        "Systems of two linear equations in two variables",
        "Linear inequalities in one or two variables"
    ],
    "Advanced Math": [
        "Equivalent expressions",
        "Nonlinear equations in one variable and systems of equations in two variables",
        "Nonlinear functions"
    ],
    "Problem-Solving and Data Analysis": [
        "Ratios, rates, proportional relationships, and units",
        "Percentages",
        "One-variable data: Distributions and measures of center and spread",
        "Two-variable data: Models and scatterplots",
        "Probability and conditional probability",
        "Inference from sample statistics and margin of error",
        "Evaluating statistical claims: Observational studies and experiments"
    ],
    "Geometry and Trigonometry": [
        "Area and volume",
        "Lines, angles, and triangles",
        "Right triangles and trigonometry",
        "Circles"
    ]
};

const difficultyMapping = {
    '하': 3,
    '중': 2,
    '상': 1,
    3: '하',
    2: '중',
    1: '상'
};

AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    region: process.env.REACT_APP_AWS_REGION
});

const s3 = new AWS.S3();

const uploadFileToS3 = (file, questionID, contentIndex, isOption) => {
    const fileKey = isOption
        ? `AI/${questionID}/options/option-${contentIndex}-${file.name}`
        : `AI/${questionID}/content/content-${contentIndex}-${file.name}`;

    const params = {
        Bucket: 'agoran-bucket',
        Key: fileKey,
        Body: file,
        ContentType: file.type,
    };

    return s3.upload(params).promise();
};

const MathGeneratedQuestionDetailsTable = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { rawquestion } = location.state || {};
    const [question, setQuestion] = useState(null);
    const [editQuestion, setEditQuestion] = useState(null);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const apiBaseUrl = useApi();

    useEffect(() => {
        const fetchQuestionDetails = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(`${apiBaseUrl}/admin/generated-questions-mathbyID/${rawquestion.GeneratedQuestionID}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setQuestion(data.question);
                } else {
                    alert('Failed to fetch question details.');
                }
            } catch (error) {
                alert('Error fetching question details.');
                console.error(error);
            }
        };

        if (rawquestion?.GeneratedQuestionID) {
            fetchQuestionDetails();
        }
    }, [rawquestion?.GeneratedQuestionID, apiBaseUrl]); // Dependency on ID and API base URL

    const handleEditClick = () => {
        setEditQuestion({
            ...question,
            difficulty: difficultyMapping[question.difficulty] || '하', // 숫자를 문자열로 변환
        });
        setOpenEditDialog(true);
    };

    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
    };

    const handleSaveClick = async () => {
        const mappedEditQuestion = {
            ...editQuestion,
            difficulty: difficultyMapping[editQuestion.difficulty] || 3, // 문자열을 숫자로 변환
            // 나머지 필드들은 그대로 유지
        };

        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`${apiBaseUrl}/admin/generated-questions-math/${question.GeneratedQuestionID}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(mappedEditQuestion)
            });

            if (response.ok) {
                alert('Question information has been updated.');
                handleCloseEditDialog();
                setQuestion(mappedEditQuestion); // Update the main question with edited data
            } else {
                alert('Failed to update question information.');
            }
        } catch (error) {
            alert('Error updating question information.');
            console.error(error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditQuestion({ ...editQuestion, [name]: value });
    };

    const handleContentChange = (index, field, value) => {
        const newContent = [...editQuestion.content];
        newContent[index][field] = value;
        setEditQuestion({ ...editQuestion, content: newContent });
    };

    const handleAddContent = () => {
        setEditQuestion({
            ...editQuestion,
            content: [...editQuestion.content, { type: 'text', value: '' }]
        });
    };

    const handleRemoveContent = (index) => {
        const newContent = [...editQuestion.content];
        newContent.splice(index, 1);
        setEditQuestion({ ...editQuestion, content: newContent });
    };

    const handleOptionChange = (index, field, value) => {
        const newOptions = [...editQuestion.options];
        newOptions[index][field] = value;
        setEditQuestion({ ...editQuestion, options: newOptions });
    };

    const handleAddOption = () => {
        setEditQuestion({
            ...editQuestion,
            options: [...editQuestion.options, { type: 'text', name: '', value: '' }]
        });
    };

    const handleRemoveOption = (index) => {
        const newOptions = [...editQuestion.options];
        newOptions.splice(index, 1);
        setEditQuestion({ ...editQuestion, options: newOptions });
    };

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleUploadClick = async (index, isOption) => {
        if (selectedFile) {
            try {
                const uploadResult = await uploadFileToS3(selectedFile, editQuestion.GeneratedQuestionID, index, isOption);
                alert('File has been uploaded to S3.');

                if (isOption) {
                    handleOptionChange(index, 'value', uploadResult.Location);
                } else {
                    handleContentChange(index, 'value', uploadResult.Location);
                }
            } catch (error) {
                alert('Failed to upload image.');
            }
        } else {
            alert('No file selected.');
        }
    };

    const renderContent = () => {
        if (!Array.isArray(question?.content)) {
            return null;
        }
        return question.content.map((item, index) => {
            if (item.type === 'text') {
                return (
                    <div key={index}>
                        <Typography variant="body2" dangerouslySetInnerHTML={{ __html: item.value }} />
                    </div>
                );
            } else if (item.type === 'image') {
                return <img key={index} src={item.value} alt={`content-${index}`} style={{ maxWidth: '100%' }} />;
            } else {
                return null;
            }
        });
    };

    const renderOptions = () => {
        if (!Array.isArray(question?.options)) {
            return null;
        }
        return question.options.map((option, index) => {
            if (option.type === 'text') {
                return (
                    <div key={index}>
                        <Typography variant="body2" dangerouslySetInnerHTML={{ __html: `<strong>${option.name}</strong>: ${option.value}` }} />
                    </div>
                );
            } else if (option.type === 'image') {
                return (
                    <div key={index}>
                        <Typography variant="body2"><strong>{option.name}:</strong></Typography>
                        <img src={option.value} alt={`option-${index}`} style={{ maxWidth: '100%' }} />
                    </div>
                );
            } else {
                return null;
            }
        });
    };

    if (!question) {
        return <Typography>Loading...</Typography>;
    }

    return (
        <div className='w-full'>
            <IconButton onClick={() => navigate(-1)} color="primary">
                <ArrowBackIcon />
            </IconButton>
            <Typography variant="h5" gutterBottom>
                Question Details
            </Typography>
            <Paper sx={{ p: 2, mb: 2, display: 'flex', border: '1px solid gray' }}>
                <Box sx={{ flex: 1, pr: 2 }}>
                    <Typography variant="h6"><strong>ID:</strong> {question.GeneratedQuestionID}</Typography>
                    <Typography variant="h6"><strong>Difficulty:</strong> {difficultyMapping[question.difficulty]}</Typography>

                    <Typography variant="h6"><strong>Content:</strong></Typography>
                    {renderContent()}
                    <Typography variant="h6"><strong>Question Text:</strong> {question.questiontext}</Typography>
                    <Typography variant="h6"><strong>Options:</strong></Typography>
                    {renderOptions()}
                </Box>
                <Box sx={{ flex: 1, pl: 2 }}>
                    <Typography variant="h6"><strong>Answer:</strong> {question.answer}</Typography>
                    <Typography variant="h6"><strong>Question Type:</strong> {question.questionType}</Typography>
                    <Typography variant="h6"><strong>Explanation:</strong></Typography>
                    <Typography variant="body2" dangerouslySetInnerHTML={{ __html: question.explanation }} />
                    <Typography variant="h6"><strong>Tag:</strong> {question.tags}</Typography>
                    <Typography variant="h6"><strong>Detailed Tags:</strong> {question.detailedTags}</Typography>
                    <IconButton onClick={handleEditClick}>
                        <EditIcon />
                    </IconButton>
                </Box>
            </Paper>

            <Dialog open={openEditDialog} onClose={handleCloseEditDialog} fullWidth maxWidth="lg">
                <DialogTitle>Edit Question</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Update the details of the question.
                    </DialogContentText>
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="h6">Difficulty</Typography>
                        <Select
                            name="difficulty"
                            value={editQuestion?.difficulty || '하'}
                            onChange={handleInputChange}
                            fullWidth
                        >
                            <MenuItem value="상">상</MenuItem>
                            <MenuItem value="중">중</MenuItem>
                            <MenuItem value="하">하</MenuItem>
                        </Select>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="h6">Question Type</Typography>
                        <Select
                            name="questionType"
                            value={editQuestion?.questionType || 'multiple choice'}
                            onChange={handleInputChange}
                            fullWidth
                        >
                            <MenuItem value="multiple choice">Multiple Choice</MenuItem>
                            <MenuItem value="short answer">Short Answer</MenuItem>
                        </Select>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="h6">Content</Typography>
                        {editQuestion?.content.map((item, index) => (
                            <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2, border: '1px solid gray', p: 1 }}>
                                <Select
                                    value={item.type}
                                    onChange={(e) => handleContentChange(index, 'type', e.target.value)}
                                    sx={{ mr: 2 }}
                                >
                                    <MenuItem value="text">Text</MenuItem>
                                    <MenuItem value="image">Image</MenuItem>
                                </Select>
                                {item.type === 'text' ? (
                                    <ReactQuill
                                        value={item.value}
                                        onChange={(content) => handleContentChange(index, 'value', content)}
                                        modules={{
                                            toolbar: [
                                                [{ 'size': [] }, { 'font': [] }],
                                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                                                ['clean'], [{ 'formula': 'formula' }],
                                                [{ 'color': [] }, { 'background': [] }],
                                            ],
                                            clipboard: {
                                                matchVisual: false,
                                            },
                                        }}
                                        formats={[
                                            'size', 'font',
                                            'bold', 'italic', 'underline', 'strike', 'blockquote',
                                            'list', 'bullet', 'indent', 'color', 'background', 'formula'
                                        ]}
                                        className="prose quill-editor"
                                    />
                                ) : (
                                    <>
                                        <TextField
                                            label="Value"
                                            value={item.value}
                                            onChange={(e) => handleContentChange(index, 'value', e.target.value)}
                                            fullWidth
                                        />
                                        <input
                                            accept="image/*"
                                            type="file"
                                            onChange={handleFileChange}
                                        />
                                        <Button onClick={() => handleUploadClick(index, false)} color="primary" variant="contained" sx={{ mt: 2 }}>
                                            Upload Image to S3
                                        </Button>
                                    </>
                                )}
                                <IconButton onClick={() => handleRemoveContent(index)} color="secondary">
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        ))}
                        <Button onClick={handleAddContent} color="primary" startIcon={<AddIcon />}>
                            Add Content
                        </Button>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="h6">Options</Typography>
                        {editQuestion?.options.map((option, index) => (
                            <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2, border: '1px solid gray', p: 1 }}>
                                <Select
                                    value={option.type}
                                    onChange={(e) => handleOptionChange(index, 'type', e.target.value)}
                                    sx={{ mr: 2 }}
                                >
                                    <MenuItem value="text">Text</MenuItem>
                                    <MenuItem value="image">Image</MenuItem>
                                </Select>
                                {option.type === 'text' ? (
                                    <ReactQuill
                                        value={option.value}
                                        onChange={(content) => handleOptionChange(index, 'value', content)}
                                        modules={{
                                            toolbar: [
                                                [{ 'size': [] }, { 'font': [] }],
                                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                                                ['clean'], [{ 'formula': 'formula' }],
                                                [{ 'color': [] }, { 'background': [] }],
                                            ],
                                            clipboard: {
                                                matchVisual: false,
                                            },
                                        }}
                                        formats={[
                                            'size', 'font',
                                            'bold', 'italic', 'underline', 'strike', 'blockquote',
                                            'list', 'bullet', 'indent', 'color', 'background', 'formula'
                                        ]}
                                        className="prose quill-editor"
                                    />
                                ) : (
                                    <>
                                        <TextField
                                            label="Image URL"
                                            value={option.value}
                                            onChange={(e) => handleOptionChange(index, 'value', e.target.value)}
                                            fullWidth
                                        />
                                        <input
                                            accept="image/*"
                                            type="file"
                                            onChange={handleFileChange}
                                        />
                                        <Button onClick={() => handleUploadClick(index, true)} color="primary" variant="contained" sx={{ mt: 2 }}>
                                            Upload Image to S3
                                        </Button>
                                    </>
                                )}
                                <IconButton onClick={() => handleRemoveOption(index)} color="secondary">
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        ))}
                        <Button onClick={handleAddOption} color="primary" startIcon={<AddIcon />}>
                            Add Option
                        </Button>
                    </Box>
                    <TextField
                        margin="dense"
                        name="answer"
                        label="Answer"
                        value={editQuestion?.answer || ''}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="h6">Explanation</Typography>
                        <ReactQuill
                            value={editQuestion?.explanation || ''}
                            onChange={(content) => setEditQuestion({ ...editQuestion, explanation: content })}
                            modules={{
                                toolbar: [
                                    [{ 'size': [] }, { 'font': [] }],
                                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                                    ['clean'], [{ 'formula': 'formula' }],
                                    [{ 'color': [] }, { 'background': [] }],
                                ],
                                clipboard: {
                                    matchVisual: false,
                                },
                            }}
                            formats={[
                                'size', 'font',
                                'bold', 'italic', 'underline', 'strike', 'blockquote',
                                'list', 'bullet', 'indent', 'color', 'background', 'formula'
                            ]}
                            className="prose quill-editor"
                        />
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="h6">Tag</Typography>
                        <Select
                            name="tags"
                            value={editQuestion?.tags || ''}
                            onChange={handleInputChange}
                            fullWidth
                        >
                            {TAGS.map((tag, index) => (
                                <MenuItem key={index} value={tag}>
                                    {tag}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="h6">Detailed Tag</Typography>
                        <Select
                            name="detailedTags"
                            value={editQuestion?.detailedTags || ''}
                            onChange={handleInputChange}
                            fullWidth
                        >
                            {DETAILED_TAGS[editQuestion?.tags]?.map((detailedTag, index) => (
                                <MenuItem key={index} value={detailedTag}>
                                    {detailedTag}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEditDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveClick} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default MathGeneratedQuestionDetailsTable;
