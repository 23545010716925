import React, { useState } from 'react';
import { Box, Typography, Button, IconButton } from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ConfirmDialog from '../ConfirmDialog';
import { useApi } from '../../../contexts/Apicontext';

export default function CourseItem({ course }) {
    const [isFavorite, setIsFavorite] = useState(course.isFavorite || false); // Initialize with API data
    const [dialogOpen, setDialogOpen] = useState(false);
    const apiBaseUrl = useApi();

    const toggleFavorite = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            // If the user is not logged in, show the login prompt dialog
            setDialogOpen(true);
            return;
        }

        try {
            const response = isFavorite
                ? await fetch(`${apiBaseUrl}/favorites/${course.classID}`, {
                      method: 'DELETE',
                      headers: {
                          'Content-Type': 'application/json',
                          'Authorization': `Bearer ${token}`,
                      },
                  })
                : await fetch(`${apiBaseUrl}/favorites`, {
                      method: 'POST',
                      headers: {
                          'Content-Type': 'application/json',
                          'Authorization': `Bearer ${token}`,
                      },
                      body: JSON.stringify({ classID: course.classID }),
                  });

            if (response.ok) {
                setIsFavorite(!isFavorite);
            } else {
                console.error('Failed to update favorite status');
            }
        } catch (error) {
            console.error('Error updating favorite status:', error);
        }
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    return (
        <Box sx={{ border: '1px solid #ccc', padding: 2, borderRadius: 1, marginBottom: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: 'black', marginRight: 1 }}>{course.className}</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                        <Typography variant="subtitle2" sx={{ color: '#fff', backgroundColor: '#000', padding: '2px 6px', borderRadius: '4px' }}>
                            VOD
                        </Typography>
                        <Typography variant="subtitle2" sx={{ color: '#fff', backgroundColor: '#000', padding: '2px 6px', borderRadius: '4px' }}>
                            Problem Set
                        </Typography>
                    </Box>
                </Box>
                <Typography variant="subtitle2" sx={{ }}>
                    {course.description}
                </Typography>
                <Typography variant="body1" sx={{ color: '#7e7e7e' }}>
                    기간: {course.servicePeriod}일
                </Typography>
                <Typography variant="body1" sx={{ color: '#7e7e7e' }}>
                    수업료: {Math.round(course.price).toLocaleString()} KRW
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <IconButton aria-label="like" onClick={toggleFavorite} sx={{ display: 'flex', flexDirection: 'column' }}>
                    {isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                    <Typography variant="body2" sx={{ color: '#7e7e7e' }}>
                        {isFavorite ? 'wished' : 'add to wish'}
                    </Typography>
                </IconButton>
                <Button variant="contained" color="primary">
                    상세 보기
                </Button>
            </Box>

            {/* Confirmation Dialog for non-logged in users */}
            <ConfirmDialog
                open={dialogOpen}
                handleClose={handleCloseDialog}
                title="로그인 필요"
                description="찜 기능을 이용하려면 먼저 로그인 하세요."
                confirmText="확인"
                handleConfirm={handleCloseDialog} // Just close the dialog on confirmation
            />
        </Box>
    );
}
