// TagScoreReport.js
import React from 'react';
import { Box, Typography, CircularProgress, LinearProgress } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import styled from 'styled-components';

const theme = createTheme({
    palette: {
        custom: {
            light: '#1434c7',
            dark: '#0a2b4d',
            default: '#0f3e8e',
            darker: '#0a1e3c',
            lightest: '#e1e8f7',
            correct: '#4caf50',
            incorrect: '#f44336',
            empty: '#d3d3d3',
            low: '#f35672' // 50% 이하인 경우 사용할 색상
        }
    },
});

const CustomCircularProgress = styled(CircularProgress)`
  & .MuiCircularProgress-circle {
    stroke-linecap: round;
  }
`;

const CustomLinearProgress = styled(LinearProgress)`
  border-radius: 10px;
  &.MuiLinearProgress-root {
    background-color: ${theme.palette.custom.empty};
    height: 10px;
  }
  & .MuiLinearProgress-bar {
    border-radius: 10px;
    height: 10px;
  }
`;

const ShadowBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px;
  position: relative;
`;

const TagScoreReport = ({ tagName, totalQuestions, correctQuestions, detailedTags, category }) => {
    const correctPercentage = totalQuestions > 0 ? (correctQuestions / totalQuestions) * 100 : 0;
    const ratio = (category==='English' ? ((tagName === 'Information and Ideas' || tagName === 'Craft and Structure') ? '30%' : '47%'):((tagName ==='Algebra'?'18%' :tagName === 'Problem-Solving and Data Analysis' ? '12%':(tagName==='Geometry and Trigonometry') ? '23%':'30%')));

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ mt: 3, width: '100%' }}>
                <Box position={'relative'}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                        {tagName}
                    </Typography>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 2, mt: 3, justifyContent:'center' }}>
                    {detailedTags.map((detailedTag, index) => {
                        const detailedCorrectPercentage = detailedTag.total > 0 ? (detailedTag.correct / detailedTag.total) * 100 : 0;
                        const isLowScore = detailedCorrectPercentage < 50;
                        const progressColor = isLowScore ? theme.palette.custom.low : theme.palette.custom.light;
                        return (
                            <ShadowBox key={index} sx={{ width: ratio }}>
                                <Box position="relative" display="inline-flex" sx={{ mb: 1, height: '70%' }}>
                                    <CustomCircularProgress
                                        variant="determinate"
                                        value={100}
                                        size={80}
                                        thickness={8}
                                        sx={{ color: theme.palette.custom.lightest, position: 'absolute', top: -10, left: 0 }}
                                    />
                                    <CustomCircularProgress
                                        variant="determinate"
                                        value={detailedCorrectPercentage}
                                        size={80}
                                        thickness={4}
                                        sx={{ color: progressColor, position: 'relative', top: -10, left: 0 }}
                                    />
                                    <Box
                                        top={0}
                                        left={0}
                                        bottom={0}
                                        right={0}
                                        position="absolute"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        sx={{ bgcolor: 'transparent', height: 60 }}
                                    >
                                        <Typography variant="caption" color="textPrimary" sx={{ position: 'absolute', whiteSpace: 'nowrap' }}>
                                            {detailedTag.correct} / {detailedTag.total}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Typography variant="body2" sx={{ mt: 2, textAlign: 'center' }}>{detailedTag.name}</Typography>
                                <Box position="relative" left={0} sx={{ mt: 4, width: '80%' }}>
                                    <CustomLinearProgress
                                        variant="determinate"
                                        value={detailedCorrectPercentage}
                                        sx={{
                                            '& .MuiLinearProgress-bar': {
                                                backgroundColor: progressColor,
                                            },
                                        }}
                                    />
                                </Box>
                            </ShadowBox>
                        );
                    })}
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default TagScoreReport;
