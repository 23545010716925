import React, { useState } from 'react';
import { Box, Toolbar, Container } from '@mui/material';
import AdminHeader from './AdminHeader';
import AdminMenuDrawer from './AdminMenuDrawer';
import ClassesTable from './ClassesTable';
import ClassLecturesPage from './ClassLecturesPage';
import { Routes, Route } from 'react-router-dom';

const drawerWidth = 240;

const ClassAdminPage = () => {
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <AdminHeader handleDrawerToggle={handleDrawerToggle} />
            <AdminMenuDrawer
                mobileOpen={mobileOpen}
                handleDrawerToggle={handleDrawerToggle}
            />
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    bgcolor: 'background.default',
                    p: 3,
                    width: { sm: '100%' } // Calculate width excluding drawer
                }}
            >
                <Toolbar />
                <Box sx={{ width: '100%' }}>
                    <Routes>
                        <Route path="/" element={<ClassesTable />} />
                        <Route path="/:classID/lectures" element={<ClassLecturesPage />} />
                    </Routes>
                </Box>
            </Box>
        </Box>
    );
};

export default ClassAdminPage;
