import React from 'react';
import { IconButton, Box, Drawer, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { green, yellow, red, grey } from '@mui/material/colors';
import { Link } from 'react-router-dom';
import { drawerWidth, sidebarWidth } from './QuizSidebar';

export default function QuizSidebarDrawer({ isOpen, toggleDrawer, questions, lectureID, quizID, classID }) {
    const getIcon = (question) => {
        if (question.responseCount === 1 && question.isCorrect === 1) {
            return <CheckCircleIcon style={{ color: green[500] }} />;
        } else if (question.responseCount === 2 && question.isCorrect === 1) {
            return <CheckCircleIcon style={{ color: yellow[700] }} />;
        } else if (question.responseCount === 2 && question.isCorrect === 0) {
            return <CancelIcon style={{ color: red[500] }} />;
        } else if (question.responseCount === 1 && question.isCorrect === 0) {
            return <HourglassEmptyIcon style={{ color: grey[700] }} />;
        } else {
            return null;
        }
    };

    return (
        <Drawer
            variant="temporary"
            anchor="right"
            open={isOpen}
            onClose={toggleDrawer}
            ModalProps={{
                BackdropProps: {
                    style: {
                        backgroundColor: 'transparent',
                    },
                },
            }}
            sx={{
                marginRight: `${sidebarWidth}px`,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                    right: `${sidebarWidth}px`,
                },
                zIndex: 100,
            }}
        >
            <IconButton
                onClick={toggleDrawer}
                sx={{ position: 'absolute', right: 8, top: 8, zIndex: 1300 }}
            >
                <CloseIcon />
            </IconButton>
            <Box sx={{ mt: 8 }}>
                <List>
                    {questions.map((question) => (
                        <ListItem button key={question.questionID} component={Link} to={`/${classID}/lectures/${lectureID}/quizzes/${quizID}/questions/${question.order}`}>
                            <ListItemIcon>
                                {getIcon(question)}
                            </ListItemIcon>
                            <ListItemText primary={`Question ${question.order}`} />
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Drawer>
    );
}
