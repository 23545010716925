import React from 'react';
import { Box, Toolbar, Container, Typography } from '@mui/material';
import TagsTable from './TagsTable';
import AdminHeader from './AdminHeader';
import AdminMenuDrawer from './AdminMenuDrawer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DetailedTagsTable from './DetailedTagsTable';  // 추가된 부분

const drawerWidth = 240;

const TagAdminPage = () => {
    return (

        <Box sx={{ display: 'flex' }}>
            <AdminHeader />
            <AdminMenuDrawer />
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    bgcolor: 'background.default',
                    p: 3,
                    width: { sm: '100%' } // Calculate width excluding drawer
                }}
            >
                <Toolbar />
                <Box sx={{ width: '100%' }}>
                    <Routes>
                        <Route path="/" element={<TagsTable />} />
                        <Route path="/:tagID/detailed" element={<DetailedTagsTable />} />  {/* 추가된 부분 */}
                    </Routes>
                </Box>
            </Box>
        </Box>
    );
};

export default TagAdminPage;
