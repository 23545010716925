import React from "react";
import BoardCommonHead from "./BoardCommonHead";
import BoardList from "./BoardList";
import AnnouncementBoardList from "./Announcement/AnnouncementBoardList";
import { useLocation } from "react-router-dom";
import AnnouncementBoardCommonHead from "./Announcement/AnnouncementBoardCommonHead";

const BoardCommon = () => {
    const location = useLocation();
    const isAnnouncement = location.pathname.includes('/announcement');

    return (
        <>

            {isAnnouncement ? (
                <>
                    <AnnouncementBoardCommonHead />
                    <AnnouncementBoardList />
                </>
            ) : (
                <>
                    <BoardCommonHead />
                    <BoardList />
                </>
            )}
        </>
    );
};

export default BoardCommon;
