import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, Grid, LinearProgress } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useApi } from '../../contexts/Apicontext';
import ScoreDisplay from './ScoreDisplay';
import { Divider } from '@mui/material';
import TagScoreReport from '../MockTest_components/TagScoreReport';
import { createTheme } from '@mui/material/styles';
import styled from 'styled-components';

const ENGLISH_TAGS = [
    "Information and Ideas",
    "Expression of Ideas",
    "Craft and Structure",
    "Standard English Conventions"
];

const ENGLISH_DETAILED_TAGS = {
    "Information and Ideas": ["Command of Evidence", "Inference", "Central Ideas and Details"],
    "Craft and Structure": ["Words in Context", "Text Structure and Purpose", "Cross-Text Connections"],
    "Expression of Ideas": ["Rhetorical Synthesis", "Transitions"],
    "Standard English Conventions": ["Boundaries", "Form, Structure, and Sense"]
};

const MATH_TAGS = [
    "Algebra",
    "Advanced Math",
    "Geometry and Trigonometry",
    "Problem-Solving and Data Analysis"
];

const MATH_DETAILED_TAGS = {
    "Algebra": [
        "Linear equations in one variable",
        "Linear functions in one variable",
        "Linear equations in two variables",
        "Systems of two linear equations in two variables",
        "Linear inequalities in one or two variables"
    ],
    "Geometry and Trigonometry": [
        "Area and volume",
        "Lines, angles, and triangles",
        "Right triangles and trigonometry",
        "Circles"
    ],
    "Advanced Math": [
        "Equivalent expressions",
        "Nonlinear equations in one variable and systems of equations in two variables",
        "Nonlinear functions"
    ],
    "Problem-Solving and Data Analysis": [
        "Ratios, rates, proportional relationships, and units",
        "Percentages",
        "One-variable data: Distributions and measures of center and spread",
        "Two-variable data: Models and scatterplots",
        "Probability and conditional probability",
        "Inference from sample statistics and margin of error",
        "Evaluating statistical claims: Observational studies and experiments"
    ]
};

const theme = createTheme({
    palette: {
        custom: {
            light: '#1434c7',
            dark: '#0a2b4d',
            default: '#0f3e8e',
            darker: '#0a1e3c',
            lightest: '#e1e8f7',
            correct: '#4caf50',
            incorrect: '#f44336',
            empty: '#d3d3d3',
            low: '#f35672'
        }
    },
});

const CustomLinearProgress = styled(LinearProgress)`
  border-radius: 10px;
  &.MuiLinearProgress-root {
    background-color: ${theme.palette.custom.empty};
    height: 10px;
  }
  & .MuiLinearProgress-bar {
    border-radius: 10px;
    height: 10px;
    background-color: ${({ value }) => (value <= 50 ? theme.palette.custom.low : theme.palette.custom.light)};
  }
`;

const HeaderBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

const QuizAttemptAccordion = ({ attemptID, score, testTime, index, category }) => {
    const [questions, setQuestions] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const apiBaseUrl = useApi();
    const [tagReports, setTagReports] = useState([]);
    const [correctCount, setCorrectCount] = useState(0);
    const [incorrectCount, setIncorrectCount] = useState(0);
    const [starttime, setStarttime] = useState('');

    useEffect(() => {
        if (expanded && !questions.length) {
            async function fetchQuestionDetails() {
                try {
                    const response = await fetch(`${apiBaseUrl}/quiz/attempt/${attemptID}/details`, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                    });

                    if (response.ok) {
                        const data1 = await response.json();
                        const data = data1.questions;
                        const startTimeISO = data1.startTime;

                        // ISO 문자열을 Date 객체로 변환 후, GMT+9 시간대로 변환
                        const utcDate = new Date(startTimeISO);
                        const localDate = new Date(utcDate.setHours(utcDate.getHours() + 9));

                        const localStartTime = localDate.toLocaleString(undefined, {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit',
                            timeZoneName: 'short'
                        });
                        setStarttime(localStartTime);

                        const updatedQuestions = data.map(question => {
                            const isCorrect = question.responses.some(response => response.isCorrect);
                            const selectedOptions = question.responses.map(response => response.selectedOption);

                            return {
                                ...question,
                                isCorrect,
                                selectedOptions
                            };
                        });

                        setQuestions(updatedQuestions);

                        // Count correct and incorrect questions
                        const correct = updatedQuestions.filter(q => q.isCorrect).length;
                        const incorrect = updatedQuestions.length - correct;
                        setCorrectCount(correct);
                        setIncorrectCount(incorrect);

                        // Calculate tag reports
                        const tagReportsArray = calculateTagReports(updatedQuestions, category);
                        setTagReports(tagReportsArray);

                    } else {
                        console.error('Failed to fetch question details');
                    }
                } catch (error) {
                    console.error('Error fetching question details:', error);
                }
            }

            fetchQuestionDetails();
        }
    }, [expanded, attemptID, apiBaseUrl]);

    const calculateTagReports = (questions, category) => {
        const TAGS = category === 'Math' ? MATH_TAGS : ENGLISH_TAGS;
        const DETAILED_TAGS = category === 'Math' ? MATH_DETAILED_TAGS : ENGLISH_DETAILED_TAGS;

        const tagMap = TAGS.reduce((acc, tag) => {
            acc[tag] = { total: 0, correct: 0, detailedTags: {} };
            DETAILED_TAGS[tag].forEach(detailedTag => {
                acc[tag].detailedTags[detailedTag] = { total: 0, correct: 0 };
            });
            return acc;
        }, {});

        questions.forEach(question => {
            const tag = question.tags;
            const detailedTag = question.detailedTags;
            if (TAGS.includes(tag) && tagMap[tag]) {
                tagMap[tag].total += 1;
                if (question.isCorrect) {
                    tagMap[tag].correct += 1;
                }
                if (DETAILED_TAGS[tag].includes(detailedTag) && tagMap[tag].detailedTags[detailedTag]) {
                    tagMap[tag].detailedTags[detailedTag].total += 1;
                    if (question.isCorrect) {
                        tagMap[tag].detailedTags[detailedTag].correct += 1;
                    }
                }
            }
        });

        return TAGS.map(tag => ({
            tagName: tag,
            totalQuestions: tagMap[tag].total,
            correctQuestions: tagMap[tag].correct,
            detailedTags: DETAILED_TAGS[tag].map(detailedTag => ({
                name: detailedTag,
                total: tagMap[tag].detailedTags[detailedTag].total,
                correct: tagMap[tag].detailedTags[detailedTag].correct
            }))
        }));
    };

    const correctPercentage = (correctCount / questions.length) * 100;

    return (
        <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel-content-${attemptID}`}
                id={`panel-header-${attemptID}`}
            >
                <Typography variant="h6">
                    Attempt #{index + 1} - Score: {score}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <HeaderBox>
                    <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
                        SAT Score Report
                    </Typography>
                </HeaderBox>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 3 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '30%' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mt: 2 }}>
                            <Typography variant="body1">Your Total Score</Typography>
                            <Typography variant="h1" sx={{ fontWeight: 'bold' }}>{score}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', mt: 2 }}>
                            <Typography variant="body1">Test No: {attemptID}</Typography>
                            <Typography variant="body1">Date: {starttime}</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', width: '70%' }}>
                    </Box>
                </Box>
                <Divider sx={{ mt: 3, borderColor: theme.palette.custom.light, borderWidth: 5, mx: '-2vw' }} />
                <Typography variant="body1" component="div">
                    Time Taken: {Math.floor(testTime / 60)} minutes {testTime % 60 < 10 ? `0${testTime % 60}` : testTime % 60} seconds
                </Typography>
                <Box sx={{ mt: 5, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Box sx={{ mt: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '20%' }}>
                        <ScoreDisplay score={score} />
                        <Box sx={{ mt: 5, width: '80%' }}>
                            <CustomLinearProgress variant="determinate" value={correctPercentage} />
                        </Box>
                        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', width: '80%' }}>
                            <Typography variant="body2" color="textPrimary">Correct: {correctCount}</Typography>
                            <Typography variant="body2" color="textSecondary">Incorrect: {incorrectCount}</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '80%' }}>
                        {category === 'English' ? (
                            <Grid container spacing={1}>
                                {tagReports.map((tagReport, index) => (
                                    (tagReport.tagName === 'Information and Ideas' || tagReport.tagName === 'Craft and Structure') ? (
                                        <Grid item lg={12 * 3 / 5} xs={12} key={index}>
                                            <TagScoreReport
                                                tagName={tagReport.tagName}
                                                totalQuestions={tagReport.totalQuestions}
                                                correctQuestions={tagReport.correctQuestions}
                                                detailedTags={tagReport.detailedTags}
                                                category={category}
                                            />
                                        </Grid>
                                    ) : (
                                        <Grid item lg={12 * 2 / 5} xs={12} key={index}>
                                            <TagScoreReport
                                                tagName={tagReport.tagName}
                                                totalQuestions={tagReport.totalQuestions}
                                                correctQuestions={tagReport.correctQuestions}
                                                detailedTags={tagReport.detailedTags}
                                                category={category}
                                            />
                                        </Grid>
                                    )
                                ))}
                            </Grid>
                        ) : (
                            <Grid container spacing={1}>
                                {tagReports.map((tagReport, index) => (
                                    <Grid item lg={12} xs={12} key={index}>
                                        <TagScoreReport
                                            tagName={tagReport.tagName}
                                            totalQuestions={tagReport.totalQuestions}
                                            correctQuestions={tagReport.correctQuestions}
                                            detailedTags={tagReport.detailedTags}
                                            category={category}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                    </Box>
                </Box>
                <Box sx={{ mt: 2 }}>
                    {questions.length > 0 ? (
                        questions.map((question, qIndex) => (
                            <Box key={question.questionID} sx={{ mb: 2 }}>
                                <Typography variant="subtitle1">
                                    {qIndex + 1}.
                                </Typography>
                                <Typography variant="body2" color={question.isCorrect ? 'green' : 'red'}>
                                    {question.isCorrect ? 'Correct' : 'Incorrect'} - Your Answers:
                                </Typography>
                                <Box sx={{ ml: 2 }}>
                                    {question.selectedOptions.map((option, oIndex) => (
                                        <Typography key={oIndex} variant="body2">
                                            - {option}
                                        </Typography>
                                    ))}
                                </Box>
                                <Typography variant="body2">
                                    Tags: {question.tags}
                                </Typography>
                                <Typography variant="body2">
                                    Detailed Tags: {question.detailedTags}
                                </Typography>
                                <Divider sx={{ mt: 1 }} />
                            </Box>
                        ))
                    ) : (
                        <Typography variant="body2">Loading questions...</Typography>
                    )}
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default QuizAttemptAccordion;
