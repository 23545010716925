import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Typography, Container, Grid, Divider, List, Paper, TextField, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import QuizSidebar from './QuizSidebar';
import QuizOption from './QuizOption';
import Swal from 'sweetalert2';
import { jwtDecode } from 'jwt-decode';
import { sidebarWidth, drawerWidth } from './QuizSidebar';
import DictionarySearch from './DictionarySearch';
import Button from '@mui/material/Button';
import { createTheme } from '@mui/material/styles';
import './quillEditorStyles.css';
import { useApi } from '../../contexts/Apicontext';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import ConfirmLeaveDialog from './ConfirmLeaveDialog';
import { useNavigate } from 'react-router-dom';
import FullscreenIcon from '@mui/icons-material/Fullscreen';

function QuizQuestionDetail() {
  const { classID, lectureID, quizID, order } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { userID } = jwtDecode(localStorage.getItem('token'));
  const [questionDetail, setQuestionDetail] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [responseCount, setResponseCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [shortAnswer, setShortAnswer] = useState('');
  const [leaveDialogOpen, setLeaveDialogOpen] = useState(false);

  const [maximizeLeft, setMaximizeLeft] = useState(false);
  const [maximizeRight, setMaximizeRight] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const apiBaseUrl = useApi();

  useEffect(() => {
    const fetchQuestionDetail = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch(`${apiBaseUrl}/lectures/${lectureID}/quizzes/${quizID}/questions/${order}/${userID}?classID=${classID}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch question details');
        }

        const data = await response.json();

        setQuestionDetail(data);
        setResponseCount(data.responseCount);
        setLoading(false);
        console.log(data);
        if (data.responseDetails.length === 0) {
          setSelectedOption('');
        } else {
          setSelectedOption(data.responseDetails.at(-1).selectedOption);
        }
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchQuestionDetail();
  }, [lectureID, quizID, order, userID, classID, responseCount]);


  useEffect(() => {
    // 텍스트 복사 방지 핸들러
    const handleCopy = (event) => {
      event.preventDefault();
      alert("텍스트 복사가 허용되지 않습니다.");
    };

    // document의 copy 이벤트를 감지하여 복사 방지
    document.addEventListener('copy', handleCopy);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      document.removeEventListener('copy', handleCopy);
    };
  }, []);

  const handleAttemptLeave = () => {
    setLeaveDialogOpen(true);
  };

  const handleLeaveConfirm = () => {
    setLeaveDialogOpen(false);
    navigate(`/student`);
  };


  const handleLeaveCancel = () => {
    setLeaveDialogOpen(false);
  };


  const parseJSON = (data) => {
    try {
      return JSON.parse(data);
    } catch (error) {
      return data;
    }
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: '#0f3e8e',
      },
      custom: {
        light: '#4f7aa2',
        dark: '#0a2b4d',
        default: '#0f3e8e',
        darker: '#0a1e3c',
        lightest: '#f0f0f0'
      }
    },
  });

  const renderContent = (contentItem, index) => {
    switch (contentItem.type) {
      case 'text':
        return (
          <div key={index} className="prose">
            <div dangerouslySetInnerHTML={{ __html: contentItem.value }} />
          </div>
        );
      case 'image':
        return (
          <img key={index} src={contentItem.value} alt={`content-${index}`} style={{ maxWidth: '100%' }} />
        );
      default:
  return null;
}
  };

const handleOptionSelect = async (optionKey) => {
  if (selectedOption === optionKey) {
    return;
  }

  if (!questionDetail.responseExists || questionDetail.responseCount < 2) {
    setSelectedOption(optionKey);
    const isCorrect = questionDetail.question.answer === optionKey;
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`${apiBaseUrl}/api/responses`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          lectureID,
          quizID,
          questionID: questionDetail.question.questionID,
          userID,
          selectedOption: optionKey,
          isCorrect,
          classID
        })
      });

      if (response.ok) {
        Swal.fire(isCorrect ? '정답입니다!' : '틀렸습니다!');
        const updatedResponseCount = questionDetail.responseCount + 1;
        setQuestionDetail({ ...questionDetail, responseCount: updatedResponseCount });
        setResponseCount(updatedResponseCount);
      } else {
        Swal.fire('오류', '응답을 기록하는 데 실패했습니다.', 'error');
      }
    } catch (error) {
      console.error('응답을 기록하는 중 오류 발생:', error);
      Swal.fire('오류', '응답을 기록하는 중 오류가 발생했습니다.', 'error');
    }
  } else {
    Swal.fire('알림', '이 문제에 대한 응답을 이미 2회 제출했습니다.', 'info');
  }
};

const handleShortAnswerSubmit = async () => {
  if (!shortAnswer) {
    Swal.fire('알림', '정답을 입력하세요.', 'info');
    return;
  }

  if (!questionDetail.responseExists || questionDetail.responseCount < 2) {
    const isCorrect = questionDetail.question.answer === shortAnswer.trim();
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`${apiBaseUrl}/api/responses`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          lectureID,
          quizID,
          questionID: questionDetail.question.questionID,
          userID,
          selectedOption: shortAnswer,
          isCorrect,
          classID
        })
      });

      if (response.ok) {
        Swal.fire(isCorrect ? '정답입니다!' : '틀렸습니다!');
        const updatedResponseCount = questionDetail.responseCount + 1;
        setQuestionDetail({ ...questionDetail, responseCount: updatedResponseCount });
        setResponseCount(updatedResponseCount);
        setSelectedOption(shortAnswer);
      } else {
        Swal.fire('오류', '응답을 기록하는 데 실패했습니다.', 'error');
      }
    } catch (error) {
      console.error('응답을 기록하는 중 오류 발생:', error);
      Swal.fire('오류', '응답을 기록하는 중 오류가 발생했습니다.', 'error');
    }
  } else {
    Swal.fire('알림', '이 문제에 대한 응답을 이미 2회 제출했습니다.', 'info');
  }
};

if (loading) return <div>Loading...</div>;
if (error) return <div>Error: {error}</div>;

const handleMaximizeLeft = () => {
  setMaximizeLeft(!maximizeLeft);
  if (maximizeRight) setMaximizeRight(false);
};

const handleMaximizeRight = () => {
  setMaximizeRight(!maximizeRight);
  if (maximizeLeft) setMaximizeLeft(false);
};

const leftPaperWidth = maximizeLeft ? 7 : maximizeRight ? 3 : 5;
const rightPaperWidth = maximizeRight ? 7 : maximizeLeft ? 3 : 5;

return (
  <Container sx={{ display: 'flex', height: '100vh', minWidth: '100vw', flexDirection: 'column' }}>
    <QuizSidebar
      isDrawerOpen={isDrawerOpen}
      setIsDrawerOpen={setIsDrawerOpen}
      responseCount={responseCount}
      classID={classID}
    />
    {/* <Grid container sx={{ flexGrow: 1, height: '94vh', mt: '0vh', mb: '3vh', marginLeft: `${sidebarWidth}px`, marginRight: isDrawerOpen ? `${drawerWidth + sidebarWidth}px` : `${sidebarWidth}px`, overflowX: 'auto' }}> */}
    <Grid container sx={{ py: 1, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box item sx={{ width: '33.3%', display: 'flex', justifyContent: 'center' }}>
      </Box>
      <Box item sx={{ width: '33.3%', display: 'flex', justifyContent: 'center' }}>
      </Box>
      <Box item sx={{ width: '33.3%', display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button onClick={handleOpen} sx={{ bgcolor: 'white', color: 'black', '&:hover': { bgcolor: 'grey' }, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', color: 'black' }}>
              <StickyNote2OutlinedIcon />
            </Box>
            <Typography variant="caption" sx={{ color: 'black' }}>
              Dictionary
            </Typography>
          </Button>
          <DictionarySearch open={open} onClose={handleClose} />
        </Box>
      </Box>
    </Grid>

    <Divider sx={{ width: '100%', height: '3.5px', backgroundColor: 'black' }} />

    <Grid container spacing={2} sx={{ flexGrow: 1, pb: 0.5, mt: 1, mb: 2, justifyContent: 'center', position: 'relative', overflow: 'auto' }}>
      <Grid item xs={12} md={leftPaperWidth}>
        <Paper elevation={0} sx={{ px: 3, height: '100%', display: 'flex', overflow: 'auto', flexDirection: 'column', position: 'relative' }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mx: -3 }}>
            <IconButton
              onClick={handleMaximizeLeft}
              sx={{
                zIndex: 10,
              }}
            >
              <FullscreenIcon />
            </IconButton>
          </Box>
          <Box sx={{ mt: 2 }}>
            {questionDetail && parseJSON(questionDetail.question.content).map(renderContent)}
          </Box>
        </Paper>
      </Grid>

      <Grid item xs={0.2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Divider orientation="vertical" sx={{ backgroundColor: 'black', width: '3.5px', height: '100%' }} />
      </Grid>

      <Grid item xs={12} md={rightPaperWidth}>
        <Paper elevation={0} sx={{ px: 3, height: '100%', display: 'flex', flexDirection: 'column', overflow: 'auto', position: 'relative' }}>

          <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', mx: -3 }}>
            <IconButton
              onClick={handleMaximizeRight}
              sx={{
                zIndex: 10,
              }}
            >
              <FullscreenIcon />
            </IconButton>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', bgcolor: 'lightgrey' }}>
            <Typography variant="h5" sx={{ fontWeight: 'bold', bgcolor: 'black', color: 'white', px: '0.5rem' }}>
              {`${order}`}
            </Typography>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: -0.5 }}>
            </Box>
          </Box>
          <Divider sx={{ width: '100%', height: '3.5px', backgroundColor: 'black', mb: 1 }} />

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
              {questionDetail.question.questiontext}
            </Typography>
          </Box>
          {questionDetail.question.questionType === 'short answer' ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', mt: 1 }}>
              <Box sx={{
                borderWidth: '2px', borderColor: 'black', display: 'flex',
                justifyContent: 'center', alignItems: 'center',
                borderRadius: '10px',
                width: '30%'
              }}>
                <TextField
                  value={shortAnswer}
                  onChange={(e) => setShortAnswer(e.target.value)}
                  variant="standard"
                  fullWidth
                  sx={{
                    mt: 2,
                    px: 2,
                    py: 2,
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'black',
                        borderBottom: '2px solid black',
                      },
                      '&:hover fieldset': {
                        borderColor: 'black',

                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'black',
                      },
                    },
                    '& .MuiInputBase-input': {
                      borderBottom: '2px solid black',
                      '&::after': {
                        content: '""',
                        display: 'block',
                        width: '100%',
                        height: '2px',
                        backgroundColor: 'black',
                        position: 'absolute',
                        borderBottom: '2px solid black',
                        bottom: 0,
                        left: 0,
                      },
                    },
                  }}
                />
              </Box>
              <Button
                variant="outlined"
                onClick={handleShortAnswerSubmit}
                sx={{ mt: 2, bgcolor: 'white', color: 'black', borderRadius: '20px', '&:hover': { bgcolor: 'grey' }, borderColor: 'black', width: '30%' }}
              >
                Submit Answer
              </Button>
            </Box>
          ) : (
            <List sx={{ display: 'flex', flexDirection: 'column', padding: 0, justifyContent: 'center' }}>
              {questionDetail && questionDetail.question.options.map((option, index) => (
                <QuizOption
                  key={index}
                  answer={questionDetail.question.answer === option.name}
                  optionType={option.type}
                  optionKey={option.name}
                  optionValue={option.value}
                  isSelected={selectedOption === option.name}
                  onOptionSelect={handleOptionSelect}
                />
              ))}
            </List>
          )}
          {(questionDetail.responseDetails.length > 0 && questionDetail.responseDetails[0].isCorrect !== false) || questionDetail.responseCount >= 2 ? (
            <>
              <Typography variant="h5" sx={{ mt: 2 }}>
                정답: {questionDetail.question.answer}
              </Typography>
              <Typography variant="body2" sx={{ mt: 2, fontStyle: 'italic' }}>
                해설: <div dangerouslySetInnerHTML={{ __html: questionDetail.question.explanation }} />
              </Typography>
            </>
          ) : null}
        </Paper>
      </Grid>
    </Grid>

    <Divider sx={{ width: '100%', height: '3.5px', backgroundColor: 'black', mb: 2 }} />

    <Grid container spacing={2} sx={{ width: '100%', mb: 2 }}>
      <Grid item xs={4}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAttemptLeave}
          sx={{
            bgcolor: theme.palette.custom.default,
            borderRadius: '20px',
            '&:hover': {
              bgcolor: theme.palette.custom.dark,
            },
          }}
        >
          Leave
        </Button>
      </Grid>
    </Grid>
    {/* </Grid> */}
    <ConfirmLeaveDialog open={leaveDialogOpen} handleClose={handleLeaveCancel} handleConfirm={handleLeaveConfirm} />
  </Container >
);
}

export default QuizQuestionDetail;
