import React from 'react';
import { Typography, Box, List, ListItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import 'katex/dist/katex.min.css';
import { InlineMath } from 'react-katex';

const EnglishDirection = () => {
    return (
        <Box sx={{ maxHeight: '50vh' }}>
            <Typography variant="body2" color="black" paragraph>
                The questions in this section address a number of important reading and writing skills.
                Each question includes one or more passages, which may include a table or graph.
                Read each passage and question carefully, and then choose the best answer to the question based on the passage(s).
            </Typography>
            <Typography variant="body2" color="black" paragraph>
                All questions in this section are multiple-choice with four answer choices. Each question has a single best answer.
            </Typography>
        </Box>
    );
};

export default EnglishDirection;
