import React, { useState, useEffect } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Typography, Button, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, MenuItem, Select
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../contexts/Apicontext';


const LectureClassesPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {lectureID} = location.state || {};
    const [classes, setClasses] = useState([]);
    const [existingClasses, setExistingClasses] = useState([]);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openSelectDialog, setOpenSelectDialog] = useState(false);
    const [newClass, setNewClass] = useState({ className: '' });
    const [selectedClassId, setSelectedClassId] = useState('');

    const apiBaseUrl = useApi();

    useEffect(() => {
        fetchClasses();
        fetchExistingClasses();
    }, [lectureID]);

    const fetchClasses = async () => {
        const token = localStorage.getItem('token');
        console.log(`${apiBaseUrl}/admin/lectures/${lectureID}/classes`);
        const response = await fetch(`${apiBaseUrl}/admin/lectures/${lectureID}/classes`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();

        setClasses(data);
    };

    const fetchExistingClasses = async () => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/classes`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        setExistingClasses(data);
    };

    const handleOpenAddDialog = () => {
        setOpenAddDialog(true);
    };

    const handleCloseAddDialog = () => {
        setOpenAddDialog(false);
        setNewClass({ className: '' });
    };

    const handleAddClass = async () => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/lectures/${lectureID}/classes`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(newClass)
        });

        if (response.ok) {
            fetchClasses();
            handleCloseAddDialog();
        } else {
            alert('Failed to add new class.');
        }
    };

    const handleOpenSelectDialog = () => {
        setOpenSelectDialog(true);
    };

    const handleCloseSelectDialog = () => {
        setOpenSelectDialog(false);
        setSelectedClassId('');
    };

    const handleAddExistingClass = async () => {
        if (!selectedClassId) {
            alert('Please select a class first.');
            return;
        }

        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/lectures/${lectureID}/existing-classes`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ classID: selectedClassId })
        });

        if (response.ok) {
            fetchClasses();
            handleCloseSelectDialog();
        } else {
            alert('Failed to add existing class.');
        }
    };

    const handleDeleteClass = async (classID) => {
        const result = window.confirm('Are you sure you want to delete this class from the lecture?');

        if (result) {
            const token = localStorage.getItem('token');
            const response = await fetch(`${apiBaseUrl}/admin/lectures/${lectureID}/classes/${classID}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.ok) {
                fetchClasses();
            } else {
                alert('Failed to delete class.');
            }
        }
    };

    return (
        <div className='w-full'>
            <IconButton onClick={() => navigate(-1)} color="primary">
                <ArrowBackIcon />
            </IconButton>
            <Typography variant="h5" gutterBottom>
                Classes for Lecture {lectureID}
            </Typography>
            <Button variant="contained" color="primary" startIcon={<AddIcon />} sx={{ mb: 2 }} onClick={handleOpenAddDialog}>
                Add New Class
            </Button>
            <Button variant="contained" color="secondary" startIcon={<AddIcon />} sx={{ mb: 2, ml: 2 }} onClick={handleOpenSelectDialog}>
                Add Existing Class
            </Button>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Class ID</TableCell>
                            <TableCell>Class Name</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {classes.map((classItem) => (
                            <TableRow key={classItem.classID}>
                                <TableCell>{classItem.classID}</TableCell>
                                <TableCell>{classItem.className}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => handleDeleteClass(classItem.classID)} color="secondary">
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Add New Class Dialog */}
            <Dialog open={openAddDialog} onClose={handleCloseAddDialog} fullWidth maxWidth="sm">
                <DialogTitle>Add New Class</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter the details of the new class.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="className"
                        label="Class Name"
                        value={newClass.className}
                        onChange={(e) => setNewClass({ ...newClass, className: e.target.value })}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAddDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleAddClass} color="primary">
                        Add
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Select Existing Class Dialog */}
            <Dialog open={openSelectDialog} onClose={handleCloseSelectDialog} fullWidth maxWidth="sm">
                <DialogTitle>Select Existing Class</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Choose an existing class to add to this lecture.
                    </DialogContentText>
                    <Select
                        name="existingClass"
                        value={selectedClassId}
                        onChange={(e) => setSelectedClassId(e.target.value)}
                        fullWidth
                    >
                        {existingClasses.map((classItem) => (
                            <MenuItem key={classItem.classID} value={classItem.classID}>
                                {classItem.className}
                            </MenuItem>
                        ))}
                    </Select>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSelectDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleAddExistingClass} color="primary">
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default LectureClassesPage;
