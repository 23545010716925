import React, { useState } from "react";
import { Box, TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { useApi } from "../../../contexts/Apicontext";
import EditorComponents from "./EditorComponent"; // Import the rich text editor component

const BoardForm = ({ onClose, item, isAdmin }) => {
  const [title, setTitle] = useState(item ? item.title : "");
  const [content, setContent] = useState(item ? item.content : ""); // State to manage content
  const apiBaseUrl = useApi();
  const boardID = 1; // Fix the boardID to 1

  const handleSubmit = async () => {
    // Determine the method and URL based on whether it's a new post or an edit
    const method = item ? "PUT" : "POST";
    const url = item 
      ? `${apiBaseUrl}/posts/${item.id}` // Use the post ID for updating
      : `${apiBaseUrl}/boards/${boardID}/posts`; // Use the fixed boardID for creating
    const token = localStorage.getItem("token");

    try {
      await fetch(url, {
        method,
        headers: { "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
         },
        body: JSON.stringify({ title, content }), // Include the title and content in the request body
      });
      onClose(); // Close the dialog after submission
    } catch (error) {
      console.error("Failed to submit board item:", error);
    }
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>{item ? "게시물 수정" : "게시물 생성"}</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label="제목"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          sx={{ mb: 2 }}
        />
        {/* Use the rich text editor component for the content */}
        <EditorComponents
          styleName="custom-editor"
          content={content}
          setContent={setContent}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">취소</Button>
        <Button onClick={handleSubmit} color="primary">
          {item ? "수정" : "생성"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BoardForm;
