    import React, { useEffect, useState } from 'react';
    import Divider from '@mui/material/Divider';
    import LectureQuizzes from './LectureQuizzes'; // 새로운 컴포넌트 임포트
    import { useApi } from '../../contexts/Apicontext';
    import { IconButton, CircularProgress } from '@mui/material'; // CircularProgress 컴포넌트 추가
    import ArrowBackIcon from '@mui/icons-material/ArrowBack';
    import { useNavigate } from 'react-router-dom';
    import Loading from '../Loading/Loading';

    function LecturePageBody({ lectures, className, classID,loading,hasLectures }) {
        const [lecturesWithQuizzes, setLecturesWithQuizzes] = useState([]);
        const navigate = useNavigate();
        const apiBaseUrl = useApi();

        useEffect(() => {
            async function fetchQuizzesForLectures() {
                const token = localStorage.getItem('token'); // localStorage에서 토큰 가져오기

                const updatedLectures = await Promise.all(lectures.map(async (lecture) => {
                    const response = await fetch(`${apiBaseUrl}/lectures/${lecture.lectureID}/quizzes`, {
                        headers: {
                            'Authorization': `Bearer ${token}` // Authorization 헤더에 토큰 추가
                        }
                    });
                    if (!response.ok) {
                        console.error('Failed to fetch quizzes');
                        return lecture;
                    }
                    const quizzes = await response.json();
                    return { ...lecture, quizzes };
                }));

                setLecturesWithQuizzes(updatedLectures);
            }

            fetchQuizzesForLectures();
        }, [lectures]);

        return (
            <div>
                <div className="bg-gray-800 w-full h-24 flex items-center justify-start pl-4">
                    <h4 className="text-2xl text-white font-bold">{className}</h4>
                </div>
                <IconButton onClick={() => navigate(-1)} size='large' color="primary" sx={{mt:2, ml:1}}>
                    <ArrowBackIcon />
                </IconButton>
                <Divider sx={{ bgcolor: 'black', my: 2, mx: 2 }} />

                {loading ? ( // 로딩 상태일 때 로딩 컴포넌트 표시
                    <Loading />
                ) : hasLectures ? (
                    lecturesWithQuizzes.map((lecture, index) => (
                        <LectureQuizzes
                            key={lecture.lectureID} // 고유한 key prop 추가
                            lecture={lecture}
                            index={index}
                            total={lecturesWithQuizzes.length}
                            classID={classID}
                            className={className}
                        />
                    ))
                ) : (
                    <p className="mt-4 text-gray-700">해당 수업에는 주차 강의가 없습니다.</p>
                )}
            </div>
        );
    }

    export default LecturePageBody;
