import React from 'react';

const ParagraphDivider = ({ number }) => {
  return (
    <div className="flex items-center justify-center mt-8 mb-4">
      <span className="font-bold text-primary mr-2 text-indigo-700">
        {number.toString().padStart(2, '0')}
      </span>
      <div className="flex items-center">
        <div className="h-2 w-2 bg-indigo-500 rounded-full mx-1" ></div>
        <div className="h-2 w-2 bg-indigo-400 rounded-full mx-1"></div>
        <div className="h-2 w-2 bg-gray-400 rounded-full mx-1"></div>
      </div>
    </div>
  );
};

export default ParagraphDivider;
