import React from "react";
import { Box, Typography, Button, Paper, Divider } from "@mui/material";
import "./quillEditorStyles.css"; // Import your custom styles

const BoardItem = ({ item, onEdit, onDelete, isAdmin }) => {
  return (
    <Paper
      elevation={0}
      className="prose"
      sx={{
        px: 2,
        mb: 2,
        width: '70%',
        "&:hover": { backgroundColor: "#f5f5f5" }, // Hover effect for gray background
        transition: "background-color 0.3s", // Smooth transition for the hover effect
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
        {item.title}
      </Typography>
      <div
        className="prose"
        style={{ marginBottom: "8px" }} // Margin bottom for spacing
        dangerouslySetInnerHTML={{ __html: item.content }}
      />
      {isAdmin && ( // Conditionally render buttons if the user is an admin
        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
          <Button
            variant="outlined"
            color="primary"
            sx={{ mr: 1, borderRadius: "8px" }}
            onClick={() => onEdit(item)}
          >
            수정
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            sx={{ borderRadius: "8px" }}
            onClick={() => onDelete(item.id)}
          >
            삭제
          </Button>
        </Box>
      )}
      <Divider sx={{ my: 0 }} />
    </Paper>

  );
};

export default BoardItem;
