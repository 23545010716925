// src/actions/authActions.js

// 로그인 성공 액션 생성자
export const loginSuccess = (userInfo) => {
  return {
    type: 'LOGIN_SUCCESS',
    payload: userInfo,
  };
};

// 로그인 실패 액션 생성자
export const loginFailure = () => {
  return {
    type: 'LOGIN_FAILURE',
  };
};
export const logoutUser = () => {
  return {
    type: 'LOGOUT_USER',
  };
};

export const setUser = (userData) => {
  return {
      type: 'SET_USER',
      payload: userData
  };
};
