// src/components/NotImplementedPage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, Container } from '@mui/material';

const NotImplementedPage = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate("/"); // 뒤로 가기
  };

  return (
    <Container style={{ textAlign: 'center', marginTop: '50px' }}>
      <Typography variant="h4" gutterBottom>
        아직 구현되지 않은 기능입니다
      </Typography>
      <Button variant="contained" color="primary" onClick={goBack}>
        홈화면으로 가기
      </Button>
    </Container>
  );
};

export default NotImplementedPage;
